import React, { useEffect, useState } from "react";
import { Typography, Box, CircularProgress } from "@mui/material";
import { CustomerEditableTextField } from "./CustomerEditableTextField.web";
import { CustomerRichEditorField } from "./CustomerRichEditorField.web";
import { Invoice } from "./CustomersPageController";

interface CustomerReportInvoiceProps {
  reportId: string;
  invoice: Invoice | null;
  invoiceLoading: boolean;
  getInvoice: (invoiceId: string) => void;
  editInvoice: (invoice: Invoice) => void;

  isTest?: boolean;
}

export const CustomerReportInvoice = ({ reportId, invoice, invoiceLoading, getInvoice, editInvoice, isTest }: CustomerReportInvoiceProps) => {
  const [internalInvoice, setInternalInvoice] = useState<Invoice | null>(invoice);
  const [requestFired, setRequestFired] = useState(false);

  useEffect(() => {
    getInvoice(reportId);
    setRequestFired(true);
  }, []);

  useEffect(() => {
    if (invoice) {
      setInternalInvoice(invoice);
    }
  }, [invoice])

  if (invoiceLoading || !invoice || !internalInvoice || (!requestFired && !isTest)) {
    return (
      <Box sx={webStyle.loaderWrapper} data-test-id="loaderWrapper">
        <CircularProgress sx={webStyle.loader} />
      </Box>
    )
  }

  const serviceAmount = (internalInvoice.service_rate || 0) * (internalInvoice.service_quantity || 0);
  const additionalChargesAmount =
    ((internalInvoice.additional_charges_rate || 0) / 100) *
    serviceAmount *
    (internalInvoice.additional_charges_quantity || 0);
  const totalAmount = serviceAmount + additionalChargesAmount;

  const handleInvoiceChange = (
    field: keyof Invoice,
    value: string | number
  ) => {
    setInternalInvoice({
      ...internalInvoice,
      [field]: value,
    });
  };

  const formatCurrency = (value: string | number) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(Number(value));
  };

  const generateInvoice = () => {
    editInvoice(internalInvoice);
  };

  return (
    <Box sx={webStyle.invoiceContainer}>
      <Box sx={webStyle.invoiceTableHeader}>
        <button
          style={webStyle.generateInvoiceButton}
          onClick={generateInvoice}
        >
          <Typography sx={webStyle.buttonText}>Generate invoice</Typography>
        </button>
      </Box>
      <Box />
      <Box sx={webStyle.tableWrapper}>
        <Box sx={webStyle.invoiceTable}>
          <Box sx={webStyle.invoiceTableHead}>
            <Typography style={webStyle.headerTitle}>DESCRIPTION</Typography>
            <Typography style={{...webStyle.shortHeaderTitle, width: "20%"}}>RATE</Typography>
            <Typography style={webStyle.shortHeaderTitle}>QUANTITY</Typography>
            <Typography style={webStyle.shortHeaderTitle}>AMOUNT</Typography>
          </Box>
          <Box sx={webStyle.invoiceTableBody}>
            <Box sx={{...webStyle.invoceItem, margin: '0 4px 11px'}}>
              <CustomerRichEditorField
                initialValue={internalInvoice.service_description || ''}
                onChange={(value) =>
                  handleInvoiceChange("service_description", value)
                }
              />
            </Box>
            <Box sx={{...webStyle.shortInvoceItem, width: "20%"}}>
              <CustomerEditableTextField
                initialValue={internalInvoice.service_rate?.toString() || '0'}
                onChange={(value) => handleInvoiceChange("service_rate", value)}
                isMoneyValue
              />
            </Box>
            <Box sx={webStyle.shortInvoceItem}>
              <CustomerEditableTextField
                initialValue={internalInvoice.service_quantity?.toString() || '0'}
                onChange={(value) =>
                  handleInvoiceChange("service_quantity", value)
                }
                allowNumericOnly
              />
            </Box>
            <Box sx={webStyle.shortInvoceItem} data-test-id="serviceAmount">
              {formatCurrency(serviceAmount)}
            </Box>
          </Box>
          <Box sx={webStyle.invoiceTableBody}>
            <Box sx={webStyle.invoceItem}>
              <CustomerEditableTextField
                initialValue={internalInvoice.additional_charges_description || ''}
                onChange={(value) =>
                  handleInvoiceChange("additional_charges_description", value)
                }
                allowNumericOnly={false}
                allowEmpty={false}
              />
            </Box>
            <Box sx={webStyle.shortInvoceItem}>
              <CustomerEditableTextField
                initialValue={internalInvoice.additional_charges_rate?.toString() || '0'}
                onChange={(value) =>
                  handleInvoiceChange("additional_charges_rate", value)
                }
                isPercentageValue
                allowNumericOnly
              />
            </Box>
            <Box sx={webStyle.shortInvoceItem}>
              <CustomerEditableTextField
                initialValue={internalInvoice.additional_charges_quantity?.toString() || '0'}
                onChange={(value) =>
                  handleInvoiceChange("additional_charges_quantity", value)
                }
                allowNumericOnly
              />
            </Box>
            <Box sx={webStyle.shortInvoceItem} data-test-id="additionalChargesAmount">
              {formatCurrency(additionalChargesAmount)}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={webStyle.totalWrapper}>
        <Typography sx={webStyle.totalText}>Total</Typography>
        <Typography sx={webStyle.totalText} data-test-id="totalAmount">
          {formatCurrency(totalAmount)}
        </Typography>
      </Box>
    </Box>
  );
};

const webStyle = {
  invoiceContainer: {
    display: "flex",
    flexDirection: "column",
  },
  invoiceTableHeader: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "flex-end",
    marginBottom: "20px",
  },
  tableWrapper: {
    minHeight: 'unset',
    "@media (max-width: 1669px)": {
      // "@media (max-width: 767px)": {
      overflow: "auto",
    },
  },
  invoiceTable: {
    border: "1px solid #CBD5E1",
    borderRadius: "12px 12px 0 12px",
    minWidth: "800px",
  },
  invoiceTableHead: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
    backgroundColor: "#F8FAFC",
    borderRadius: "12px 12px 0 0",
  },
  invoiceTableBody: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
    borderTop: "1px solid #CBD5E1",
    backgroundColor: "#FFFFFF",
    "&:last-of-type": {
      borderRadius: "0 0 0 12px",
    },
  },
  generateInvoiceButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    color: "#FFFFFF",
    border: "none",
    borderRadius: "7px",
    backgroundColor: "#1A7BA4",
    display: "flex",
    height: "44px",
    alignItems: "center",
    margin: "10px 0 0 12px",
    padding: "0 16px",
  },
  headerTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "50%",
    margin: "13px 4px",
  },
  shortHeaderTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "15%",
    margin: "13px 4px",
  },
  invoceItem: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "50%",
    margin: "11px 4px",
    display: "flex",
    wordBreak: "break-word",
  },
  shortInvoceItem: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "15%",
    margin: "11px 4px",
    display: "flex",
    wordBreak: "break-word",
  },
  buttonText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "24px",
  },
  totalWrapper: {
    display: "flex",
    width: "30%",
    justifyContent: "space-between",
    alignSelf: "flex-end",
    padding: "16px",
    borderLeft: "1px solid #CBD5E1",
    borderBottom: "1px solid #CBD5E1",
    borderRight: "1px solid #CBD5E1",
    borderRadius: "0 0 12px 12px",
  },
  totalText: {
    width: "100%",
    fontSize: "16px",
    fontWeight: 700,
    fontFamily: "'Inter', sans-serif",
    color: "#0F172A",
  },
  loaderWrapper: {
   margin: '200px auto 0',
   width: 'fit-content',
  },
  loader: {
   color: '#1A7BA4',
   height: '60px',
   width: '60px',
  },
};
