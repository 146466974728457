import React from "react";
// Customizable Area Start
import {
  Typography,
  Box,
  Tooltip,
  Radio,
  InputBase,
  Button,
  CircularProgress,
} from "@mui/material";
import * as Yup from "yup";
import { FieldArray, Formik } from "formik";
import dayjs from "dayjs";
import {
  errorIcon,
  addIcon,
  removeIcon,
  calendarIcon,
  calendarErrorIcon,
} from "./assets";
import { styled } from "@mui/material/styles";
// Customizable Area End

import AddTenantPageController, {
  Props,
  configJSON,
} from "./AddTenantPageController";
// Customizable Area Start
// Customizable Area End
export default class AddTenantPage extends AddTenantPageController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start

  meterListSchema = () => {
    return Yup.object().shape({
      type: Yup.string(),
      meterNumber: Yup.string()
        .trim()
        .matches(/^\d+$/, "Only numbers are allowed")
        .required("Meter Number is required"),
      lastReadingAvaliable: Yup.string().required(
        "Last reading available is required"
      ),
      lastReading: Yup.string()
        .nullable()
        .when("lastReadingAvaliable", {
          is: (lastReadingAvaliable) => lastReadingAvaliable === "Yes",
          then: Yup.string()
            .matches(/(\-?\d*\.?\d+)/, "Must be only digits")
            .trim()
            .required("Last Reading is required")
            .min(5, "Must be 5 digits minimum")
            .max(9, "Must be 9 digits maximum"),
        }),
      lastReadingDate: Yup.date()
        .nullable()
        .when("lastReadingAvaliable", {
          is: (lastReadingAvaliable) => lastReadingAvaliable === "Yes",
          then: Yup.date()
            .max(
              new Date(),
              `Date of last reading should be equal or earlier today `
            )
            .required("Date of last reading is required"),
        }),
    });
  };

  addTenantSchema = () => {
    return Yup.object().shape({
      tenantName: Yup.string().trim().required("Tenant Name is required"),
      meters: Yup.array().of(this.meterListSchema()).notRequired(),
    });
  };

  addMeterSchema = () => {
    return Yup.object().shape({
      meters: Yup.array().of(this.meterListSchema()).notRequired(),
    });
  };
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Box sx={webStyle.addTenantsContainer}>
        <Box sx={webStyle.addTenantsBlock}>
          <Box sx={webStyle.addTenantFormContainer}>
            <Box>
              <Typography sx={webStyle.formTitle}>
                {this.state.isEditTenant || this.state.isEditMeter
                  ? this.state.isTenant
                    ? configJSON.editTenantText
                    : configJSON.editMeterText
                  : this.state.isTenant
                  ? this.state.currentProperty.property_name
                  : this.state.currentTenant.tenant_name}
              </Typography>
              <Typography sx={webStyle.formSubTitle}>
                {this.state.isTenant &&
                !this.state.isEditMeter &&
                !this.state.isEditTenant
                  ? this.state.currentProperty.service_address
                  : ""}
              </Typography>
            </Box>
            <Formik
              initialValues={{
                tenantName: this.state.isEditTenant
                  ? this.state.currentTenant?.tenant_name
                  : "",
                meters: this.state.isEditTenant
                  ? this.state.currentTenant?.meters?.map((meter: any) => ({
                      type: meter.meter_type,
                      waterSubType: meter.water_sub_type,
                      volume: meter.volume,
                      metric: meter.metric,
                      meterNumber: meter.meter_number,
                      lastReadingAvaliable:
                        !!meter?.last_reading && !!meter?.date_of_last_reading
                          ? "Yes"
                          : "No",
                      lastReading: meter?.last_reading,
                      lastReadingDate: meter?.date_of_last_reading,
                      meterNote: meter?.meter_note,
                    })) || []
                  : [
                      {
                        type: this.state.isEditMeter
                          ? this.state.currentMeter?.meter_type
                          : "Water",
                        waterSubType: this.state.isEditMeter
                          ? this.state.currentMeter?.water_sub_type
                          : "Cold",
                        volume: this.state.isEditMeter
                          ? this.state.currentMeter?.volume
                          : "Cu ft",
                        metric: this.state.isEditMeter
                          ? this.state.currentMeter?.metric
                          : "Standard",
                        meterNumber: this.state.isEditMeter
                          ? this.state.currentMeter?.meter_number
                          : "",
                        lastReadingAvaliable: this.state.isEditMeter
                          ? !!this.state.currentMeter?.last_reading &&
                            !!this.state.currentMeter?.date_of_last_reading
                            ? "Yes"
                            : "No"
                          : "Yes",
                        lastReading: this.state.isEditMeter
                          ? this.state.currentMeter?.last_reading || ""
                          : "",
                        lastReadingDate: this.state.isEditMeter
                          ? this.state.currentMeter?.date_of_last_reading || ""
                          : "",
                        meterNote: this.state.isEditMeter
                          ? this.state.currentMeter?.meter_note
                          : "",
                      },
                    ],
              }}
              enableReinitialize={true}
              validateOnMount={true}
              validateOnChange={true}
              validationSchema={
                this.state.isTenant ? this.addTenantSchema : this.addMeterSchema
              }
              onSubmit={() => {
                this.setValidate(true);

                this.state.isTenant
                  ? this.createpdateTenant(
                      this.state.isEditTenant ? this.state.tenantId : undefined
                    )
                  : this.createOrUpdateMeter(
                      this.state.isEditMeter ? this.state.meterId : undefined
                    );
              }}
              data-test-id="addTenantForm"
            >
              {({ errors, values, setFieldValue, handleSubmit }) => (
                <form>
                  {this.state.isTenant &&
                  this.state.currentTenant?.tenant_name === undefined &&
                  this.state.tenantId ? (
                    <Box
                      sx={webStyle.loaderWrapper}
                      data-test-id="loaderWrapper"
                    >
                      <CircularProgress sx={webStyle.loader} />
                    </Box>
                  ) : (
                    <Box sx={webStyle.formContainer}>
                      <Box sx={webStyle.mainFieldControl}>
                        {this.state.isTenant ? (
                          <>
                            <Typography sx={webStyle.inputLabel}>
                              {configJSON.tenantNameText}
                            </Typography>
                            <input
                              style={{
                                ...webStyle.inputElemnt(),
                                ...(this.state.validate && errors.tenantName
                                  ? webStyle.inputElementError
                                  : {}),
                              }}
                              value={values.tenantName}
                              data-test-id="tenantName"
                              type="text"
                              onChange={(event) => {
                                setFieldValue("tenantName", event.target.value);
                              }}
                            />
                            {this.state.validate && errors.tenantName && (
                              <Tooltip
                                open={Boolean(errors.tenantName)}
                                title={errors.tenantName}
                                componentsProps={tooltipStyles}
                                arrow
                              >
                                <img
                                  src={errorIcon}
                                  alt=""
                                  style={webStyle.errorIcon}
                                />
                              </Tooltip>
                            )}
                          </>
                        ) : (
                          ""
                        )}
                      </Box>
                      <FieldArray name="meters">
                        {(arrayHelpers: any) => (
                          <>
                            {values.meters.map((_: any, index: number) => {
                              const currentValue = values?.meters[index];
                              const currentError = ((errors?.meters as any[]) ||
                                [])[index] as any;
                              return (
                                <Box sx={webStyle.meterBlcok} key={index}>
                                  <Box sx={webStyle.meterTitle}>
                                    <Typography sx={webStyle.meterTitleLabel}>
                                      {configJSON.meterTitleText}
                                      {index + 1}
                                    </Typography>
                                    {this.state.isTenant ? (
                                      <Button
                                        sx={webStyle.removeMeeterButton}
                                        // type="button"
                                        variant="text"
                                        data-test-id={`removeItem.${index}`}
                                        onClick={() => {
                                          arrayHelpers.remove(index);
                                        }}
                                      >
                                        <img
                                          src={removeIcon}
                                          alt=""
                                          style={webStyle.btnIcon}
                                        />
                                      </Button>
                                    ) : (
                                      ""
                                    )}
                                  </Box>
                                  <Box sx={webStyle.arrayFieldControl}>
                                    <Typography
                                      sx={webStyle.radioButtonTitleLabel}
                                    >
                                      {configJSON.meterText}
                                    </Typography>
                                    <Box sx={webStyle.radioButtonGroup}>
                                      <Box sx={webStyle.radioButtnBlock}>
                                        <Radio
                                          value="Water"
                                          checked={
                                            currentValue.type === "Water"
                                          }
                                          sx={webStyle.radioButton()}
                                          data-test-id={`meters.${index}.type.water`}
                                          name={`meterType.${index}`}
                                          onChange={(event) => {
                                            const element =
                                              event.currentTarget as HTMLInputElement;
                                            const value = element.value;
                                            setFieldValue(
                                              `meters.${index}.type`,
                                              value
                                            );
                                            setFieldValue(
                                              `meters.${index}.waterSubType`,
                                              "Cold"
                                            );
                                            setFieldValue(
                                              `meters.${index}.volume`,
                                              "Cu ft"
                                            );
                                            setFieldValue(
                                              `meters.${index}.metric`,
                                              "Standard"
                                            );
                                          }}
                                        />
                                        <Typography
                                          sx={webStyle.radioButtonLabel}
                                        >
                                          {configJSON.waterTypeText}
                                        </Typography>
                                      </Box>
                                      <Box sx={webStyle.radioButtnBlock}>
                                        <Radio
                                          value="Electric"
                                          checked={
                                            currentValue.type === "Electric"
                                          }
                                          sx={webStyle.radioButton()}
                                          data-test-id={`meters.${index}.type.electric`}
                                          name={`meterType.${index}`}
                                          onChange={(event) => {
                                            const element =
                                              event.currentTarget as HTMLInputElement;
                                            const value = element.value;
                                            setFieldValue(
                                              `meters.${index}.type`,
                                              value
                                            );
                                            setFieldValue(
                                              `meters.${index}.waterSubType`,
                                              ""
                                            );
                                            setFieldValue(
                                              `meters.${index}.volume`,
                                              ""
                                            );
                                            setFieldValue(
                                              `meters.${index}.metric`,
                                              ""
                                            );
                                          }}
                                        />
                                        <Typography
                                          sx={webStyle.radioButtonLabel}
                                        >
                                          {configJSON.electricalTypeText}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Box>
                                  {currentValue.type?.toLowerCase() ===
                                    "water" && (
                                    <>
                                      <Box sx={webStyle.arrayFieldControl}>
                                        <Typography
                                          sx={webStyle.radioButtonTitleLabel}
                                        >
                                          {configJSON.typeText}
                                        </Typography>
                                        <Box sx={webStyle.radioButtonGroup}>
                                          <Box sx={webStyle.radioButtnBlock}>
                                            <Radio
                                              sx={webStyle.radioButton()}
                                              data-test-id={`meters.${index}.waterSubType.cold`}
                                              name={`meter_water_sub_type_cold`}
                                              value="Cold"
                                              checked={
                                                currentValue.waterSubType?.toLowerCase() ===
                                                "cold"
                                              }
                                              onChange={(event) => {
                                                const element =
                                                  event.currentTarget as HTMLInputElement;
                                                const value = element.value;
                                                setFieldValue(
                                                  `meters.${index}.waterSubType`,
                                                  value
                                                );
                                              }}
                                            />
                                            <Typography
                                              sx={webStyle.radioButtonLabel}
                                            >
                                              {configJSON.coldWaterTypeText}
                                            </Typography>
                                          </Box>
                                          <Box sx={webStyle.radioButtnBlock}>
                                            <Radio
                                              sx={webStyle.radioButton()}
                                              data-test-id={`meters.${index}.waterSubType.hot`}
                                              name={`meter_water_sub_type_cold`}
                                              value="Hot"
                                              checked={
                                                currentValue.waterSubType?.toLowerCase() ===
                                                "hot"
                                              }
                                              onChange={(event) => {
                                                const element =
                                                  event.currentTarget as HTMLInputElement;
                                                const value = element.value;
                                                setFieldValue(
                                                  `meters.${index}.waterSubType`,
                                                  value
                                                );
                                              }}
                                            />
                                            <Typography
                                              sx={webStyle.radioButtonLabel}
                                            >
                                              {configJSON.hotWaterTypeText}
                                            </Typography>
                                          </Box>
                                        </Box>
                                      </Box>
                                      <Box sx={webStyle.arrayFieldControl}>
                                        <Typography
                                          sx={webStyle.radioButtonTitleLabel}
                                        >
                                          {configJSON.volumeText}
                                        </Typography>
                                        <Box sx={webStyle.radioButtonGroup}>
                                          <Box sx={webStyle.radioButtnBlock}>
                                            <Radio
                                              sx={webStyle.radioButton()}
                                              data-test-id={`meters.${index}.volume.cu_ft`}
                                              name={`volume_cu_ft`}
                                              value="Cu ft"
                                              checked={
                                                currentValue.volume?.toLowerCase() ===
                                                  "cu ft" ||
                                                currentValue.volume?.toLowerCase() ===
                                                  "cu_ft"
                                              }
                                              onChange={(event) => {
                                                const element =
                                                  event.currentTarget as HTMLInputElement;
                                                const value = element.value;
                                                setFieldValue(
                                                  `meters.${index}.volume`,
                                                  value
                                                );
                                              }}
                                            />
                                            <Typography
                                              sx={webStyle.radioButtonLabel}
                                            >
                                              {configJSON.volumeCuFtText}
                                            </Typography>
                                          </Box>
                                          <Box sx={webStyle.radioButtnBlock}>
                                            <Radio
                                              sx={webStyle.radioButton()}
                                              data-test-id={`meters.${index}.volume.gallons`}
                                              name={`volume_gallons`}
                                              value="Gallons"
                                              checked={
                                                currentValue.volume?.toLowerCase() ===
                                                "gallons"
                                              }
                                              onChange={(event) => {
                                                const element =
                                                  event.currentTarget as HTMLInputElement;
                                                const value = element.value;
                                                setFieldValue(
                                                  `meters.${index}.volume`,
                                                  value
                                                );
                                              }}
                                            />
                                            <Typography
                                              sx={webStyle.radioButtonLabel}
                                            >
                                              {configJSON.volumeGallonsText}
                                            </Typography>
                                          </Box>
                                        </Box>
                                      </Box>
                                      <Box sx={webStyle.arrayFieldControl}>
                                        <Typography
                                          sx={webStyle.radioButtonTitleLabel}
                                        >
                                          {configJSON.metricText}
                                        </Typography>
                                        <Box sx={webStyle.radioButtonGroup}>
                                          <Box sx={webStyle.radioButtnBlock}>
                                            <Radio
                                              sx={webStyle.radioButton()}
                                              data-test-id={`meters.${index}.metric.metric_standard`}
                                              name={`metric_standard`}
                                              value="Standard"
                                              checked={
                                                currentValue.metric?.toLowerCase() ===
                                                "standard"
                                              }
                                              onChange={(event) => {
                                                const element =
                                                  event.currentTarget as HTMLInputElement;
                                                const value = element.value;
                                                setFieldValue(
                                                  `meters.${index}.metric`,
                                                  value
                                                );
                                              }}
                                            />
                                            <Typography
                                              sx={webStyle.radioButtonLabel}
                                            >
                                              {configJSON.standardVolumeText}
                                            </Typography>
                                          </Box>
                                          <Box sx={webStyle.radioButtnBlock}>
                                            <Radio
                                              sx={webStyle.radioButton()}
                                              data-test-id={`meters.${index}.metric.metric_10cu_ft`}
                                              name={`metric_10cu_ft`}
                                              value="10 cu.ft/gallons"
                                              checked={
                                                currentValue.metric?.toLowerCase() ===
                                                  "10 cu.ft/gallons" ||
                                                currentValue.metric?.toLowerCase() ===
                                                  "ten_cuft_gallons"
                                              }
                                              onChange={(event) => {
                                                const element =
                                                  event.currentTarget as HTMLInputElement;
                                                const value = element.value;
                                                setFieldValue(
                                                  `meters.${index}.metric`,
                                                  value
                                                );
                                              }}
                                            />
                                            <Typography
                                              sx={webStyle.radioButtonLabel}
                                            >
                                              {configJSON.type1VolumeText}
                                            </Typography>
                                          </Box>
                                          <Box sx={webStyle.radioButtnBlock}>
                                            <Radio
                                              sx={webStyle.radioButton()}
                                              data-test-id={`meters.${index}.metric.metric_100cu_ft`}
                                              name={`metric_100cu_ft`}
                                              value="100 cu.ft/gallons"
                                              checked={
                                                currentValue.metric?.toLowerCase() ===
                                                  "100 cu.ft/gallons" ||
                                                currentValue.metric?.toLowerCase() ===
                                                  "hundred_cuft_gallons"
                                              }
                                              onChange={(event) => {
                                                const element =
                                                  event.currentTarget as HTMLInputElement;
                                                const value = element.value;
                                                setFieldValue(
                                                  `meters.${index}.metric`,
                                                  value
                                                );
                                              }}
                                            />
                                            <Typography
                                              sx={webStyle.radioButtonLabel}
                                            >
                                              {configJSON.type2VolumeText}
                                            </Typography>
                                          </Box>
                                        </Box>
                                      </Box>
                                    </>
                                  )}
                                  <Box sx={webStyle.mainFieldControl}>
                                    <Typography sx={webStyle.inputLabel}>
                                      {configJSON.meterNumberText}
                                    </Typography>
                                    <input
                                      style={{
                                        ...webStyle.inputElemnt(),
                                        ...(this.state.validate &&
                                        currentError?.meterNumber
                                          ? webStyle.inputElementError
                                          : {}),
                                      }}
                                      data-test-id={`meters.${index}.meterNumber`}
                                      type="text"
                                      value={currentValue.meterNumber || ""}
                                      onChange={(event) => {
                                        setFieldValue(
                                          `meters.${index}.meterNumber`,
                                          event.target.value
                                        );
                                      }}
                                    />
                                    {this.state.validate &&
                                      currentError?.meterNumber && (
                                        <Tooltip
                                          open={Boolean(
                                            currentError?.meterNumber
                                          )}
                                          title={currentError?.meterNumber}
                                          componentsProps={tooltipStyles}
                                          arrow
                                        >
                                          <img
                                            src={errorIcon}
                                            alt=""
                                            style={webStyle.errorIcon}
                                          />
                                        </Tooltip>
                                      )}
                                  </Box>
                                  <Box sx={webStyle.arrayFieldControl}>
                                    <Typography
                                      sx={webStyle.radioButtonTitleLabel}
                                    >
                                      {configJSON.lastReadingAvaliableTitleText}
                                    </Typography>
                                    <Box
                                      sx={webStyle.lastReadingAvaliableGroup}
                                    >
                                      <Box
                                        sx={webStyle.lastReadingAvaliableBlock}
                                      >
                                        <Radio
                                          value="Yes"
                                          checked={
                                            currentValue.lastReadingAvaliable ===
                                            "Yes"
                                          }
                                          sx={webStyle.radioButton()}
                                          data-test-id={`meters.${index}.lastReadingAvaliable.avaliabke`}
                                          name={`meterType.${index}`}
                                          onChange={() => {
                                            setFieldValue(
                                              `meters.${index}.lastReadingAvaliable`,
                                              "Yes"
                                            );
                                          }}
                                        />
                                        <Typography
                                          sx={webStyle.radioButtonLabel}
                                        >
                                          {configJSON.lastReadingAvaliableText}
                                        </Typography>
                                      </Box>
                                      <Box
                                        sx={webStyle.lastReadingAvaliableBlock}
                                      >
                                        <Radio
                                          value="No"
                                          checked={
                                            currentValue.lastReadingAvaliable ===
                                            "No"
                                          }
                                          sx={webStyle.radioButton()}
                                          data-test-id={`meters.${index}.lastReadingAvaliable.unAvaliabke`}
                                          name={`meterType.${index}`}
                                          onChange={() => {
                                            setFieldValue(
                                              `meters.${index}.lastReadingAvaliable`,
                                              "No"
                                            );
                                            setFieldValue(
                                              `meters.${index}.lastReading`,
                                              ""
                                            );
                                            setFieldValue(
                                              `meters.${index}.lastReadingDate`,
                                              ""
                                            );
                                          }}
                                        />
                                        <Typography
                                          sx={webStyle.radioButtonLabel}
                                        >
                                          {
                                            configJSON.lastReadingUnAvaliableText
                                          }
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Box>
                                  <Box sx={webStyle.mainFieldControl}>
                                    <Typography sx={webStyle.inputLabel}>
                                      {configJSON.lastReadingText}
                                    </Typography>
                                    <input
                                      style={{
                                        ...webStyle.inputElemnt(
                                          currentValue?.lastReadingAvaliable ===
                                            "No"
                                        ),
                                        ...(this.state.validate &&
                                        currentError?.lastReading
                                          ? webStyle.inputElementError
                                          : {}),
                                      }}
                                      data-test-id={`meters.${index}.lastReading`}
                                      type="text"
                                      disabled={
                                        currentValue.lastReadingAvaliable ===
                                        "No"
                                      }
                                      value={currentValue.lastReading || ""}
                                      onChange={(event) => {
                                        setFieldValue(
                                          `meters.${index}.lastReading`,
                                          event.target.value
                                        );
                                      }}
                                    />
                                    {this.state.validate &&
                                      currentError?.lastReading && (
                                        <Tooltip
                                          open={Boolean(
                                            currentError?.lastReading
                                          )}
                                          title={currentError?.lastReading}
                                          componentsProps={tooltipStyles}
                                          arrow
                                        >
                                          <img
                                            src={errorIcon}
                                            alt=""
                                            style={webStyle.errorIcon}
                                          />
                                        </Tooltip>
                                      )}
                                  </Box>
                                  <Box sx={webStyle.mainFieldControl}>
                                    <Typography sx={webStyle.inputLabel}>
                                      {configJSON.lastReadingDateText}
                                    </Typography>
                                    <StyledInput
                                      data-test-id={`meters.${index}.lastReadingDate`}
                                      type="date"
                                      placeholder="mm/dd/yyyy"
                                      inputProps={{
                                        max: dayjs().format("YYYY-MM-DD"),
                                      }}
                                      onChange={(event) => {
                                        setFieldValue(
                                          `meters.${index}.lastReadingDate`,
                                          event.target?.value
                                        );
                                      }}
                                      value={
                                        currentValue?.lastReadingDate
                                          ? dayjs(
                                              currentValue?.lastReadingDate
                                            ).format("YYYY-MM-DD")
                                          : ""
                                      }
                                      style={
                                        !currentValue?.lastReadingDate
                                          ? { color: "#94A3B8" }
                                          : undefined
                                      }
                                      sx={{
                                        ...(this.state.validate &&
                                        currentError?.lastReadingDate
                                          ? webStyle.inputElementError
                                          : undefined),
                                        ...(currentValue?.lastReadingAvaliable ===
                                        "No"
                                          ? webStyle.inputDisabled
                                          : undefined),
                                      }}
                                      disabled={
                                        currentValue?.lastReadingAvaliable ===
                                        "No"
                                      }
                                    />
                                    <Tooltip
                                      open={
                                        this.state.validate &&
                                        !!currentError?.lastReadingDate
                                      }
                                      title={currentError?.lastReadingDate}
                                      componentsProps={tooltipStyles}
                                      arrow
                                    >
                                      <img
                                        src={
                                          this.state.validate &&
                                          !!currentError?.lastReadingDate
                                            ? calendarErrorIcon
                                            : calendarIcon
                                        }
                                        alt=""
                                        style={{
                                          ...(webStyle.calendarIcon as any),
                                          ...(currentValue?.lastReadingAvaliable ===
                                          "No"
                                            ? webStyle.inputDisabled
                                            : undefined),
                                        }}
                                      />
                                    </Tooltip>
                                  </Box>
                                  <Box sx={webStyle.mainFieldControl}>
                                    <Typography sx={webStyle.inputLabel}>
                                      {configJSON.meterNoteText}
                                    </Typography>
                                    <input
                                      style={{
                                        ...webStyle.inputElemnt(),
                                        ...(this.state.validate &&
                                        currentError?.meterNote
                                          ? webStyle.inputElementError
                                          : {}),
                                      }}
                                      data-test-id={`meters.${index}.meterNote`}
                                      type="text"
                                      value={currentValue.meterNote || ""}
                                      onChange={(event) => {
                                        setFieldValue(
                                          `meters.${index}.meterNote`,
                                          event.target.value
                                        );
                                      }}
                                    />
                                  </Box>
                                </Box>
                              );
                            })}
                            {this.state.isTenant ? (
                              <Box sx={webStyle.fieldControl}>
                                <Button
                                  sx={webStyle.addNewMeterButton}
                                  // type="button"
                                  variant="text"
                                  data-test-id={"addNewArrayItem"}
                                  onClick={() => {
                                    arrayHelpers.push({
                                      type: "Water",
                                      waterSubType: "Cold",
                                      volume: "Cu ft",
                                      metric: "Standard",
                                      meterNumber: "",
                                      lastReading: "",
                                      lastReadingDate: "",
                                      lastReadingAvaliable: "Yes",
                                      meterNote: "",
                                    });
                                  }}
                                >
                                  <img
                                    src={addIcon}
                                    alt=""
                                    style={webStyle.btnIcon}
                                  />
                                  <Typography sx={webStyle.typographyTitleText}>
                                    {configJSON.addNewMeterText}
                                  </Typography>
                                </Button>
                              </Box>
                            ) : (
                              ""
                            )}
                          </>
                        )}
                      </FieldArray>
                      <Box sx={webStyle.fieldControlGroup}>
                        <Button
                          sx={webStyle.cancelButton}
                          data-test-id="cancelBtn"
                          // type="button"
                          onClick={() => {
                            this.state.isTenant
                              ? this.goTenantsPage()
                              : this.goMetersPage();
                          }}
                        >
                          {configJSON.cancelBtnText}
                        </Button>
                        <Button
                          sx={webStyle.submitButton}
                          data-test-id="submitBtn"
                          // type="button"
                          onClick={() => {
                            this.state.isTenant
                              ? this.setTenantData({
                                  tenantName: values?.tenantName,
                                  metersAttributes: values?.meters,
                                })
                              : this.setMetersData(values?.meters[0]);

                            this.setValidate(true);
                            if (Object.keys(errors).length == 0) {
                              handleSubmit();
                            }
                          }}
                        >
                          {configJSON.saveBtnText}
                        </Button>
                      </Box>
                    </Box>
                  )}
                </form>
              )}
            </Formik>
          </Box>
        </Box>
      </Box>
      //Merge Engine End DefaultContainer
      // Customizable Area Start
    );
  }
}
// Customizable Area Start
const StyledInput = styled(InputBase)(() => ({
  height: "56px",
  padding: "10px 16px 10px 10px",
  borderRadius: "8px",
  border: "1px solid #CBD5E1",
  backgroundColor: "#FFFFFF",
  fontFamily: "'Inter', sans-serif",

  "& .MuiInputBase-input": {
    fontSize: "16px",

    "&::placeholder": {
      fontFamily: "'Inter', sans-serif",
      color: "#94A3B8",
      fontSize: "16px",
      opacity: 1, // Firefox requires this
    },
    "&:disabled": {
      backgroundColor: "#F5F5F4",
    },
  },
}));
const tooltipStyles = {
  tooltip: {
    sx: {
      color: "#FFFFFF",
      fontFamily: "'Inter', sans-serif",
      fontSize: "13px",
      lineHeight: "18px",
      fontWeight: "400",
      padding: "8px 12px",
      backgroundColor: "#1E293B",
      borderRadius: "8px",
      width: "fit-content",
      maxWidth: "480px",
      "& .MuiTooltip-arrow": {
        color: "#1E293B",
      },
    },
  },
};

const webStyle = {
  addTenantsContainer: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#F8FAFC",
    minHeight: "100%",
  },
  addTenantsBlock: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  addTenantFormContainer: {
    maxWidth: "600px",
    width: "80%",
    margin: "40px auto 0",
  },
  formContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  formTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    marginBottom: "10px",
    wordBreak: "break-word",
    "@media (max-width: 500px)": {
      fontSize: "20px",
    },
  },
  formSubTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#475467",
    marginBottom: "20px",
    wordBreak: "break-word",
    "@media (max-width: 500px)": {
      fontSize: "14px",
    },
  },
  fieldControl: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    margin: "10px",
    position: "relative",
  },
  mainFieldControl: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    margin: "10px 0px",
    position: "relative",
  },
  arrayFieldControl: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    margin: "0px",
    position: "relative",
  },
  radioButtonGroup: {
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width: 767px)": {
      // fontSize: "14px",
      flexDirection: "column",
    },
  },
  lastReadingAvaliableGroup: {
    display: "flex",
    justifyContent: "left",
  },
  fieldControlGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  // fieldCheckboxControl: {
  //   display: "flex",
  //   flex: 1,
  //   flexDirection: "row",
  //   margin: "10px",
  //   position: "relative",
  // },
  switchTitle: {
    alignItems: "center",
    display: "flex",
  },
  radioButtonTitleLabel: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#64748B",
    margin: "10px 0px",
    alignItems: "center",
    display: "flex",
  },
  inputLabel: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#334155",
    marginBottom: "5px",
    alignItems: "center",
    display: "flex",
    "@media (max-width: 500px)": {
      fontSize: "12px",
    },
  },
  radioButtonLabel: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#0F172A",
    alignItems: "center",
    display: "flex",
    marginLeft: "8px",
    "@media (max-width: 500px)": {
      fontSize: "14px",
    },
  },
  radioButtnBlock: {
    display: "flex",
    flexDirection: "row",
    margin: "10px 0px",
    alignItems: "center",
    flex: "auto",
    width: "50%",
    "@media (max-width: 500px)": {
      margin: "4px 0px",
      width: "100%",
    },
  },
  lastReadingAvaliableBlock: {
    display: "flex",
    flexDirection: "row",
    margin: "10px 0px",
    alignItems: "center",
    width: "131px",
    flex: "none",
  },
  numberInputElemnt: {
    height: "56px",
    padding: "10px",
    border: "none",
    "-moz-appearance": "textfield",
    "& ::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& ::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    width: "34%",
    textAlign: "center" as const,
  },
  inputElemnt: (disabled: boolean = false) => ({
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#0F172A",
    height: "56px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    backgroundColor: disabled ? "#F5F5F4" : "#FFFFFF",
    "@media (max-width: 500px)": {
      fontSize: "14px",
    },
  }),
  inputElementError: {
    borderRadius: "8px",
    border: "1px solid #F87171",
  },
  radioButtonBlock: {
    display: "flex",
    alignItems: "center",
    marginBottom: "11px",
  },
  radioButton: (isSmall?: boolean) => ({
    padding: 0,
    width: isSmall ? "16px" : "20px",
    height: isSmall ? "16px" : "20px",
    "& .MuiSvgIcon-root": {
      color: "#64748B",
      width: isSmall ? "20px" : "24px",
      height: isSmall ? "20px" : "24px",
    },
    "&.Mui-checked": {
      backgroundColor: "#1A7BA4",
      "& .MuiSvgIcon-root": {
        width: isSmall ? "16px" : "20px",
        height: isSmall ? "16px" : "20px",
      },
      "& .MuiSvgIcon-root:first-of-type": {
        color: "#1A7BA4",
      },
      "& .MuiSvgIcon-root:nth-of-type(2)": {
        color: "#FFFFFF",
      },
    },
  }),
  errorIcon: {
    width: "24px",
    height: "24px",
    position: "absolute" as "absolute",
    top: 43,
    right: 16,
  },
  numberFieldControlIcon: {
    width: "24px",
    height: "24px",
  },
  numberFieldControlButton: {
    color: "#1A7BA41A",
    border: "none",
    width: "33%",
  },
  cancelButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    marginTop: "30px",
    height: "44px",
    borderRadius: "8px",
    border: "1px solid #1A7BA4",
    background: "#F8FAFC",
    color: "#1A7BA4",
    padding: "0px 16px",
    textTransform: "capitalize",
    "@media (max-width: 500px)": {
      fontSize: "14px",
      width: "100%",
    },
  },
  submitButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    margin: "30px 0 0 16px",
    height: "44px",
    borderRadius: "8px",
    border: "none",
    background: "#1A7BA4",
    color: "#FFFFFF",
    padding: "0px 16px",
    textTransform: "capitalize",
    "@media (max-width: 500px)": {
      fontSize: "14px",
      width: "100%",
    },
    "&:hover": {
      backgroundColor: "#1A7BA4",
    },
  },
  meterToReadBlcok: {
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "row",
  },
  meterBlcok: {
    border: "1px solid #1A7BA44D",
    backgroundColor: "#1A7BA40F",
    padding: "16px",
    borderRadius: "12px",
    margin: "20px 0px",
  },
  meterTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  meterTitleLabel: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "18px",
    fontWeight: 700,
    lineheight: "24px",
    color: "#0F172A",
    "@media (max-width: 500px)": {
      fontSize: "16px",
      // width: "100%"
    },
  },
  addNewMeterButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    margin: "15px",
    height: "56px",
    borderRadius: "8px",
    border: "none",
    background: "inherit",
    color: "#1A7BA4",
    padding: "0px 20px",
    flexDirection: "row" as const,
    display: "flex",
    alignItems: "center",
    flex: 1,
    justifyContent: "center",
  },
  removeMeeterButton: {
    height: "36px",
    width: "46px",
    border: "none",
    backgroundColor: "inherit",
    "@media (max-width: 500px)": {
      width: "36px",
      minWidth: "36px",
    },
  },
  typographyTitleText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "22px",
    textTransform: "none",
    "@media (max-width: 500px)": {
      fontSize: "14px",
    },
  },
  btnIcon: {
    width: "24px",
    height: "24px",
  },
  calendarIcon: {
    width: "24px",
    height: "24px",
    position: "absolute" as "absolute",
    top: 43,
    right: 16,
    backgroundColor: "#FFFFFF",
    pointerEvents: "none",
  },
  inputDatePickerElemnt: (placeholder: boolean, disabled: boolean = false) => ({
    height: "56px",
    padding: "0px",
    borderRadius: "8px",
    backgroundColor: disabled ? "#F5F5F4" : "#FFFFFF",
    "& .MuiInputBase-root": {
      paddingRight: "20px",
    },
    "& fieldset": {
      border: "1px solid #CBD5E1 !important",
      borderRadius: "8px",
    },
    "& input": {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
      color: placeholder ? "#94A3B8" : "#0F172A",
      padding: "10px",
      height: "36px",
      borderRadius: "8px",
      textTransform: "lowercase",
    },
  }),
  inputDisabled: {
    backgroundColor: "#F5F5F4",
  },
  loaderWrapper: {
    height: "calc(100vh - 200px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loader: {
    color: "#1A7BA4",
    height: "60px",
    width: "60px",
  },
};
// Customizable Area End
