import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface AlternativeEmail {
  email: string;
  use_for: string[];
}

interface CompanyDetails {
  city: string;
  company_name: string;
  contact_person: string;
  created_at: string;
  fax_number: string;
  mailing_address: string;
  phone_number: string;
  primary_email: string;
  state: string;
  title: string;
  zip_code: string;
  alternative_emails: AlternativeEmail[],
}

interface SnackbarProps {
  isOpen: boolean;
  type: "Error" | "Success";
  messages: string[];
}

interface S {
  token: string;
  zipCodeFieldFocusing: boolean;
  companyDetailId: number | null;
  companyDetails: CompanyDetails | null;
  step: number;
  validate: boolean;
  showPassword: boolean;
  showConfirmPassword: boolean;
  zipCodeError: string;
  isAccountSettingsEdit: boolean;
  isCompanyDetailsEdit: boolean;
  accountSettings: AccountSettings;
  updatedAccountSettings: AccountSettings | null;
  snackbarProps: SnackbarProps;
  // Customizable Area Start
  companyDetailsLoading: boolean;
  accountSettingsLoading: boolean;
  // Customizable Area End
}

export interface AccountSettings {
  zip_code: string;
  full_name: string;

  new_password?: string;
  confirm_password?: string;
}

interface SS {
  id: any;
}

export enum PaymentStatus {
  Paid = "Paid",
  Pending = "Pending",
  NoInvoice = "No Invoice",
}

export default class SettingsPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGetCompanyDetailsCallId: string = "";
  apiAddCompanyDetailsCallId: string = "";
  apiUpdateCompanyDetailsCallId: string = "";
  apiUpdateAccountSettingsCallId: string = "";
  apiGetAccountSettingsCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      token: "",
      step: 1,
      zipCodeFieldFocusing: false,
      zipCodeError: "",
      companyDetailId: 0,
      companyDetails: {
        city: "",
        company_name: "",
        contact_person: "",
        created_at: "",
        fax_number: "",
        mailing_address: "",
        phone_number: "",
        primary_email: "",
        state: "",
        title: "",
        zip_code: "",
        alternative_emails: [],
      },
      accountSettings: {
        full_name: "",
        zip_code: "",
      },
      validate: false,
      snackbarProps: {
        isOpen: false,
        type: "Success",
        messages: [],
      },
      showPassword: false,
      showConfirmPassword: false,
      isAccountSettingsEdit: false,
      isCompanyDetailsEdit: false,
      updatedAccountSettings: null,
      companyDetailsLoading: true,
      accountSettingsLoading: true,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.apiGetCompanyDetailsCallId) {
          if (
            responseJson &&
            responseJson.data &&
            responseJson.data.type === "company_detail"
          ) {
            this.setState({
              companyDetails: responseJson.data.attributes,
              companyDetailId: responseJson.data.id,
            });
          } else if (responseJson && responseJson.data === null) {
            this.setState({ companyDetails: null, companyDetailId: null, isCompanyDetailsEdit: true });
          } else if (responseJson && responseJson.errors) {
            const errorsMsg = responseJson.errors.map(
              (error: any) => Object.values(error)[0]
            );
            if (errorsMsg.includes("Invalid token")) {
              this.goToLogInPage();
            }
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
          }

          this.setState({ companyDetailsLoading: false });
          this.parseApiCatchErrorResponse(errorReponse);
        }
        if (apiRequestCallId === this.apiAddCompanyDetailsCallId) {
          if (responseJson && responseJson.data) {
            this.setState({
              companyDetailId: responseJson.data.id,
              companyDetails: responseJson.data.attributes,
              snackbarProps: {
                isOpen: true,
                messages: ["Company details successfully added!"],
                type: "Success",
              },
              isCompanyDetailsEdit: false,
            });
            setStorageData('completed_setup', 'true');
            window.dispatchEvent(new StorageEvent('storage', {
              key: 'completed_setup',
              newValue: 'true'
            }));
          } else if (responseJson && responseJson.errors) {
            this.setState({
              snackbarProps: {
                isOpen: true,
                messages: responseJson.errors,
                type: "Error",
              },
            });
            const errorsMsg = responseJson.errors.map(
              (error: any) => Object.values(error)[0]
            );
            if (errorsMsg.includes("Invalid token")) {
              this.goToLogInPage();
            }
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
          }

          this.parseApiCatchErrorResponse(errorReponse);
        }
        if (apiRequestCallId === this.apiUpdateCompanyDetailsCallId) {
          if (responseJson && responseJson.data) {
            this.setState({
              companyDetails: responseJson.data.attributes,
              companyDetailId: responseJson.data.id,
              snackbarProps: {
                isOpen: true,
                messages: ["Company details successfully updated!"],
                type: "Success",
              },
              isCompanyDetailsEdit: false,
            });

            const errorsMsg = responseJson?.errors?.map(
              (error: any) => Object.values(error)[0]
            );
            if (errorsMsg?.includes("Invalid token")) {
              this.goToLogInPage();
            }
          } else if (responseJson && responseJson.errors) {
            this.setState({
              snackbarProps: {
                isOpen: true,
                messages: responseJson.errors,
                type: "Error",
              },
            });
            const errorsMsg = responseJson.errors.map(
              (error: any) => Object.values(error)[0]
            );
            if (errorsMsg.includes("Invalid token")) {
              this.goToLogInPage();
            }
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
          }

          this.parseApiCatchErrorResponse(errorReponse);
        }
        if (apiRequestCallId === this.apiGetAccountSettingsCallId) {
          if (responseJson?.data?.attributes) {
            const { full_name, zip_code } = responseJson?.data?.attributes;
            this.setState({ accountSettings: { full_name, zip_code } });
          } else {
            this.setState({
              snackbarProps: {
                isOpen: true,
                messages: ["An unexpected error occurred."],
                type: "Error",
              },
            });
          }

          this.setState({ accountSettingsLoading: false });
        }
        if (apiRequestCallId === this.apiUpdateAccountSettingsCallId) {
          if (responseJson?.message) {
            const updatedAccountSettings = this.state.updatedAccountSettings;
            updatedAccountSettings &&
              this.setState({
                accountSettings: updatedAccountSettings,
                updatedAccountSettings: null,
              });
            this.setState({
              snackbarProps: {
                isOpen: true,
                messages: ["Successfully updated account settings!"],
                type: "Success",
              },
              isAccountSettingsEdit: false,
            });
          } else {
            this.setState({
              snackbarProps: {
                isOpen: true,
                messages: ["An unexpected error occurred."],
                type: "Error",
              },
            });
          }
        }
      }
    }
    // Customizable Area End
  }

  setStep = (step: number) => {
    (step === 1) && this.getCompanyDetails();
    (step === 2) && this.getAccountSettings();

    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'SettingsPage'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);

    this.setState((prevState) => ({ 
      step,
      validate: false,
      isAccountSettingsEdit: false,
      isCompanyDetailsEdit: !prevState.companyDetailId
     }));
  };

  setValidate = (validate: boolean) => {
    this.setState({ validate });
  };

  setShowPassword = (show: boolean) => {
    this.setState({ showPassword: show });
  };

  setShowConfirmPassword = (show: boolean) => {
    this.setState({ showConfirmPassword: show });
  };

  trimSpaces = (value: string) => value.trim().replace(/\s+/g, " ");

  formatCompanyDetailsValues = (valuesData: any) => {
    return {
      city: valuesData?.city || "",
      company_name: this.trimSpaces(valuesData?.company_name || ""),
      contact_person: this.trimSpaces(valuesData?.contact_person || ""),
      created_at: this.trimSpaces(valuesData?.created_at || ""),
      fax_number: this.trimSpaces(valuesData?.fax_number?.toString() || ""),
      mailing_address: this.trimSpaces(valuesData?.mailing_address || ""),
      phone_number: this.trimSpaces(valuesData?.phone_number?.toString() || ""),
      primary_email: this.trimSpaces(valuesData?.primary_email || ""),
      state: valuesData?.state || "",
      title: this.trimSpaces(valuesData?.title || ""),
      zip_code: valuesData?.zip_code || "",
      alternative_emails: valuesData?.alternative_emails?.map((item: AlternativeEmail) => ({ ...item, email: this.trimSpaces(item.email || '') })) || [],
    }
  };

  goToLogInPage = () => {
    this.props.navigation.navigate("LogInPage");
  };

  createCompanyDetails = (values: any) => {
    const header = {
      "Content-Type": configJSON.createCompanyDetailsApiContentType,
      token: this.state.token,
    };

    const httpBody = {
      company_detail: values,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiAddCompanyDetailsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createCompanyDetailsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createCompanyDetailsApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  updateCompanyDetails = (values: any) => {
    const header = {
      "Content-Type": configJSON.updateCompanyDetailsApiContentType,
      token: this.state.token,
    };

    const httpBody = {
      company_detail: values,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUpdateCompanyDetailsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateCompanyDetailsApiEndPoint + "/" + this.state.companyDetailId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateCompanyDetailsApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  getCompanyDetails = () => {
    this.setState({ companyDetailsLoading: true });
    const header = {
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCompanyDetailsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCurrentCompanyDetailsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getCurrentCompanyDetailsApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  getToken = async () => {
    let token = await getStorageData("token");
    this.setState({ token: token });
  };
  // Customizable Area Start

  getAccountSettings = () => {
    this.setState({ accountSettingsLoading: true });

    const header = {
      "Content-Type": configJSON.getAccountSettingsApiContentType,
      "Cache-Control": 'no-cache, no-store, max-age=0, must-revalidate',
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetAccountSettingsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAccountSettingsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAccountSettingsApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateAccountSettings = (account: AccountSettings) => {
    const header = {
      "Content-Type": configJSON.updateAccountSettingsApiContentType,
      token: this.state.token,
    };

    const httpBody = {
      account,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUpdateAccountSettingsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateAccountSettingsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateAccountSettingsApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({ updatedAccountSettings: account });
  };

  closeSnackbar = () => {
    this.setState((prevState) => ({
      snackbarProps: { ...prevState.snackbarProps, isOpen: false },
    }));
  };

  setIsAccountSettingsEdit = () => {
    this.setState((prevState) => ({
      isAccountSettingsEdit: !prevState.isAccountSettingsEdit,
      validate: false,
    }));
  };

  setIsCompanyDetailsEdit = () => {
    this.setState((prevState) => ({
      isCompanyDetailsEdit: !prevState.isCompanyDetailsEdit,
      validate: false,
    }));
  };

  handleReceiveNotificationsChange = (_: boolean) => {
    // TODO: do API call when it's ready
  }
  
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  async componentDidUpdate(prevProps: Props, prevState: S): Promise<void> {
    const type = window.location.search?.replace('?type=', '') || "";

    if (type) { 
      if (type === 'company_settings' && prevState.step !== 1) { this.setStep(1); }
      if (type === 'account_settings' && prevState.step !== 2) { this.setStep(2); }
      if (type === 'notification_settings' && prevState.step !== 3) { this.setStep(3); }
    }
  }

  componentDidMount = async () => {
    const userRole = await getStorageData("role");

    if (userRole !== "user") {
      const message: Message = new Message(
        getName(MessageEnum.NavigationMessage)
      );
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "LogInPage"
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
    }

    await this.getToken();
    await this.getCompanyDetails();
    this.getAccountSettings();
  };
  // Customizable Area End
}
