import React, { memo, useEffect, useRef } from 'react';
import { Chart, ChartConfiguration, ChartDataset } from 'chart.js/auto';
import moment from 'moment';
import {
  Box,
  CircularProgress,
} from "@mui/material";

export interface DashboardChartProps {
  data: { 
    name: string,
    date: string,
    value: number
  }[];
  chartType?: 'line' | 'bar';
  emptyMessage?: string;
  loading?: boolean;
}

const colors = [
  "rgba(31, 119, 180, ",
  "rgba(255, 127, 14, ",
  "rgba(44, 160, 44, ",
  "rgba(214, 39, 40, ",
  "rgba(148, 103, 189, ",
  "rgba(140, 86, 75, ",
  "rgba(227, 119, 194, ",
  "rgba(127, 127, 127, ",
  "rgba(188, 189, 34, ",
  "rgba(23, 190, 207, "
];

export const CustomChart: React.FC<DashboardChartProps> = memo(({ 
  data,
  chartType = 'line',
  emptyMessage = "No data available",
  loading,
}) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstance = useRef<Chart | null>(null);

  useEffect(() => {
    // If no data, do nothing
    if (!chartRef.current) return;

    // Prepare dates for Chart.js
    const uniqueDates = [...new Set(data.map(item => item.date))].sort((a, b) => {
      return moment(a, 'YYYY-MM-DD').unix() - moment(b, 'YYYY-MM-DD').unix();
    });

    // Group data by name
    const meterGroups = data.reduce((acc, item) => {
      if (!acc[item.name]) {
        acc[item.name] = {};
      }
      acc[item.name][item.date] = item.value;
      return acc;
    }, {} as Record<string, Record<string, number>>);

    // Prepare datasets
    const datasets: ChartDataset[] = Object.entries(meterGroups).map(([meterNumber, meterData], index) => {
      // If there are more than 10 datasets we will generate random colors
      const baseColor = index < 10 ? colors[index] : `rgba(${
        Math.floor(Math.random() * 255)}, ${
        Math.floor(Math.random() * 255)}, ${
        Math.floor(Math.random() * 255)}, `;
      
      return {
        label: meterNumber,
        data: uniqueDates.map(date => meterData[date] || 0), // if there are no readings for date we will set it to 0
        borderColor: baseColor + '1)',
        backgroundColor: (context: any) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) return null;

          // No gradient for bar chart type
          if (chartType === 'bar') {
            return baseColor + '1)';
          }

          // Create gradient
          const gradient = ctx.createLinearGradient(
            0, chartArea.bottom, 
            0, chartArea.top
          );

          gradient.addColorStop(0, baseColor + '0.1)'); // 10% opacity at bottom
          gradient.addColorStop(1, baseColor + '0.4)'); // 40% at top

          return gradient;
        },
        tension: 0.1,
        fill: true,
      }
    });

    // Configure chart
    const config: any = {
      type: chartType,
      data: {
        labels: uniqueDates,
        datasets: datasets
      },
      options: {responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            labels: {
              boxWidth: 15,
              font: {
                size: 14,
              }
            }
          },
        },
        scales: {
          x: {
            ticks: {
              font: {
                size: 14,
              }
            }
          },
          y: {
            beginAtZero: true,
            ticks: {
              font: {
                size: 14,
              }
            }
          }
        },
      }
    };

    // Destroy existing chart if it exists
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }
    
    // Create new chart
    chartInstance.current = new Chart(chartRef.current, config);

    const resizeCallback = () => {
      if (!chartInstance?.current) {
        return;
      }
      if (window.innerWidth < 320) {
        config.options.plugins.legend.labels.boxWidth = 8;
        config.options.plugins.legend.labels.font.size = 8;
        config.options.scales.x.ticks.font.size = 8;
        config.options.scales.y.ticks.font.size = 8;
      } else if (window.innerWidth < 680) {
        config.options.plugins.legend.labels.boxWidth = 12;
        config.options.plugins.legend.labels.font.size = 12;
        config.options.scales.x.ticks.font.size = 12;
        config.options.scales.y.ticks.font.size = 12;
      } else {
        config.options.plugins.legend.labels.boxWidth = 14;
        config.options.plugins.legend.labels.font.size = 14;
        config.options.scales.x.ticks.font.size = 14;
        config.options.scales.y.ticks.font.size = 14;
      }
      chartInstance.current.options = config.options;
      chartInstance.current.update();
    }

    window.addEventListener("resize", resizeCallback);

    // Cleanup
    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
      window.removeEventListener("resize", resizeCallback);
    };
  }, [data, chartType]);

  const getCanvasMessage = () => {
    if (loading) {
      return (
        <Box data-test-id="loaderWrapper">
          <CircularProgress sx={styles.loader} />
        </Box>
      )
    }

    if (data.length === 0) {
      return emptyMessage;
    }
  }

  return (
    <Box sx={styles.canvasWrapper}>
      <canvas style={styles.canvas(loading || data.length === 0)} ref={chartRef} />
      <Box sx={styles.canvasMessageWrapper}>
        {getCanvasMessage()}
      </Box>
    </Box>
    );
});

const styles = {
  canvasMessageWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '16px',
    color: '#64748B',
    textAlign: 'center'
  },
  canvas: (isTransparent: boolean) => ({
    opacity: isTransparent ? '0.7' : '1',
  }),
  loader: {
    color: "#1A7BA4",
    height: "60px",
    width: "60px",
  },
  emptyMessage: {
    height: '400px',
    fontSize: '24px',
    fontFamily: "'Inter', sans-serif",
    textAlign: 'center',
    fontWeight: 600,
  },
  canvasWrapper: {
    position: 'relative',
    height:"35vW",
    width:"100%",
    "@media (max-width: 1036px)": {
      height:"45vW",
    },
    "@media (max-width: 500px)": {
      height:"55vW",
    },
  },
};
