import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface Property {
  account_id: number,
  building_manager: string,
  email: string,
  id: number,
  meter_location: string,
  of_meaters_to_read: number,
  of_meters: number,
  of_tenants: number,
  phone_number: string,
  property_name: string,
  reading_period: string,
  service_address: string,
  start_reading_from: string,
}

interface S {
  token: string,
  search: string,
  filteredItemsCount: number,
  deleteButtonDialoge: number | null,
  propertiesList: Property[],
  currentPage:number;
  itemsPerPage:number;
  totalPages:number;
  currentProperties: Property[];
  firstItemIndex:number,
  lastItemIdex:number,
  // Customizable Area Start
  propertiesLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PropertiesPageController extends BlockComponent<Props, S, SS> {
  apiGetPropertiesListCallId: string = "";
  apiDeletePropertyCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      token: "",
      search: "",
      filteredItemsCount: 0,
      deleteButtonDialoge: null,
      propertiesList: [],
      currentPage:1,
      itemsPerPage:10,
      totalPages: 1,
      currentProperties: [],
      firstItemIndex:1,
      lastItemIdex:10,
      propertiesLoading: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.apiGetPropertiesListCallId) {
          if (responseJson && responseJson.data) {
            this.setState({ propertiesList: responseJson.data.map(({ attributes }: any) => attributes) })
          } else if (responseJson && responseJson.errors) {
            const errorsMsg = responseJson.errors.map((error: any) => Object.values(error)[0]);
            if (errorsMsg.includes("Invalid token")) {
              this.goToLogInPage();
            }
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
          }

          this.parseApiCatchErrorResponse(errorReponse);

          this.setState({ propertiesLoading: false });
        }
        if (apiRequestCallId === this.apiDeletePropertyCallId) {
          if (responseJson && responseJson.message && responseJson.message === "Successfully Deleted!") {
            this.getPropertiesList();
            this.setDeleteButtonDialoge(null);
          } else if (responseJson && responseJson.errors) {
            const errorsMsg = responseJson.errors.map((error: any) => Object.values(error)[0]);
            if (errorsMsg.includes("Invalid token")) {
              this.goToLogInPage();
            }
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
          }

          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }
    // Customizable Area End
  }

  searchProperties = (search: string) => {
    this.setState({ search, currentPage: 1 });
  }

  filterProperties = () => {

  }

  addProperties = () => {
    setStorageData('propertyId', -1);

    this.props.navigation.navigate("AddPropertiesPage");
  }

  addTenant = (id: number) => {
    setStorageData('propertyId', id);
    removeStorageData("tenantId");
    setStorageData("isEditMeter", "false");
    setStorageData("isEditTenant", "false");

    this.props.navigation.navigate("AddTenantPage");
  }

  goToLogInPage = () => {
    this.props.navigation.navigate("LogInPage");
  }

  editProperties = (id: number) => {
    setStorageData('propertyId', id);

    this.props.navigation.navigate("AddPropertiesPage");
  }

  gotToAsosiatedTenantLisr = (id: number) => {
    const currentProduct = this.state.propertiesList.find(property =>  property.id === id);

    setStorageData('propertyId', id);
    setStorageData('propertyName', currentProduct?.property_name);
    setStorageData('propertyAddress', currentProduct?.service_address);

    this.props.navigation.navigate("TenantPage");
  }

  deletePropeerties = (id: number | null) => {
    const header = {
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeletePropertyCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deletePropertiesApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deletePropertiesApiEndPoint + "/" + `${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    const userRole = await getStorageData('role');
    const userCompletedSetup = await getStorageData('completed_setup');

    if (userRole !== 'user' || userCompletedSetup === 'false') {
      const message: Message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        userRole !== 'user' ? 'LogInPage' : 'SettingsPage'
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);

      return;
    }
    
    setStorageData('propertyId', -1);

    await this.getToken();
    await this.getPropertiesList();
    await Promise.all([this.updatePagination()]);
  }

  async componentDidUpdate(prevProps: Props, prevState: S): Promise < void> {
    if (    
      prevState.currentPage !== this.state.currentPage ||
      prevState.itemsPerPage !== this.state.itemsPerPage ||
      prevState.propertiesList !== this.state.propertiesList ||
      prevState.search !== this.state.search
    ) {
      await Promise.all([ this.updatePagination()]);
    }
  }

  getPropertiesList = async () => {
    this.setState({ propertiesLoading: true });
    const header = {
      "Content-Type": configJSON.getPropertiesApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetPropertiesListCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPropertiesApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getPropertiesApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleNextPage = () => {
    if (this.state.currentPage < Math.ceil(this.state.propertiesList.length / this.state.itemsPerPage)) {
      this.setState({ currentPage: this.state.currentPage + 1 });
    }
  };

  handlePrevPage = () => {
    if (this.state.currentPage > 1) {
      this.setState({ currentPage: this.state.currentPage - 1 });
    }
  };

  handlePageChange = (pageNumber: number) => {
    this.setState({ currentPage: pageNumber });
  };

  setDeleteButtonDialoge = (deleteButtonDialoge: number | null) => {
    this.setState({ deleteButtonDialoge });
  };

  updatePagination = () => {
    const { currentPage, itemsPerPage, propertiesList, search } = this.state;
    const filteredPropertiesList = search 
      ? propertiesList
        .filter((property) => property.property_name?.toLowerCase().includes(search.toLowerCase())) 
      : propertiesList;
    const filteredItemsCount = filteredPropertiesList.length;
    let indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentProperties = filteredPropertiesList.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredPropertiesList.length / itemsPerPage) || 1;

    if (currentProperties.length === 0) {
      this.handlePageChange(totalPages);
    }

    if (indexOfLastItem > filteredPropertiesList.length ) {
        indexOfLastItem = filteredPropertiesList.length;
    }

    this.setState({
      currentProperties,
      filteredItemsCount,
      totalPages,
      firstItemIndex:indexOfFirstItem,
      lastItemIdex:indexOfLastItem,
    });
  };

  getToken = async () => {
    let token = await getStorageData("token");
    this.setState({ token: token });
  };
  // Customizable Area End
}
