import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import moment from "moment";
import React from "react";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

export enum DateFilter {
  LAST_12_MONTHS,
  LAST_YEAR,
  TWO_YEARS_AGO,
  THREE_YEARS_AGO,
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface Property {
  account_id: number;
  building_manager: string;
  email: string;
  id: number;
  meter_location: string;
  of_meaters_to_read: number;
  of_meters: number;
  of_tenants: number;
  phone_number: string;
  property_name: string;
  reading_period: string;
  service_address: string;
  start_reading_from: string;
}

interface Tenant {
  id: number;
  tenant_name: string;
  meters: Meter[];
}

interface Meter {
  id: number;
  tenant_name: string;
  tenant_id: number;
  meter_number: string;
  meter_type: "Water" | "Electric";
  meter_note: string;
  water_sub_type?: "Hot" | "Cold";
  volume?: string;
  metric?: string;
  last_reading: string;
  date_of_last_reading: string;
}

interface ConsumptionForChart {
  value: number;
  name: string;
  date: string;
}

interface ConsumptionForTable {
  tenant_name: string;
  meter_number: string;
  consumption: number;
  date: string;
}

interface TooltipState {
  isOpen: boolean;
  message: string;
}

interface Customer {
  id: string;
  company_name: string;
}

export enum MeterTypeFilter {
  WATER_COLD = 'Water (Cold)',
  WATER_HOT = 'Water (Hot)',
  ELECTRIC = 'Electric',
}

interface S {
  selectedPropertyId?: number;
  selectedTenantIds: number[];
  selectedMeterIds: number[];
  selectedMeterType?: MeterTypeFilter;
  chartType: "line" | "bar";
  // Customizable Area Start
  dateFilter: DateFilter;
  token: string;
  propertiesList: Property[];
  propertiesLoading: boolean;
  tenantsList: Tenant[];
  tenantsLoading: boolean;
  metersList: Meter[];
  filteredMetersList: Meter[];
  consumptionsLoading: boolean;
  consumptionsForChart: ConsumptionForChart[];
  consumptionsForTable: ConsumptionForTable[];
  currentPage: number;
  totalPages: number;
  firstItemIndex: number;
  lastItemIndex: number;
  itemsPerPage: number;
  displayedConsumptionTableItems: ConsumptionForTable[];
  pdfButtonTooltip: TooltipState;
  customersList: Customer[];
  customersListLoading: boolean;
  customerIdFilter: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export enum PaymentStatus {
  Paid = "Paid",
  Pending = "Pending",
  NoInvoice = "No Invoice",
}

export default class AnaliticsPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGetCustomersListCallId: string;
  apiGetPropertiesListCallId: string;
  apiGetTenantsListCallId: string;
  apiGetConsumptionsCallId: string;
  wrapperRef: React.RefObject<HTMLElement>;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.apiGetCustomersListCallId = "";
    this.apiGetPropertiesListCallId = "";
    this.apiGetTenantsListCallId = "";
    this.apiGetConsumptionsCallId = "";
    this.wrapperRef = React.createRef();

    this.state = {
      selectedTenantIds: [],
      selectedMeterIds: [],
      chartType: "line",
      metersList: [],
      filteredMetersList: [],
      dateFilter: DateFilter.LAST_12_MONTHS,
      propertiesList: [],
      propertiesLoading: false,
      tenantsList: [],
      tenantsLoading: false,
      consumptionsForChart: [],
      consumptionsForTable: [],
      displayedConsumptionTableItems: [],
      consumptionsLoading: false,
      currentPage: 1,
      totalPages: 1,
      firstItemIndex: 1,
      lastItemIndex: 10,
      itemsPerPage: 10,
      pdfButtonTooltip: {
        isOpen: false,
        message: "",
      },
      token: "",
      customersList: [],
      customersListLoading: true,
      customerIdFilter: '',
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    var responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    var errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (apiRequestCallId != null) {
      if (apiRequestCallId === this.apiGetPropertiesListCallId) {
        this.setPropertiesList(responseJson);
        this.parseApiCatchErrorResponse(errorResponse);
      } else if (apiRequestCallId === this.apiGetTenantsListCallId) {
        this.setTenantsList(responseJson);
        this.parseApiCatchErrorResponse(errorResponse);
      } else if (apiRequestCallId === this.apiGetConsumptionsCallId) {
        this.setConsumptions(responseJson);
        this.parseApiCatchErrorResponse(errorResponse);
      } else if (apiRequestCallId === this.apiGetCustomersListCallId) {
        this.setCustomersList(responseJson);
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
    // Customizable Area End
  }

  changeDateFilter = (value: DateFilter) => {
    this.setState({ dateFilter: value, currentPage: 1 });
    if (this.state.selectedMeterIds.length) {
      const { startDate, endDate } = this.getStartEndDates(value);
      this.getConsumptions(this.state.selectedMeterIds, startDate, endDate);
    }
  };

  changeSelectedPropertyId = (value?: number) => {
    this.setState({
      selectedPropertyId: value,
      tenantsList: [],
      selectedTenantIds: [],
      selectedMeterIds: [],
      metersList: [],
      selectedMeterType: undefined,
      consumptionsForTable: [],
      consumptionsForChart: [],
      currentPage: 1,
    });

    if (value) {
      this.getTenantsList(value);
    }
  };

  changeSelectedTenantIds = (value: number[]) => {
    const newMetersList = this.state.tenantsList.reduce<Meter[]>(
      (acc, tenant) => {
        if (value.includes(tenant.id)) {
          acc = [...acc, ...tenant.meters];
        }

        return acc;
      },
      []
    );

    this.setState({
      selectedTenantIds: value,
      metersList: newMetersList,
      selectedMeterIds: [],
      selectedMeterType: undefined,
      consumptionsForTable: [],
      consumptionsForChart: [],
      currentPage: 1,
    });
  };

  changeSelectedMeterType = (value?: MeterTypeFilter) => {
    this.setState((prevState) => ({
      selectedMeterType: value,
      filteredMetersList: prevState.metersList.filter(
        (meter) => {
          if (value === MeterTypeFilter.ELECTRIC) {
            return meter.meter_type === value;
          }

          if (value === MeterTypeFilter.WATER_COLD) {
            return meter.meter_type === 'Water' && meter.water_sub_type === 'Cold';
          }

          if (value === MeterTypeFilter.WATER_HOT) {
            return meter.meter_type === 'Water' && meter.water_sub_type === 'Hot';
          }
        }
      ),
      selectedMeterIds: [],
      consumptionsForTable: [],
      consumptionsForChart: [],
      currentPage: 1,
    }));
  };

  changeSelectedMeterIds = (value: number[]) => {
    this.setState({ selectedMeterIds: value, currentPage: 1 });

    if (value.length) {
      const { startDate, endDate } = this.getStartEndDates(
        this.state.dateFilter
      );
      this.getConsumptions(value, startDate, endDate);
    } else {
      this.setState({ consumptionsForChart: [], consumptionsForTable: [] });
    }
  };

  changeChartType = (chartType: "line" | "bar") => {
    this.setState({ chartType });
  };

  // Customizable Area Start
  getPropertiesList = (customerId: string) => {
    const header = {
      "Content-Type": "application/json",
      "Cache-Control": "no-cache, no-store, max-age=0, must-revalidate",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetPropertiesListCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_forms/properties?company_detail_id=${customerId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "Get"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({ propertiesLoading: true });
  };

  setPropertiesList = (responseJson: any) => {
    this.setState({ propertiesLoading: false });

    if (responseJson?.data) {
      this.setState({
        propertiesList: responseJson.data.map(
          ({ attributes }: any) => attributes
        ),
      });
    } else if (responseJson && responseJson.errors) {
      const errorsMsg = responseJson.errors.map(
        (error: any) => Object.values(error)[0]
      );
      if (errorsMsg.includes("Invalid token")) {
        this.goToLoginPage();
      }
    } else {
      //Check Error Response
      this.parseApiErrorResponse(responseJson);
    }
  };

  getTenantsList = (propertyId: number) => {
    const header = {
      "Content-Type": "application/json",
      "Cache-Control": "no-cache, no-store, max-age=0, must-revalidate",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetTenantsListCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_forms/properties/${propertyId}/tenants?company_detail_id=${this.state.customerIdFilter}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "Get"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({ tenantsLoading: true });
  };

  setTenantsList = (responseJson: any) => {
    this.setState({ tenantsLoading: false });

    if (responseJson?.data) {
      this.setState({
        tenantsList: responseJson.data.map(({ attributes }: any) => attributes),
      });
    } else if (responseJson && responseJson.errors) {
      const errorsMsg = responseJson.errors.map(
        (error: any) => Object.values(error)[0]
      );
      if (errorsMsg.includes("Invalid token")) {
        this.goToLoginPage();
      }
    } else {
      //Check Error Response
      this.parseApiErrorResponse(responseJson);
    }
  };

  getConsumptions = (
    meterIds: number[],
    startDate: string,
    endDate: string
  ) => {
    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetConsumptionsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_forms/meters/consumption_filter?start_date=${startDate}&end_date=${endDate}${meterIds.map((id) => `&meter_ids[]=${id}`).join('')}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "Get"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({ consumptionsLoading: true });
  };

  setConsumptions = (responseJson: any) => {
    if (responseJson?.data) {
      this.setState({
        consumptionsForTable: responseJson.data.map((item: any) => ({
          tenant_name: item.tenant,
          meter_number: item.meter_number,
          consumption: item.consumption,
          date: item.reading_date,
        })),
        consumptionsForChart: responseJson.data.map((item: any) => ({
          name: item.meter_number,
          value: item.consumption,
          date: item.reading_date,
        })),
      });
    } else {

    }
    this.setState({
      consumptionsLoading: false,
    });
  };

  getStartEndDates = (dateFilter: DateFilter) => {
    let startDate;
    let endDate;

    switch (dateFilter) {
      case DateFilter.LAST_12_MONTHS: {
        startDate = moment().subtract(12, "months").startOf('month').format('YYYY-MM-DD');
        endDate = moment().format('YYYY-MM-DD');

        break;
      }
      case DateFilter.LAST_YEAR: {
        startDate = moment().subtract(1, "year").startOf("year").format('YYYY-MM-DD');
        endDate = moment().subtract(1, "year").endOf("year").format('YYYY-MM-DD');

        break;
      }
      case DateFilter.TWO_YEARS_AGO: {
        startDate = moment().subtract(2, "year").startOf("year").format('YYYY-MM-DD');
        endDate = moment().subtract(2, "year").endOf("year").format('YYYY-MM-DD');

        break;
      }
      case DateFilter.THREE_YEARS_AGO: {
        startDate = moment().subtract(3, "year").startOf("year").format('YYYY-MM-DD');
        endDate = moment().subtract(3, "year").endOf("year").format('YYYY-MM-DD');

        break;
      }
    }

    return { startDate, endDate };
  };

  updatePagination = () => {
    const { currentPage, itemsPerPage, consumptionsForTable } = this.state;
    let indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const displayedConsumptionTableItems = consumptionsForTable.slice(
      indexOfFirstItem,
      indexOfLastItem
    );
    const totalPages = Math.ceil(consumptionsForTable.length / itemsPerPage);

    if (indexOfLastItem > consumptionsForTable.length) {
      indexOfLastItem = consumptionsForTable.length;
    }

    this.setState({
      displayedConsumptionTableItems,
      totalPages,
      firstItemIndex: indexOfFirstItem,
      lastItemIndex: indexOfLastItem,
    });
  };

  async componentDidUpdate(_: Props, prevState: S): Promise<void> {
    if (
      prevState.currentPage !== this.state.currentPage ||
      prevState.itemsPerPage !== this.state.itemsPerPage ||
      prevState.consumptionsForTable !== this.state.consumptionsForTable
    ) {
      this.updatePagination();
    }
  }

  handleNextPage = () => {
    if (
      this.state.currentPage <
      Math.ceil(
        this.state.consumptionsForTable.length / this.state.itemsPerPage
      )
    ) {
      this.setState({ currentPage: this.state.currentPage + 1 });
    }
  };

  handlePrevPage = () => {
    if (this.state.currentPage > 1) {
      this.setState({ currentPage: this.state.currentPage - 1 });
    }
  };

  handlePageChange = (pageNumber: number) => {
    this.setState({ currentPage: pageNumber });
  };

  downloadAsPDF = async () => {
    // If user hasn't selected any meters yet - show tooltip
    if (!this.state.consumptionsForTable.length) {
      this.openPdfButttonTooltip("There is no data available for export.");
      return;
    } else {
      this.openPdfButttonTooltip(
        "Download started! Your file will be ready shortly."
      );
    }

    const pdf = new jsPDF("p"); // Portrait orientation

    // Get page dimensions
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();

    // Find selected property name
    const selectedPropertyName = this.state.propertiesList.find(
      (property) => property.id === this.state.selectedPropertyId
    )?.property_name;

    const titleText = `Consumption for ${selectedPropertyName} property`;
    const maxWidth = pageWidth - 40;
    const textLines = pdf.splitTextToSize(titleText, maxWidth);
    let yPosition = 10;

    // Add title
    pdf.setFontSize(18);
    pdf.setTextColor(0, 0, 0);
    textLines.forEach((line: string) => {
      pdf.text(line, pageWidth / 2, yPosition, { align: "center" });
      yPosition += 8; // Break the lines
    });

    // Get the chart canvas element
    const chartCanvas = document.querySelector('canvas');
    if (chartCanvas) {
      // Convert the canvas to an image
      const chartImage = chartCanvas.toDataURL('image/png');
      
      // Calculate dimensions to maintain aspect ratio
      const chartAspectRatio = chartCanvas.height / chartCanvas.width;
      const chartWidth = pageWidth - 40; // 20mm margins on each side
      const chartHeight = chartWidth * chartAspectRatio;
      const titleHeight = textLines.length * 8; // Height of title
      const chartStartY = titleHeight + 10; // Add 10mm from title
      
      // Add the chart image to PDF
      pdf.addImage(
        chartImage,
        'PNG',
        20, // x position (20mm from left)
        chartStartY,
        chartWidth,
        chartHeight
      );

      // Update starting position for table
      const tableStartY = chartStartY + chartHeight + 20; // 30mm padding after chart

      // Prepare data for auto-table
      const tableData = this.state.consumptionsForTable.map((item) => [
        item.meter_number,
        item.tenant_name,
        item.consumption,
        item.date,
      ]);

      // Generate PDF with auto-table
      // @ts-ignore - autoTable is present in pdf object (library bug)
      pdf.autoTable({
        head: [["Meter#", "Tenant", "Consumption", "Date"]],
        body: tableData,
        theme: "striped",
        styles: {
          fontSize: 10,
          cellPadding: 3,
          overflow: "linebreak",
        },
        columnStyles: {
          0: { cellWidth: 50 }, // Meter#
          1: { cellWidth: 60 }, // Tenant
          2: { cellWidth: 30 }, // Consumption
          3: { cellWidth: 40 }, // Date
        },
        headStyles: {
          fillColor: [26, 123, 164],
          textColor: 255,
        },
        startY: tableStartY, // Start table below the chart
        margin: {
          top: 20,
        },
        didDrawPage: function(data: any) {
          // Add page number to each page
          pdf.setFontSize(10);
          pdf.setTextColor(0, 0, 0);
          pdf.text(
            `Page ${data.pageNumber}`,
            pageWidth / 2,
            pageHeight - 10,
            {
              align: "center",
            }
          );
        },
      });
    }

    // Save the PDF
    pdf.save(`consumptions-for-${selectedPropertyName}-export.pdf`);

    return true;
  };

  openPdfButttonTooltip = (message: string) => {
    this.setState({
      pdfButtonTooltip: {
        message,
        isOpen: true,
      },
    });

    setTimeout(() => {
      this.setState((prevState) => ({
        pdfButtonTooltip: {
          ...prevState.pdfButtonTooltip,
          isOpen: false,
        },
      }));
    }, 3000);
  };

  getCustomersList = () => {
    this.setState({ customersListLoading: true });

    const header = {
      "Content-Type": "application/json",
      token: this.state.token
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCustomersListCallId = requestMessage.messageId;

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        'bx_block_custom_forms/company_details',
    );
    
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'Get'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  setCustomersList = (responseJson: any) => {
    if (responseJson?.data) {
      const customers: Customer[] = responseJson.data.map((item: any): Customer => {
        return {
          id: item.attributes.id || "",
          company_name: item.attributes.company_name || "",
        };
      });
      this.setState({ customersList: customers })
    } else {

    }

    this.setState({ customersListLoading: false });
  }

  changeCustomerFilter = (customerId: string) => {
    this.setState({
      tenantsList: [],
      selectedTenantIds: [],
      selectedMeterIds: [],
      metersList: [],
      selectedMeterType: undefined,
      consumptionsForTable: [],
      consumptionsForChart: [],
      currentPage: 1,
      customerIdFilter: customerId,
      selectedPropertyId: undefined,
    })
    this.getPropertiesList(customerId);
  }

  goToLoginPage = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(getName(MessageEnum.NavigationTargetMessage), "LogInPage");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  componentDidMount = async () => {
    const userRole = await getStorageData("role");

    if (userRole !== "admin") {
      this.goToLoginPage();

      return;
    }

    await this.getToken();
    this.getCustomersList();
  };
  getToken = async () => {
    let token = await getStorageData("token");
    this.setState({ token: token });
  };
  // Customizable Area End
}
