import React from "react";
// Customizable Area Start

import { Typography, Box, Button } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";

// Customizable Area End

import SupportPageController, {
  Props,
  configJSON,
} from "./SupportPageController";
// Customizable Area Start

// Customizable Area End
export default class SupportPage extends SupportPageController {
  constructor(props: Props) {
    super(props);
  }

  supportSchema = () => {
    return Yup.object().shape({
      sevicesNeeded: Yup.string().required("Services Neede is required"),
      message: Yup.string().required("Mssage is required"),
    });
  };

  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Box sx={webStyle.supportContainer}>
        <Box sx={webStyle.supportBlock}>
          <Box sx={webStyle.supportFormContainer}>
            <Box sx={webStyle.supportTitle}>
              <Typography style={webStyle.formTitle}>
                {configJSON.supportTitleText}
              </Typography>
            </Box>
            <Formik
              initialValues={{
                servicesNeeded: "",
                message: "",
              }}
              validateOnMount={true}
              validateOnChange={true}
              validationSchema={this.supportSchema}
              onSubmit={() => {
                this.setValidate(true);
              }}
              data-test-id="supportForm"
            >
              {({ setFieldValue, values, errors, handleSubmit }) => (
                <form>
                  <Box sx={webStyle.formContainer}>
                    <Box sx={webStyle.fieldControl}>
                      <Typography sx={webStyle.inputLabel}>
                        {configJSON.servicesNeededText}
                      </Typography>
                      <input
                        style={webStyle.inputElemnt}
                        data-test-id="servicesNeeded"
                        type="text"
                        value={values?.servicesNeeded}
                        onChange={(event) => {
                          setFieldValue("servicesNeeded", event.target.value);
                        }}
                      />
                    </Box>
                    <Box sx={webStyle.fieldControl}>
                      <Typography sx={webStyle.inputLabel}>
                        {configJSON.messageText}
                      </Typography>
                      <textarea
                        style={webStyle.textAreaElement}
                        data-test-id="message"
                        value={values?.message}
                        onChange={(event) => {
                          setFieldValue("message", event.target.value);
                        }}
                      />
                    </Box>
                    <Box sx={webStyle.fieldControlFirstStepGroup}>
                      <Button
                        sx={webStyle.submitButton}
                        data-test-id="submitBtn"
                        // type="button"
                        onClick={() => {
                          this.setValidate(true);
                          if (Object.keys(errors).length === 0) {
                            handleSubmit();
                          }
                        }}
                      >
                        <Typography sx={webStyle.buttonText}>
                          {configJSON.sendText}
                        </Typography>
                      </Button>
                    </Box>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
      </Box>

      //Merge Engine End DefaultContainer
      // Customizable Area Start
    );
  }
}
// Customizable Area Start
const webStyle = {
  supportContainer: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#F8FAFC",
    height: '100%',
  },
  supportBlock: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  supportFormContainer: {
    margin: "40px auto 0",
    maxWidth: "600px",
    width: "80%",
  },
  supportTitle: {
    margin: "0 10px 40px",
  },
  formContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  formTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "29px",
    marginBottom: "15px",
  },
  fieldControl: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    margin: "10px",
    position: "relative",
  },
  fieldControlFirstStepGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  inputLabel: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#334155",
    marginBottom: "5px",
    alignItems: "center",
    display: "flex",
    // "@media (max-width: 1330px)": {
    //   fontSize: "12px",
    // },
    // "@media (max-width: 1036px)": {
    //   fontSize: "14px",
    // },
    "@media (max-width: 870px)": {
      // width: "50%",
      fontSize: "12px",
    },
  },
  textAreaElement: {
    height: "139px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
  },
  submitButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    margin: "15px",
    height: "44px",
    borderRadius: "8px",
    border: "none",
    background: "#1A7BA4",
    color: "#FFFFFF",
    padding: "10px 16px",
    flexDirection: "row" as const,
    display: "flex",
    textTransform: "none",
    alignItems: "center",
    "@media (max-width: 500px)": {
      // width: "50%",
      width: "100%",
    },
    "&:hover": {
      backgroundColor: "#1A7BA4",
    },
  },
  buttonText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
  },
  inputElemnt: {
    height: "56px",
    padding: "10px",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    // "@media (max-width: 1330px)": {
    //   fontSize: "14px",
    // },
    // "@media (max-width: 1036px)": {
    //   fontSize: "16px",
    // },
    "@media (max-width: 870px)": {
      // width: "50%",
      fontSize: "14px",
    },
  },
};
// Customizable Area End
