import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Typography, Box, Button } from '@mui/material';
import PowerSettingsNewRoundedIcon from '@mui/icons-material/PowerSettingsNewRounded';
export const configJSON = require("../../blocks/landingpage/src/config");

import { removeStorageData } from '../../framework/src/Utilities';
import AnalyticsSVGIcon from './AnalyticsSVGIcon';
import SettingSVGIcon from './SettingSVGIcon';
import HelpSVGIcon from './HelpSVGIcon';
import ReportSVGIcon from './ReportSVGIcon';
import PropertySVGIcon from './PropertySVGIcon';
import AccountController, { Props } from './AccountController';
export default class AdminSideBar extends AccountController {
  constructor(props: Props) {
    super(props);
  }

  logOut = async () => {
    await removeStorageData('role');
    await removeStorageData('token');
  }
  
  render() {
    const isDashboard = ["/DashboardPage"].includes(window.location.pathname)
    const isProperties = ["/PropertiesPage", '/TenantPage', '/MeterPage'].includes(window.location.pathname)
    const isReport = ["/ReportsPage"].includes(window.location.pathname)
    const isSetting = ["/SettingsPage"].includes(window.location.pathname)
    const isSupport = ["/SupportPage"].includes(window.location.pathname)
    
    return (
      <Box sx={styles.sideBarConatiner}>
        <Box sx={styles.sideBarWrapper}>
          <Box sx={styles.listContainer}>
            <Box sx={styles.navContainer}>
              {this.state.completedSetup && (
            <>
              <Link to="/DashboardPage" style={styles.linkStyle}>
                <Box sx={styles.listItem(isDashboard)}>
                  <AnalyticsSVGIcon sx={styles.fillSvg(isDashboard)} />
                  <Typography sx={styles.listItemText(isDashboard)}>Dashboard</Typography>
                </Box>
              </Link>
              <Link to="/PropertiesPage" style={styles.linkStyle}>
                <Box sx={styles.listItem(isProperties)}>
                  <PropertySVGIcon sx={styles.fillSvg(isProperties)} />
                  <Typography sx={styles.listItemText(isProperties)}>Properties</Typography>
                </Box>
              </Link>
              <Link to="/ReportsPage" style={styles.linkStyle}>
                <Box sx={styles.listItem(isReport)}>
                  <ReportSVGIcon sx={styles.fillSvg(isReport)} />
                  <Typography sx={styles.listItemText(isReport)}>Reports</Typography>
                </Box>
              </Link>
            </>)}
              <Link to="/SettingsPage" style={styles.linkStyle}>
                <Box sx={styles.listItem(isSetting)}>
                  <SettingSVGIcon sx={styles.fillSvg(isSetting)} />
                  <Typography sx={styles.listItemText(isSetting)}>Settings</Typography>
                </Box>
              </Link>
              <Link to="/SupportPage" style={styles.linkStyle}>
                <Box sx={styles.listItem(isSupport)}>
                  <HelpSVGIcon sx={styles.fillSvg(isSupport)} />
                  <Typography sx={styles.listItemText(isSupport)}>Support</Typography>
                </Box>
              </Link>
            </Box>
            <Link to="/LogInPage" style={styles.logoutButton} data-test-id="logoutButton" onClick={this.logOut}>
              <PowerSettingsNewRoundedIcon sx={styles.logoutIcon} />
              <Typography sx={styles.logoutText}>Log out</Typography>
            </Link>
          </Box>
        </Box>
      </Box>
    );
  }
}
const styles = {
  sideBarConatiner: {
    position: "relative",
    boxShadow: "0px 2px 8px 0px #00000014",
    zIndex: '100',
  },
  sideBarWrapper: {
    minHeight: "100%",
  },
  listContainer: {
    height: "100%",
    padding: "0 24px",
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    "@media (max-width: 1036px)": {
      padding: "0px",
    },
  },
  navContainer: {
    paddingTop: '24px',
  },
  listItem: (isActive: boolean = false) => ({
    backgroundColor:  isActive ?  "#E8F9FC" : "#FFFFFF",
    border: "none",
    borderRadius: "18px",
    width: "293px",
    height: "40px",
    padding: "8px 12px 8px 12px",
    alignItem: "center",
    margin: "0px 10px",
    display: "flex",
    flexDirectiion: "row",
    textDecoration: "none",
    "@media (max-width: 1036px)": {
      width: "40px",
    },
  }),
  linkStyle: {
    textDecoration: "none",
  },
  fillSvg: (isActive: boolean = false) => ({
    margin: '8px 8px 8px 0',
    '> path': {
      fill: isActive ? "#1A7BA4" : '#64748B',
      strokeWidth: "0px",
    },
    "@media (max-width: 1036px)": {
      margin: "8px",
    },
  }),
  listItemText: (isActive: boolean = false) => ({
    alignItems: "center",
    flex: 1,
    display: "flex",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: isActive ? 700 : 400,
    lineHeight: "24px",
    color: isActive ? "#1A7BA4" : "#64748B",
    "@media (max-width: 1036px)": {
      display: "none",
    },
  }),
  logoutButton: {
    width: 'fit-content',
    height: "56px",
    padding: "8px 12px 8px 12px",
    margin: "0px 10px 24px",
    display: "flex",
    flexDirectiion: "row",
    textDecoration: "none",
    backgroundColor: '#FFFFFF',
    justifyContent: 'flex-start',
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
  },
  logoutIcon: {
    color: '#F87171',
    margin: '0 8px',
    height: '24px',
  },
  logoutText: {
    fontFamily: "'Inter', sans-serif",
    color: '#F87171',
    fontSize: '16px',
    textTransform: 'none',
    "@media (max-width: 1036px)": {
      display: "none",
    },
  }
};
