import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { setStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  showPassowrd: boolean;
  showConfirmPassword: boolean;
  validate: boolean;
  password:string;
  confirmPassword:string
  // Customizable Area Start
    passwordError:string,
    confirmPasswordError:string,
    isTokenExpired: boolean,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ResetPasswordPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  resetPasswordApiCallId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.resetPasswordApiCallId = "";
  
    this.state = {
      showPassowrd: false,
      showConfirmPassword: false,
      validate: false,
      confirmPassword:"",
      password:"",
      passwordError:"",
      confirmPasswordError:"",
      isTokenExpired: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (!apiRequestCallId || !responseJson) {
      return;
    }

    if(apiRequestCallId === this.resetPasswordApiCallId && responseJson.errors?.at(0).token === "Invalid token") {
      this.setState({isTokenExpired: true})
    }

    if (apiRequestCallId === this.resetPasswordApiCallId && !!responseJson.message) {
        setStorageData("resetPasswordMsg", responseJson.message);
        this.goToLogIn();   
    }
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  setValidate = (validate: boolean) => {
    this.setState({ validate });
  };

  setShowPassword = (show: boolean) => {
    this.setState({ showPassowrd: show });
  };

  setShowConfirmPassword = (show: boolean) => {
    this.setState({ showConfirmPassword: show });
  };

  // Customizable Area Start
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToForgotPasswordPage() {
    this.props.navigation.navigate("ForgotPasswordPage");
  }

  setPassword(password:string) {
   this.setState({password})
  }

  setConfirmPassword(confirmPassword:string) {
    this.setState({confirmPassword})
  }

  goToLogIn() {
    console.log("Go to LogIn Page");
      const message: Message = new Message(getName(MessageEnum.NavigationMessage))
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "LogInPage"
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
      this.send(message);
  }

  resetPasswordEmailNotification = () => {
    const token = window.location.href.match(/[?&]token=([^&#]*)/)?.[1];
    
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
  
    const requestBody = {
        data:
         {
          token:token,
          new_password: this.state.password,
          confirm_password: this.state.confirmPassword
         }
        };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postResetPasswordEndPoint
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );
  
    this.resetPasswordApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area End
}
