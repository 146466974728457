import React from "react";
// Customizable Area Start

import {
  Typography,
  Box,
  Tabs,
  Tab,
  CircularProgress,
  Select,
  MenuItem,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Divider,
  InputBase,
  Snackbar,
  Tooltip,
} from "@mui/material";
import { Formik, FieldArray } from "formik";
import * as Yup from "yup";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { v4 as uuidv4 } from 'uuid';
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { createPortal } from 'react-dom';
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { errorIcon } from './assets';
// Customizable Area End

import AdminSettingsPageController, {
  Price,
  Props,
  configJSON,
} from "./AdminSettingsPageController";
// Customizable Area Start

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#1A7BA4",
        opacity: 1,
        border: 0,
        ...theme.applyStyles("dark", {
          backgroundColor: "#2ECA45",
        }),
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.grey[100],
      ...theme.applyStyles("dark", {
        color: theme.palette.grey[600],
      }),
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
      ...theme.applyStyles("dark", {
        opacity: 0.3,
      }),
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
    ...theme.applyStyles("dark", {
      backgroundColor: "#39393D",
    }),
  },
}));

interface WaterElectricalSettingsDialog {
  isOpen: boolean;
  rateName: string;
  priceIndex?: number;
  rateNameError?: string;
}

interface WaterElectricalDeletePriceDialog {
  isOpen: boolean;
  priceIndex: number;
}

export const trimSpaces = (value: string) => value.trim().replace(/\s+/g, ' ');

// Customizable Area End
export default class AdminSettingsPage extends AdminSettingsPageController {
  constructor(props: Props) {
    super(props);
  }

  serviceChargeSchema = () => {
    return Yup.object().shape({
      serviceCharge: Yup.string().required("Service Charge is required"),
    });
  };

  pricesSchema = () => {
    return Yup.object().shape({
      prices: Yup.array().of(
        Yup.object().shape({
          consumptions: Yup.array().of(
            Yup.object().shape({
              amount: Yup.number().required('Amount is required'),
              rate: Yup.number().required('Rate is required')
            })
          )
        })
      )
    })
  };

  serviceChargeForm = () => {
    if (this.state.serviceChargeLoading) {
      return (
        <Box sx={webStyle.loaderWrapper} data-test-id="loaderWrapper">
          <CircularProgress sx={webStyle.loader} />
        </Box>
      )
    }

    return (
      <Formik
        initialValues={{
          serviceCharge: this.state.serviceCharge,
          isEdit: false,
        }}
        validateOnMount={true}
        validateOnChange={true}
        validationSchema={this.serviceChargeSchema}
        onSubmit={(values, { setFieldValue }) => {
          this.setValidate(false);
          this.editServiceCharge(values.serviceCharge, () => setFieldValue('isEdit', false));
        }}
        data-test-id="serviceChargeForm"
      >
        {({ setFieldValue, values, errors, handleSubmit }) => (
          <form>
            <Box sx={webStyle.formContainer}>
              <Box sx={{...webStyle.columnFieldControl, margin: 0}}>
                <Typography sx={webStyle.inputLabel}>
                  {configJSON.serviceChargeText}
                </Typography>
                <Box sx={{ position: 'relative' }}>
                  <input
                    style={{...webStyle.inputElemnt, paddingLeft: '18px', ...(!values.isEdit ? webStyle.inputElemntDisabled : {}), ...(this.state.validate && errors.serviceCharge
                      ? webStyle.inputElementError
                      : {}),}}
                    data-test-id="serviceCharge"
                    type="text"
                    value={values.serviceCharge}
                    onChange={(event) => {
                      /^\d*\.?\d*$/.test(event.target.value) && setFieldValue("serviceCharge", event.target.value);
                    }}
                    disabled={!values.isEdit}
                  />
                  {this.state.validate && errors.serviceCharge && (
                    <Tooltip
                      open={true}
                      title={errors.serviceCharge}
                      componentsProps={tooltipStyles}
                      arrow
                    >
                      <img src={errorIcon} alt="" style={webStyle.errorIcon} />
                    </Tooltip>
                  )}
                  <Box sx={webStyle.dollarSign}>$</Box>
                </Box>
              </Box>
              <Box sx={webStyle.fieldControlFirstStepGroup}>
                {values.isEdit ? (
                  <>
                    <Button
                      sx={webStyle.cancelButton}
                      data-test-id="cancelBtn"
                      // type="button"
                      onClick={() => {
                        setFieldValue('isEdit', false);
                        setFieldValue('serviceCharge', this.state.serviceCharge);
                        this.setValidate(false);
                      }}
                    >
                      <Typography sx={webStyle.buttonText}>{configJSON.cancelButtonText}</Typography>
                    </Button>
                    <Button
                      sx={webStyle.submitButton}
                      data-test-id="submitBtn"
                      // type="button"
                      onClick={() => {
                        this.setValidate(true);
                        if (Object.keys(errors).length === 0) {
                          handleSubmit();
                        }
                      }}
                    >
                      <Typography sx={webStyle.buttonText}>{configJSON.submitButtonText}</Typography>
                    </Button>
                  </>
                ) : (
                <Button
                  sx={webStyle.submitButton}
                  data-test-id="editBtn"
                  // type="button"
                  onClick={() => {
                    setFieldValue('isEdit', true);
                  }}
                >
                  <Typography sx={webStyle.buttonText}>{configJSON.editButtonText}</Typography>
                </Button>
                )}
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    );
  };

  notificationForm = () => {
    return (
      <Formik
        initialValues={{
          // serviceCharge: "$12",
          notification: true,
        }}
        validateOnMount={true}
        validateOnChange={true}
        // validationSchema={this.serviceChargeSchema}
        onSubmit={() => {
          this.setValidate(true);
        }}
        data-test-id="notificationForm"
      >
        {({ setFieldValue, values, handleSubmit }) => (
          <form>
            <Box sx={webStyle.formContainer}>
              <Box sx={webStyle.fieldCheckboxControl}>
                <Typography style={webStyle.switchTitle}>
                  {configJSON.turnNotificationText}
                </Typography>
                <IOSSwitch
                  sx={{ m: 1 }}
                  defaultChecked={values.notification}
                  data-test-id="turnNotification"
               />
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    );
  };

  pricesSettingsForm = (isElectrical?: boolean) => {
    if ((!isElectrical && this.state.waterPricesLoading) || (isElectrical && this.state.electricalPricesLoading)) {
      return (
        <Box sx={webStyle.loaderWrapper} data-test-id="loaderWrapper">
          <CircularProgress sx={webStyle.loader} />
        </Box>
      )
    }

    const hasPrices = isElectrical ? this.state.electricalPrices.length > 0 : this.state.waterPrices.length > 0;

    return (
      <Formik
        initialValues={{
          prices: isElectrical ? this.state.electricalPrices : this.state.waterPrices,
          selectedPrice: hasPrices ? 0 : -1,
          isAdd: false,
          isEdit: false,
          dialogProps: {
            isOpen: false,
            rateName: '',
          },
          deleteDialogProps: {
            isOpen: false,
            priceIndex: -1,
          },
        }}
        validationSchema={this.pricesSchema}
        validateOnChange={true}
        onSubmit={(values: { prices: Price[], selectedPrice: number, isEdit: boolean, isAdd: boolean, dialogProps: WaterElectricalSettingsDialog, deleteDialogProps: WaterElectricalDeletePriceDialog }, formikHelpers) => {
          this.setValidate(false);
          const callback = (newPrices: Price[]) => {
            formikHelpers.setFieldValue('isAdd', false);
            formikHelpers.setFieldValue('isEdit', false);
            formikHelpers.setFieldValue('prices', newPrices);
          };
          const price: Price = {...values.prices[values.selectedPrice], consumptions: values.prices[values.selectedPrice].consumptions.map((consumption) => ({ rate: Number(Number(consumption.rate).toFixed(2)), amount: Number(consumption.amount) }))}
          if (isElectrical) {
            this.editElectricalPrices(price, callback);
          } else {
            this.editWaterPrices(price, callback);
          }
        }}
        data-test-id="pricesSettingsForm"
      >
        {({ setFieldValue, values, errors, handleSubmit, resetForm }) => (
          <form>
            <Box sx={webStyle.formContainer}>
              <Box sx={webStyle.topRow}>
                <StyledSelect
                  value={values.selectedPrice}
                  inputProps={{ "aria-label": "Without label" }}
                  data-test-id="selectedPrice"
                  onChange={(event) => event.target.value !== 'add' && setFieldValue('selectedPrice', event.target.value)}
                  disabled={values.isEdit}
                  sx={webStyle.selectPriceField}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        width: '200px',
                        "& .MuiMenu-list": {
                          padding: "2px 0",
                        },
                        "@media (max-width: 870px)": {
                          width: '100%',
                        }
                      }
                    },
                  }}
                  renderValue={() => {
                    if (!values.prices.length) {
                      return 'No rates';
                    }

                    return values.prices[values.selectedPrice]?.name;
                  }}
                  displayEmpty
                >
                  {values.prices.map((item, index) => (
                    <MenuItem sx={webStyle.selectItem} value={index}>{item.name}</MenuItem>
                  ))}
                  <MenuItem value={'add'} onClick={() => setFieldValue('dialogProps', { isOpen: true, rateName: '', priceIndex: -1 })} sx={webStyle.addNewRateButton}>
                    <AddRoundedIcon sx={webStyle.addIcon} />
                    <Box>Add rate</Box>
                  </MenuItem>
                </StyledSelect>
                {!values.isAdd && values.selectedPrice !== -1 && (
                  <Box sx={webStyle.topButtonsRow}>
                    <Button
                      data-test-id="delete_button"
                      sx={webStyle.deleteButton}
                      // type="button"
                      onClick={() => {
                        setFieldValue('deleteDialogProps', { isOpen: true, priceIndex: values.selectedPrice });
                      }}
                    >
                      <DeleteIcon sx={webStyle.deleteIcon} />
                      <Box>Delete</Box>
                    </Button>
                    <Button
                      sx={webStyle.renameButton}
                      data-test-id="rename_button"
                      // type="button"
                      onClick={() => {
                        setFieldValue('dialogProps', { isOpen: true, rateName: values.prices[values.selectedPrice]?.name, priceIndex: values.selectedPrice });
                      }}
                    >
                      <EditRoundedIcon sx={webStyle.renameIcon} />
                      <Box>Rename</Box>
                    </Button>
                  </Box>
                )}
              </Box>
              <FieldArray name={`prices.${values.selectedPrice}.consumptions`}>
                {(arrayHelpers: any) => (
                  <>
                    {values.selectedPrice !== -1 && values.prices[values.selectedPrice]?.consumptions.map((consumption, index) => (
                      <Box sx={webStyle.fieldGroupContral(index)} key={index}>
                        <Box sx={webStyle.columnFieldControl}>
                          <Typography sx={webStyle.inputLabel}>
                            {isElectrical ? 'Consumption in Kw' : 'Consumption in Cu Ft.'}
                          </Typography>
                          <input
                            // @ts-ignore
                            style={{...webStyle.inputElemnt, ...(!values.isEdit ? webStyle.inputElemntDisabled : {}), ...(errors?.prices?.[values.selectedPrice]?.consumptions?.[index]?.amount && this.state.validate ? webStyle.inputElementError : {})}}
                            data-test-id={`consumption_${index}`}
                            type="text"
                            value={consumption.amount}
                            onChange={(event) => {
                              console.log(errors);
                              /^\d*\.?\d*$/.test(event.target.value) && setFieldValue(`prices.${values.selectedPrice}.consumptions.${index}.amount`, event.target.value);
                            }}
                            disabled={!values.isEdit}
                          />
                        </Box>
                        <Box sx={webStyle.columnFieldControl}>
                          <Typography sx={webStyle.inputLabel}>
                            {configJSON.rateTitleText}
                          </Typography>
                          <Box sx={{ position: 'relative' }}>
                            <input
                              // @ts-ignore
                              style={{...webStyle.inputElemnt, paddingLeft: '18px', ...(!values.isEdit ? webStyle.inputElemntDisabled : {}), ...(errors?.prices?.[values.selectedPrice]?.consumptions?.[index]?.rate && this.state.validate ? webStyle.inputElementError : {})}}
                              data-test-id={`rate_${index}`}
                              type="text"
                              value={consumption.rate}
                              onChange={(event) => {
                                /^\d*\.?\d*$/.test(event.target.value) && setFieldValue(`prices.${values.selectedPrice}.consumptions.${index}.rate`, event.target.value);
                              }}
                              disabled={!values.isEdit}
                            />
                            <Box sx={webStyle.dollarSign}>$</Box>
                          </Box>
                          {values.isEdit && index > 0 && (
                            <IconButton sx={webStyle.removeItemButton} onClick={() => arrayHelpers.remove(index)} data-test-id="remove_button">
                                <RemoveRoundedIcon sx={webStyle.removeItemIcon} />
                            </IconButton>
                          )}
                        </Box>
                      </Box>
                      ))}
                      {values.isEdit && (
                        <Button
                          sx={webStyle.addNewRowButton}
                          variant="text"
                          // type="button"
                          onClick={() => {
                            arrayHelpers.push({
                              rate: 0,
                              amount: 0
                            });
                          }}
                          data-test-id="add_button"
                        >
                          <AddRoundedIcon sx={webStyle.addIcon} />
                          <Box>Add row</Box>
                        </Button>
                      )}
                  </>
                )}
              </FieldArray>
              <Box sx={webStyle.fieldControlFirstStepGroup}>
                {values.isEdit ? (
                  <>
                    <Button
                      sx={webStyle.cancelButton}
                      data-test-id="cancelBtn"
                      // type="button"
                      onClick={() => {
                        resetForm({ values: { prices: isElectrical ? this.state.electricalPrices : this.state.waterPrices, selectedPrice: values.selectedPrice, isAdd: false, isEdit: false, dialogProps: { isOpen: false, rateName: '' }, deleteDialogProps: { isOpen: false, priceIndex: -1 } } })
                      }}
                    >
                      <Typography sx={webStyle.buttonText}>{configJSON.cancelButtonText}</Typography>
                    </Button>
                    <Button
                      sx={webStyle.submitButton}
                      data-test-id="submitBtn"
                      // type="button"
                      onClick={() => {
                        this.setValidate(true);
                        if (Object.keys(errors).length === 0) {
                          handleSubmit();
                        }
                      }}
                    >
                      <Typography sx={webStyle.buttonText}>{configJSON.submitButtonText}</Typography>
                    </Button>
                  </>
                ) : values.selectedPrice !== -1 ? (
                  <>
                    <Button
                      sx={webStyle.submitButton}
                      data-test-id="editBtn"
                      // type="button"
                      onClick={() => {
                        setFieldValue('isEdit', true);
                      }}
                    >
                      <Typography sx={webStyle.buttonText}>{configJSON.editButtonText}</Typography>
                    </Button>
                  </>
                ) : null}
              </Box>
            </Box>
            <Dialog
              open={values.dialogProps.isOpen}
              onClose={() => setFieldValue('dialogProps', { isOpen: false, rateName: values.dialogProps.rateName, rateNameError: '', priceIndex: values.dialogProps.priceIndex })}
              maxWidth="sm"
              fullWidth
              PaperProps={{
                style: {
                  borderRadius: "16px",
                  padding: "32px",
                  maxHeight: "calc(100% - 120px)",
                },
              }}
              data-test-id="price_rename_dialog"
            >
              <DialogTitle sx={webStyle.dialogTitle} /*style={{ padding: 0, position: "relative" }} */ >
                <Typography variant="h6" sx={webStyle.dialogTitle}>
                  {`${values.dialogProps.priceIndex !== -1 ? "Rename" : "Add new"} rate`}
                </Typography>
              </DialogTitle>
              <DialogContent
                sx={webStyle.dialogContent}
                // sx={{ padding: 0, overflowY: "unset", margin: "32px 0 8px" }}
              >
                <Box sx={webStyle.rateWrapper}>
                  <Typography sx={webStyle.rateLabel}>Rate name</Typography>
                  <StyledInput
                    value={values.dialogProps.rateName}
                    onChange={(event) => setFieldValue('dialogProps', { isOpen: true, priceIndex: values.dialogProps.priceIndex, rateNameError: '', rateName: event.target.value })}
                    sx={values.dialogProps.rateNameError ? webStyle.inputElementError : undefined}
                  />
                  {values.dialogProps.rateNameError && (
                    <Tooltip
                      open={true}
                      title={values.dialogProps.rateNameError}
                      componentsProps={tooltipStyles}
                      arrow
                    >
                      <img src={errorIcon} alt="" style={{...webStyle.errorIcon, top: '33px'}} />
                    </Tooltip>
                  )}
                </Box>
              </DialogContent>
              <Box sx={webStyle.dialogAction}>
                <Button
                  sx={webStyle.cancelButton}
                  data-test-id="cancelDialogButton"
                  // type="button"
                  onClick={() => setFieldValue('dialogProps', { isOpen: false, rateName: values.dialogProps.rateName, rateNameError: '', priceIndex: values.dialogProps.priceIndex })}
                >
                  Cancel
                </Button>
                <Button
                  sx={webStyle.submitButton}
                  data-test-id="submitDialogButton"
                  // type="button"
                  onClick={() => {
                    const newRateName = trimSpaces(values.dialogProps.rateName);
                    if (!newRateName) {
                      setFieldValue('dialogProps', { ...values.dialogProps, rateNameError: 'Rate name is required' });
                      return;
                    }
                    if (values.dialogProps.priceIndex !== undefined && values.dialogProps.priceIndex !== -1) { // renaming existing price
                      this.changeRateName(values.dialogProps.priceIndex, newRateName, !!isElectrical, () => {
                        setFieldValue('dialogProps', { isOpen: false, rateName: newRateName, rateNameError: '', priceIndex: values.dialogProps.priceIndex });
                        setFieldValue('prices', values.prices.map((price, index) => ({ ...price, name: index === values.dialogProps.priceIndex  ? newRateName : price.name })));
                      });
                    } else { // creating a new price
                      const updatedPrices: Price[] = [{ name: newRateName, consumptions: [{ rate: 0, amount: 0 }, { rate: 0, amount: 0 }, { rate: 0, amount: 0 },] }, ...values.prices];
                      setFieldValue('selectedPrice', 0);
                      setFieldValue('prices', updatedPrices);
                      setFieldValue('isAdd', true);
                      setFieldValue('isEdit', true);
                      setFieldValue('dialogProps', { isOpen: false, rateName: newRateName, rateNameError: '', priceIndex: values.dialogProps.priceIndex });
                    }
                  }}
                >
                  Save
                </Button>
              </Box>
            </Dialog>
            <Dialog
              open={values.deleteDialogProps.isOpen}
              onClose={() => setFieldValue('deleteDialogProps', { isOpen: false, priceIndex: -1 })}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              data-test-id="deletePriceDialog"
              PaperProps={{
                style: {
                  width: "633px", 
                  padding: "40px 32px 32px 32px",
                  borderRadius: "16px", 
                  fontFamily:"'Inter', sans-serif",
                },
              }}
            >
            <DialogTitle
              id="alert-dialog-title"
              sx={webStyle.dialogTitle}
              // style={{ fontWeight: "600", padding: '0 0 24px',fontFamily:"'Inter', sans-serif", fontSize:'24px', letterSpacing: '-1px' }}
            >
              Are you sure you want to delete this price?
            </DialogTitle>
            <DialogContent /*style={{ marginBottom: '32px', padding: 0 }}*/ >
              <DialogContentText
                id="alert-dialog-description"
                sx={webStyle.dialogContent}
                // style={{ color: "#64748B", fontFamily:"'Inter', sans-serif",fontSize:"18px"}}
              >
                This action can’t be undone, and all the data associated with this price will be permanently deleted and no longer accessible.
              </DialogContentText>
              <Divider />
            </DialogContent>
            <DialogActions sx={webStyle.dialogAction}>
              <Button
                onClick={() => setFieldValue('deleteDialogProps', { isOpen: false, priceIndex: -1 })}
                variant="outlined"
                sx={webStyle.dialogCancelButton}
              >
                Cancel
              </Button>
              <Button
                onClick={() => this.deletePrice(values.deleteDialogProps.priceIndex, !!isElectrical, () => {
                  const updatedPrices = values.prices.filter((_, index) => index !== values.deleteDialogProps.priceIndex);
                  console.log(updatedPrices, values);
                  setFieldValue('prices', updatedPrices);
                  setFieldValue('selectedPrice', updatedPrices.length > 0 ? 0 : -1);
                  setFieldValue('deleteDialogProps', { isOpen: false, priceIndex: -1 });
                })}
                sx={webStyle.confirmDelete}
                startIcon={<DeleteIcon />} 
                data-test-id="deleteDialogButton"
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
          </form>
        )}
      </Formik>
    );
  };

  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Box sx={webStyle.addReadingContainer}>
        <Box sx={webStyle.addReadingBlock}>
          <Box sx={webStyle.settingFormContainer}>
            <Box sx={webStyle.settingTitle}>
              <Typography style={webStyle.formTitle}>{configJSON.settingTitleText}</Typography>
            </Box>
            <Tabs value={this.state.step} onChange={(_, newValue) => this.setStep(newValue)} sx={webStyle.tabsContainer}>
              <Tab
                data-test-id="firstStep"
                value={1}
                label="Water settings"
                sx={webStyle.tabButton(this.state.step === 1)}
              />
              <Tab
                value={2}
                label="Electricity settings"
                data-test-id="secondStep"
                sx={webStyle.tabButton(this.state.step === 2)}
              />
              <Tab
                value={3}
                label="Service charge"
                data-test-id="thirdStep"
                sx={webStyle.tabButton(this.state.step === 3)}
              />
              <Tab
                value={4}
                label="Notifications"
                data-test-id="fourthStep"
                sx={webStyle.tabButton(this.state.step === 4)}
              />
            </Tabs>
            {this.state.step === 1 && this.pricesSettingsForm()}
            {this.state.step === 2 && this.pricesSettingsForm(true)}
            {this.state.step === 3 && this.serviceChargeForm()}
            {this.state.step === 4 && this.notificationForm()}
          </Box>
        </Box>
        {createPortal(
          <Snackbar
            open={this.state.snackbarProps.isOpen}
            message={
              <Box sx={webStyle.snackbarContent}>
                {this.state.snackbarProps?.type === 'Error' ? (
                  <CancelRoundedIcon
                    sx={{
                      ...webStyle.snackbarIcon,
                      ...webStyle.snackbarWarningIcon,
                    }}
                  />
                ) : (
                  <CheckCircleRoundedIcon
                    sx={{
                      ...webStyle.snackbarIcon,
                      ...webStyle.snackbarSuccessIcon,
                    }}
                  />
                )}
                <Typography sx={webStyle.snackbarText}>
                  {this.state.snackbarProps?.message}
                </Typography>
              </Box>
            }
            autoHideDuration={3500}
            onClose={() => this.setState((prevValues) => ({ snackbarProps: {...prevValues.snackbarProps, isOpen: false} }))}
            sx={webStyle.snackbar}
          />,
          document.body,
        )}
      </Box>

      //Merge Engine End DefaultContainer
      // Customizable Area Start
    );
  }
}
// Customizable Area Start
const StyledInput = styled(InputBase)(() => ({
  padding: "10px 12px",
  borderRadius: "8px",
  border: "1px solid #CBD5E1",
  backgroundColor: "#FFFFFF",
  fontFamily: "'Inter', sans-serif",
  width: "100%",

  "& .MuiInputBase-input": {
    fontSize: "16px",
    color: "#0F172A",
  },
}));

const StyledSelect = styled(Select)(() => ({
  fontFamily: "'Inter', sans-serif",
  backgroundColor: "#FFFFFF",
  width: '200px',
  height: '44px',
  "& fieldset": {
    border: "1px solid #CBD5E1",
  },
  "& fieldset:hover": {
    border: "1px solid #CBD5E1 !important",
  },
}))

const tooltipStyles = {
  popper: {
    sx: {
      zIndex: 9999,
    },
  },
  tooltip: {
    sx: {
      color: "#FFFFFF",
      fontFamily: "'Inter', sans-serif",
      fontSize: "13px",
      lineHeight: "18px",
      fontWeight: "400",
      padding: "8px 12px",
      backgroundColor: "#1E293B",
      borderRadius: "8px",
      width: "fit-content",
      maxWidth: "480px",
      "& .MuiTooltip-arrow": {
        color: "#1E293B",
      },
    },
  },
};

const webStyle = {
  addReadingContainer: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#F8FAFC",
    height: '100%',
    fontFamily: "'Inter', sans-serif",
  },
  addReadingBlock: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  settingFormContainer: {
    margin: "40px auto",
    maxWidth: "700px",
    width: "80%",
  },
  settingTitle: {
    margin: "0 0 20px",
  },
  formContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  formTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "29px",
    marginBottom: "15px",
  },
  fieldGroupContral: (index: number = 0) => ({
    position: "relative",
    display: "flex",
    flex: 1,
    flexDirection: "row",
    gap: '16px',
    "@media (max-width: 676px)": {
      flexDirection: "column",
      padding: "10px 0px",
      borderTop: index !== 0 ? "1px solid #CBD5E1" : "none",
      BorderBottom: index !== 0 ? "none" : "1px solid #CBD5E1",
    }
  }),
  fieldControlFirstStepGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  inputLabel: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#334155",
    marginBottom: "5px",
    alignItems: "center",
    display: "flex",
    "@media (max-width: 500px)": {
      fontSize: "12px",
    },
  },
  cancelButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    margin: "24px 0 0",
    height: "44px",
    borderRadius: "8px",
    border: "1px solid #1A7BA4",
    background: "transparent",
    color: "#1A7BA4",
    padding: "0px 16px",
    flexDirection: "row" as const,
    display: "flex",
    alignItems: "center",
    textTransform: "capitalize",
    "@media (max-width: 500px)": {
      fontSize: "14px",
      width: "100%",
      margin: "24px 6px 0 6px",
    },
  },
  submitButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    margin: "24px 0 0 16px",
    height: "44px",
    borderRadius: "8px",
    border: "none",
    background: "#1A7BA4",
    color: "#FFFFFF",
    padding: "0px 16px",
    flexDirection: "row" as const,
    display: "flex",
    alignItems: "center",
    textTransform: "capitalize",
    "@media (max-width: 500px)": {
      fontSize: "14px",
      width: "100%",
      margin: "24px 6px 0 6px",
    },
    "&:hover": {
      backgroundColor: "#1A7BA4",
    },
  },
  buttonText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
  },
  tabsContainer: {
    borderBottom: '1px solid #E2E8F0',
    margin: '0 0 40px',
    fontSize:"16px",
    fontFamily: "'Inter', sans-serif",
    '& .Mui-selected': {
      color: '#1A7BA4 !important',
    },
    '& .MuiTabs-indicator': {
      display: "none",
      // backgroundColor: '#1A7BA4 !important',
    },

    // MuiTabs-indicator css-1aquho2-MuiTabs-indicator
    "@media (max-width: 870px)": {
      "& .MuiTabs-flexContainer": {
        flexWrap: "wrap",
      }
    }
  },
  activeTabItemContainer: {
    cursor: "pointer",
    borderBottom: "1px solid #1A7BA4",
    display: "flex",
    flexDirection: "row",
    color: '#1A7BA4'
  },
  tabItemContainer: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
  },
  tabButton: (isActive: boolean) => ({
    fontWeight: isActive ? 700 : 400,
    color: isActive ? '#1A7BA4' : '#64748B',
    textTransform: 'none',
    padding: '8px 12px',
    fontFamily: "'Inter', sans-serif",
    fontSize: '16px',
    "@media (max-width: 870px)": {
      width: "50%",
      fontSize: '14px',
    },
    borderBottom: isActive ? "2px solid #1A7BA4" : "2px solid transparent",
  }),
  tabTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#64748B",
    margin: "7px",
  },
  activeTabTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#64748B",
    margin: "7px",
  },
  inputElemnt: {
    height: "56px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#0F172A",
    width: '100%',
  },
  inputElemntDisabled: {
    backgroundColor: '#F1F5F9',
  },
  columnFieldControl: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    margin: "10px 0",
    position: "relative",
  },
  fieldCheckboxControl: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    margin: "10px",
    position: "relative",
  },
  switchTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    color: "#475569",
    alignItems: "center",
    display: "flex",
    marginRight: "30px",
  },
  loaderWrapper: {
   margin: '200px auto 0',
   width: 'fit-content',
  },
  loader: {
   color: '#1A7BA4',
   height: '60px',
   width: '60px',
  },
  selectItem: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    color: '#0F172A',
    padding: '6px 12px',
    margin: '0px',
    borderRadius: '0px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'block',
    '&.Mui-selected': {
      backgroundColor: '#E8FBFF',
      color: '#1A7BA4',
      fontWeight: '600',
    }
  },
  selectInput: {
    fontFamily: "'Inter', sans-serif",
    backgroundColor: "#FFFFFF",
    width: '200px',
    height: '44px',
    "& fieldset": {
      border: "1px solid #CBD5E1",
    },
    "& fieldset:hover": {
      border: "1px solid #CBD5E1 !important",
    },
  },
  selectPriceField: {
    width: '200px',
    "& .MuiMenu-list": {
      padding: "2px 0",
    },
    "@media (max-width: 870px)": {
      width: '100%',
    }
  },
  topRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
    textTransform: "capitalize",
    "@media (max-width: 870px)": {
      flexDirection: "column",
      // fontSize: "14px",
      // width: "100%"
    },
  },
  renameIcon: {
    color: '#1A7BA4',
    width: '20px',
    height: '20px',
  },
  deleteIcon: {
    color: '#DC2626',
    width: '20px',
    height: '20px',
  },
  renameButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "24px",
    height: "44px",
    borderRadius: "8px",
    border: "1px solid #1A7BA4",
    background: "transparent",
    color: "#1A7BA4",
    padding: "0px 16px",
    flexDirection: "row" as const,
    display: "flex",
    alignItems: "center",
    gap: '6px',
    textTransform: "capitalize",
    "@media (max-width: 870px)": {
      width: "100%",
    },
    "@media (max-width: 500px)": {
      fontSize: "14px",
    },
  },
  addNewRowButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    height: "24px",
    border: "none",
    background: "inherit",
    color: "#1A7BA4",
    padding: "0px 20px",
    flexDirection: "row" as const,
    display: "flex",
    alignItems: "center",
    flex: 1,
    justifyContent: "center",
    width: 'fit-content',
    margin: '20px 0 0',
    textTransform: 'none',
    "@media (max-width: 870px)": {
      fontSize: "14px",
    },
    "@media (max-width: 500px)": {
      width: "100%",
    },
  },
  addIcon: {
    width: '24px',
    height: '24px',
    color: "#1A7BA4",
  },
  removeItemButton: {
    position: 'absolute',
    right: -41,
    top: 38,
    width: '32px',
    height: '32px',
    backgroundColor: '#FEF2F2',
    borderRadius: '100px',
    "@media (max-width: 676px)": {
      right: "6px",
      top: "-125px",
    },
  },
  removeItemIcon: {
    color: '#DC2626',
    width: '24px',
    height: '24px',
  },
  addNewRateButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    height: "44px",
    borderTop: "1px solid rgba(26, 123, 164, 0.6)",
    background: "inherit",
    color: "#1A7BA4",
    padding: "0px",
    flexDirection: "row" as const,
    display: "flex",
    alignItems: "center",
    flex: 1,
    justifyContent: "center",
    width: '100%',
    gap: '8px',
  },
  rateLabel: {
    fontSize: "14px",
    fontWeight: 700,
    fontFamily: "'Inter', sans-serif",
    color: "#334155",
    "@media (max-width: 500px)": {
      fontSize: "12px",
    },
  },
  rateWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    position: 'relative',
  },
  dialogTitleText: {
    fontWeight: "700",
    fontSize: "24px",
    fontFamily: "'Inter', sans-serif",
  },
  buttonsWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  dollarSign: {
    position: 'absolute',
    left: '8px',
    top: '19px',
  },
  dialogCancelButton:{
    color: "#1A7BA4",
    border: "1px solid #1A7BA4",
    padding: "6px 12px",
    borderRadius: "5px",
    textTransform: "none",
    fontWeight:"700",
    fontFamily:"'Inter', sans-serif",
    fontSize: '16px',
    height: '44px',
    "@media (max-width: 500px)": {
      fontSize: "14px",
      width: "100%"
    },
  },
  confirmDelete:{
    padding: "6px 12px",
    borderRadius: "5px",
    textTransform: "none",
    backgroundColor:"white",
    color:"#DC2626",
    border:"1px solid #DC2626",
    fontFamily:"'Inter', sans-serif",
    fontWeight:"600",
    height: '44px',
    marginLeft: '16px !important',
    "@media (max-width: 500px)": {
      fontSize: "14px",
      width: "100%",
      marginLeft: '0px !important',
      marginTop: '10px !important',
    },
  },
  deleteButton: {
    backgroundColor: "#FFFFFF",
    color: "#DC2626",
    border: "1px solid #DC2626",
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "24px",
    height: "44px",
    borderRadius: "8px",
    background: "transparent",
    padding: "0px 16px",
    flexDirection: "row" as const,
    display: "flex",
    alignItems: "center",
    gap: '6px',
    marginRight: '16px',
    textTransform: "capitalize",
    "@media (max-width: 870px)": {
      width: "100%",
    },
    "@media (max-width: 500px)": {
      fontSize: "14px",
    },
  },
  deleteText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
  },
  topButtonsRow: {
    display: 'flex',
    width: '200px',
    "@media (max-width: 870px)": {
      marginTop: "10px",                    
      width: '100%',
  }
  },
  snackbar: {
    left: "50% !important",
    transform: "translateX(-50%)",
    fontFamily: "'Inter', sans-serif",
    "& .MuiPaper-root": {
      backgroundColor: "#FFFFFF",
      fontFamily: "'Inter', sans-serif !important",
      padding: "8px 16px 8px 8px",
      boxShadow: "0px 6px 15px -3px #00000026",
      borderRadius: "8px",
      minWidth: "auto",
      "& .MuiSnackbarContent-message": {
        padding: 0,
      },
    },
  },
  snackbarContent: {
    display: "flex",
    gap: "8px",
    alignItems: "center",
  },
  snackbarIcon: {
    width: "26px",
    height: "26px",
  },
  snackbarWarningIcon: {
    "> path": {
      fill: "#F87171",
    },
  },
  snackbarSuccessIcon: {
    "> path": {
      fill: "#34D399",
    },
  },
  snackbarText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#0F172A",
  },
  errorIcon: {
    width: "24px",
    height: "24px",
    position: "absolute" as "absolute",
    top: 15,
    right: 16,
  },
  inputElementError: {
    border: "1px solid #F87171",
  },
  dialogTitle: { 
    fontWeight: "600", 
    paddingBottom: "8px",
    fontFamily:"'Inter', sans-serif", 
    fontSize:'20px',
    // whiteSpace:"nowrap",
    "@media (max-width: 767px)": {
      fontSize:'17px',
    }
  },
  dialogContent: { 
    color: "#64748B", 
    paddingBottom: "16px",
    fontFamily:"'Inter', sans-serif",
    fontSize:"18px",
    "@media (max-width: 767px)": {
      fontSize:"16px",
    }
  },
  dialogAction: {
    padding: "16px",
    display: "flex",
    justifyContent: "flex-end",
    "@media (max-width: 500px)": {
      flexDirection: "column",
    },
  },
};
// Customizable Area End
