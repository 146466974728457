import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Box, Modal, Button } from '@mui/material';
import PowerSettingsNewRoundedIcon from '@mui/icons-material/PowerSettingsNewRounded';

import AccountController, { Props } from './AccountController';
export const configJSON = require("../../blocks/landingpage/src/config");

const companySettingSVG = require('./company_settings.svg');
const accountSettingSVG = require('./account_settings.svg');
const notificationSettingSVG = require('./notification_settings.svg');

export const reeadingIcon = require("./reeading.svg");

export default class UserProfileBlock extends AccountController {

  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <Modal
        open={!!this.props.showUserProfile}
        onClose={() => {
          this.props.setUserProfileVisible(false);
        }}
        sx={styles.modalBlock}
      >
        <Box sx={styles.userProfileConatiner}>
          <Box sx={styles.profileBlock}>
            <Box sx={styles.accountBlock}>
             <Typography sx={styles.accounInitials}>{this.state.accountSettings.initials || ""}</Typography>
            </Box>  
            <Box sx={styles.accountInfo}>
              <Box sx={styles.accountName}>{this.state.accountSettings.full_name || ""}</Box>
              <Box sx={styles.accountEmail}>{this.state.accountSettings.email || ""}</Box>
            </Box>
          </Box>
          {this.state.userRole === "user" ? (
            <Box sx={styles.userProfileBody}>
              <Link to="/SettingsPage?type=company_settings" style={styles.listItem} onClick={() => this.props.setUserProfileVisible(false)}>
                <img src={companySettingSVG} alt="Logo" style={styles.itemIcon} />
                <Typography style={styles.listItemText}>Company settings</Typography>
              </Link>
              <Link to="/SettingsPage?type=account_settings" style={styles.listItem} onClick={() => this.props.setUserProfileVisible(false)}>
                <img src={accountSettingSVG} alt="Logo" style={styles.itemIcon} />
                <Typography style={styles.listItemText}>Account settings</Typography>
              </Link>
              <Link to="/SettingsPage?type=notification_settings" style={styles.listItem} onClick={() => this.props.setUserProfileVisible(false)}>
                <img src={notificationSettingSVG} alt="Logo" style={styles.itemIcon} />
                <Typography style={styles.listItemText}>Notification settings</Typography>
              </Link>
            </Box>
          ) : (
            <Box sx={styles.userProfileBody}>
              <Link to="/admin/settings?type=water_settings" style={styles.listItem} onClick={() => this.props.setUserProfileVisible(false)}>
                <img src={companySettingSVG} alt="Logo" style={styles.itemIcon} />
                <Typography style={styles.listItemText}>Water settings</Typography>
              </Link>
              <Link to="/admin/settings?type=electricity_settings" style={styles.listItem} onClick={() => this.props.setUserProfileVisible(false)}>
                <img src={companySettingSVG} alt="Logo" style={styles.itemIcon} />
                <Typography style={styles.listItemText}>Electricity settings</Typography>
              </Link>
              <Link to="/admin/settings?type=service_charge" style={styles.listItem} onClick={() => this.props.setUserProfileVisible(false)}>
                <img src={companySettingSVG} alt="Logo" style={styles.itemIcon} />
                <Typography style={styles.listItemText}>Service charge</Typography>
              </Link>
              <Link to="/admin/settings?type=notifications" style={styles.listItem} onClick={() => this.props.setUserProfileVisible(false)}>
                <img src={notificationSettingSVG} alt="Logo" style={styles.itemIcon} />
                <Typography style={styles.listItemText}>Notifications</Typography>
              </Link>
            </Box>
          )}
          <Box sx={styles.logOutBlock}>
            <Link to="/LogInPage" style={styles.logoutButton} data-test-id="logoutButton" onClick={() => this.logOut()} >
              <PowerSettingsNewRoundedIcon sx={styles.logoutIcon} />
              <Typography sx={styles.logoutText}>Log out</Typography>
            </Link>
          </Box>
        </Box>
      </Modal>
    );
  }
}
const styles = {
  modalBlock: {
    "& .MuiBackdrop-root": {
      opacity: "0 !important",
    },
  }, 
  userProfileConatiner: {
    boxShadow: "0px 2px 8px 0px #00000014",
    zIndex: '10',
    borderRadius: "12px",
    border: "1px solid #CBD5E1",
    backgroundColor: "#FFFFFF",
    width: "340px",
    right: "20px",
    top: "60px",
    position: "absolute",
    padding: 0,
  },
  userProfileBody: {
    padding: "0px",
    borderTop: "1px solid #CBD5E1",
    borderBottom: "1px solid #CBD5E1",
    display: "flex",
    flexDirection: "column",
  },
  logOutBlock: {
    padding: "0px",
    display: "flex",
    flexDirection: "column",
  },
  profileBlock: {
    padding: "0px",
    display: "flex",
    flexDirection: "row",
  },
  userProfileMessageTitle: {
    marginBottom: "8px",
    display: "flex",
    flexDirection: "column",
  },
  userProfileMessageTitleBlock: {
    marginBottom: "2px",
    display: "flex",
    flexDirection: "row",
    height: "24px",
  },
  userProfileMessageTitleText: {
    textAlign: "left",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#0F172A",
  },
  userProfileReadingIcon: {
    height: '8px', 
    margin: "8px",
  },
  userProfileMessageDescription: {
    textAlign: "left",
    display: "flex",
    flexDirection: "row",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#64748B",
  },
  userProfileMessageDate: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  userProfileMessageDateTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#64748B",
  },
  logoutButton: {
    backgroundColor:  "#FFFFFF",
    border: "none",
    borderRadius: "18px",
    width: "fit-content",
    height: "40px",
    padding: "8px 12px 8px 12px",
    alignItem: "center",
    margin: "0px 10px",
    display: "flex",
    fleexDirectiion: "row",
    textDecoration: "none",

    // width: "100%",
    // height: "40px",
    // padding: "8px 12px 8px 12px",
    // margin: "0px 10px 24px",
    // display: "flex",
    // fleexDirectiion: "row",
    // textDecoration: "none",
    // backgroundColor: '#FFFFFF',
    // justifyContent: 'flex-start',
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
  },
  itemIcon: {
    margin: '8px',
    height: '24px',
  },
  logoutIcon: {
    color: '#F87171',
    margin: '8px',
    height: '24px',
  },
  logoutText: {
    alignItems: "center",
    flex: 1,
    display: "flex",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    // color: "#64748B",

    // fontFamily: "'Inter', sans-serif",
    color: '#F87171',
    // fontSize: '16px',
    textTransform: 'none',
  },
  listItem: {
    backgroundColor:  "#FFFFFF",
    border: "none",
    borderRadius: "18px",
    width: "fit-content",
    height: "40px",
    padding: "8px 12px 8px 12px",
    alignItem: "center",
    margin: "0px 10px",
    display: "flex",
    fleexDirectiion: "row",
    textDecoration: "none",
  },
  svg: {
    margin: '8px 8px 8px 0',
    '> path': {
      fill: '#64748B'
    }
  },
  listItemText: {
    alignItems: "center",
    flex: 1,
    display: "flex",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#64748B",
  },
  accountBlock: {
    width: "45px",
    height: "45px",
    // height: "64px",
    // width: "64px",
    margin: "16px",

    backgroundColor: "#F0E5FF",
    borderRadius: "25px",
    border: "none",
    cursor: 'pointer',
  },
  accounInitials: {
    fontFamily: "'Inter', sans-serif",
    // fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "45px",
    textAlign: "center",
    color: "#A666FF",
    textTransform: 'uppercase',
    minWidth: "48px",
  },
  accountInfo: {
    display: "flex",
    flexDirection: "column",
    margin: "auto 0px",
  },
  accountName: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    textAlign: "left",
    color: "#0F172A",
    width: "100%",
    wordWrap: "anywhere",
  },
  accountEmail: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 300,
    lineHeight: "24px",
    textAlign: "left",
    color: "#64748B",
    width: "100%",
    wordWrap: "anywhere",
  },
};
