import React from "react";
// Customizable Area Start
import { 
  Typography, 
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Divider,
  CircularProgress,
} from "@mui/material";
import {
  searchIcon,
  addIcon,
  editIcon,
  deleteIcon,
  leftArrowIcon,
  hotWaterIcon,
  coldWaterIcon,
  noMetersIcon,
} from "./assets";
// Customizable Area End

import MeterPageController, { Props, configJSON } from "./MeterPageController";
// Customizable Area Start
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";
import moment from "moment";


const renderTypeWithIcon = (meterType: 'Water' | 'Electric', waterMeterType?: 'Cold' | 'Hot') => {
  if (meterType === 'Electric') {
    return <Typography sx={{ fontSize: '14px', fontFamily: "'Inter', sans-serif" }}>Electrical</Typography>;
  }

  if (waterMeterType === "Cold") {
    return (
      <>
        Water &nbsp;
        <div style={{ display: "flex", alignItems: "center" }}>
          (
          <img
            src={coldWaterIcon}
            alt="Cold Icon"
            style={{ width: "16px", height: "16px" }}
          />{" "}
          &nbsp;Cold)
        </div>
      </>
    );
  } else if (waterMeterType === "Hot") {
    return (
      <>
        Water &nbsp;
        <div style={{ display: "flex", alignItems: "center" }}>
          (
          <img
            src={hotWaterIcon}
            alt="Hot Icon"
            style={{ width: "16px", height: "16px" }}
          />{" "}
          &nbsp;Hot)
        </div>
      </>
    );
  }
};
// Customizable Area End
export default class MeterPage extends MeterPageController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Box sx={webStyle.metersContainer}>
        <Box sx={webStyle.tenantActionContainer}>
          <Button
            variant="text"
            data-test-id="goBack"
            sx={webStyle.backButton}
            onClick={() => {
              this.goBack();
            }}
          >
            <img src={leftArrowIcon} alt="" style={webStyle.btnIcon} />
            <Typography sx={webStyle.typographyTitleText}>
              {configJSON.backToTenantText}
            </Typography>
          </Button>
          <Typography sx={webStyle.tenantTitle}>
            {this.state.currentProperty.property_name}
          </Typography>
          <Typography sx={webStyle.tenantSubTitle}>
            {this.state.currentProperty.service_address}
          </Typography>
        </Box>
        {this.state.metersLoading ? (
          <Box sx={webStyle.loaderWrapper} data-test-id="loaderWrapper">
            <CircularProgress sx={webStyle.loader} />
          </Box>
        ) : this.state.meterList.length === 0 ? (
          <Box sx={webStyle.noMeterBlock} data-test-id={"noMeterBlock"}>
            <Box sx={webStyle.noMeterBlockTitle}>
              <img src={noMetersIcon} style={webStyle.mainImage} />
              <Typography sx={webStyle.noMeterTitle}>
                {configJSON.noMeterTitleText}
              </Typography>
              <Typography sx={webStyle.noMeterSubTitle}>
                {configJSON.noMeterSubTitleText}
              </Typography>
              <Box sx={webStyle.buttonBlock}>
                <Button
                  sx={webStyle.addNewButton}
                  data-test-id="addMeter"
                  onClick={this.addMeter}
                >
                  <Typography sx={webStyle.buttonText}>
                    {configJSON.addMeterText}
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        ) : (
          <>
            <Box sx={webStyle.propertiesTableHeader}>
              <Box sx={webStyle.searchContainer}>
                <Box sx={webStyle.fieldControl}>
                  <input
                    style={webStyle.inputElemnt}
                    data-test-id="search"
                    type={"text"}
                    placeholder={configJSON.searchPlaceholderText}
                    onChange={(event) => {
                      this.searchMeters(event.target.value);
                    }}
                  />
                  <img src={searchIcon} alt="" style={webStyle.searchIcon} />
                </Box>
              </Box>
              <Box sx={webStyle.actionContainer}>
                <Button
                  data-test-id="addMeter"
                  sx={webStyle.addPropertiesButton}
                  onClick={() => {
                    this.addMeter();
                  }}
                >
                  <img src={addIcon} alt="" style={webStyle.btnIcon} />
                  <Typography sx={webStyle.typographyTitleText}>
                    {configJSON.addMeterText}
                  </Typography>
                </Button>
              </Box>
            </Box>
            <Typography sx={webStyle.metersCount} data-test-id="resultsLength">
              {this.state.filteredItemsCount + " results"}
            </Typography>
            {this.state.filteredItemsCount ? (
              <Box sx={webStyle.tableWrapper(this.state.totalPages)}>
                <Box sx={webStyle.propertisTable}>
                  <Box sx={webStyle.propertisTableHead}>
                    <Box sx={webStyle.propertisTableDataHead}>
                      <Typography sx={webStyle.headerTitle}>
                        {configJSON.meterNumberText}
                      </Typography>
                      <Typography sx={webStyle.headerTitle}>
                        {configJSON.typeText}
                      </Typography>
                      <Typography sx={webStyle.headerTitle}>
                        {configJSON.lastReadingText}
                      </Typography>
                      <Typography sx={webStyle.headerTitle}>
                        {configJSON.lastReadingDateText}
                      </Typography>
                    </Box>
                    <Typography sx={webStyle.headerTitleAction}>
                      {configJSON.actionsText}
                    </Typography>
                  </Box>
                  {this.state.currentMeters.map((meter, index) => (
                    <Box sx={webStyle.propertisTableBody} key={meter.id}>
                      <Box sx={webStyle.propertisTableDataBody}>
                        <Box sx={webStyle.propertyItem}>{meter.meterNumber}</Box>
                        <Box sx={webStyle.propertyItem}>
                          {renderTypeWithIcon(meter.type, meter.waterSubType)}
                        </Box>
                        <Box sx={webStyle.propertyItem}>{meter.lastRiding || '-'}</Box>
                        <Box sx={webStyle.propertyItem}>{meter.dateLastRiding ? moment(meter.dateLastRiding).format("l") : '-'}</Box>
                      </Box>
                      <Box sx={webStyle.propertyItemAction}>
                        <Button
                          data-test-id={`editMeter.${index}`}
                          sx={webStyle.editButton}
                          onClick={() => {
                            this.editMeter(meter.id);
                          }}
                        >
                          <img src={editIcon} alt="" style={webStyle.btnIcon} />
                          <Typography sx={webStyle.typographyText}>
                            {configJSON.editText}
                          </Typography>
                        </Button>
                        <Button
                          data-test-id={`deleteMeter.${index}`}
                          sx={webStyle.deleteButton}
                          onClick={() => {
                            this.setDeleteButtonDialoge(meter.id);
                          }}
                        >
                          <img src={deleteIcon} alt="" style={webStyle.btnIcon} />
                          <Typography sx={webStyle.typographyText}>
                            {configJSON.deleteText}
                          </Typography>
                        </Button>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            ) : ""}
            {this.state.totalPages > 1 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "20px",
                  marginBottom: "5%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexGrow: 1,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* Previous Button */}
                  <button
                    onClick={() => this.handlePrevPage()}
                    data-test-id="previous-button"
                    style={{
                      backgroundColor: "inherit",
                      border: "none",
                      cursor: "pointer",
                      padding: 0,
                      marginRight: "4px",
                    }}
                    disabled={this.state.currentPage === 1}
                  >
                    <KeyboardArrowLeftRoundedIcon
                      sx={
                        this.state.currentPage === 1
                          ? webStyle.chevronIconDisabled
                          : webStyle.chevronIconActive
                      }
                    />
                  </button>

                  {this.state.totalPages <= 3 ? (
                    [...Array(this.state.totalPages).keys()].map((page) => (
                      <button
                        key={page + 1}
                        data-test-id={"page-button"}
                        style={{
                          marginRight: "4px",
                          width:
                            this.state.currentPage === page + 1 ? "24px" : "16px",
                          height: "24px",
                          backgroundColor:
                            this.state.currentPage === page + 1
                              ? "#DBF5FF"
                              : "inherit",
                          color:
                            this.state.currentPage === page + 1
                              ? "#1A7BA4"
                              : "#64748B",
                          border: "none",
                          textDecoration:
                            this.state.currentPage !== page + 1
                              ? "underline"
                              : "none",
                          borderRadius: "8px",
                          fontWeight:
                            this.state.currentPage !== page + 1 ? 400 : 700,
                          cursor: "pointer",
                          fontSize: "12px",
                          fontFamily: "'Inter', sans-serif",
                        }}
                        onClick={() => this.handlePageChange(page + 1)}
                      >
                        {page + 1}
                      </button>
                    ))
                  ) : (
                    <>
                      {this.state.currentPage !== 1 && (
                        <button
                          data-test-id="ellipsis"
                          style={{
                            marginRight: "4px",
                            width: "16px",
                            height: "24px",
                            backgroundColor: "inherit",
                            color: "#64748B",
                            border: "none",
                            textDecoration: "underline",
                            borderRadius: "8px",
                            cursor: "pointer",
                            fontSize: "12px",
                            fontFamily: "'Inter', sans-serif",
                          }}
                          onClick={() => this.handlePageChange(1)}
                        >
                          1
                        </button>
                      )}

                      {this.state.currentPage > 3 && (
                        <span style={{ marginRight: "4px", color: "#64748B" }}>
                          ...
                        </span>
                      )}

                      {this.state.currentPage > 2 && (
                        <button
                          data-test-id="middle-page"
                          style={{
                            marginRight: "4px",
                            width: "24px",
                            height: "24px",
                            backgroundColor: "inherit",
                            color: "#64748B",
                            border: "none",
                            textDecoration: "underline",
                            borderRadius: "8px",
                            cursor: "pointer",
                            fontSize: "12px",
                            fontFamily: "'Inter', sans-serif",
                          }}
                          onClick={() =>
                            this.handlePageChange(this.state.currentPage - 1)
                          }
                        >
                          {this.state.currentPage - 1}
                        </button>
                      )}
                      <button
                        data-test-id="middle-page2"
                        style={{
                          marginRight: "4px",
                          width: "24px",
                          height: "24px",
                          backgroundColor: "#DBF5FF",
                          color: "#1A7BA4",
                          border: "none",
                          borderRadius: "8px",
                          cursor: "pointer",
                          fontWeight: 700,
                          fontSize: "12px",
                          fontFamily: "'Inter', sans-serif",
                        }}
                        onClick={() =>
                          this.handlePageChange(this.state.currentPage)
                        }
                      >
                        {this.state.currentPage}
                      </button>

                      {this.state.currentPage < this.state.totalPages - 1 && (
                        <button
                          data-test-id="middle-page3"
                          style={{
                            marginRight: "4px",
                            width: "16px",
                            height: "24px",
                            color: "#64748B",
                            backgroundColor: "inherit",
                            border: "none",
                            textDecoration: "underline",
                            borderRadius: "8px",
                            cursor: "pointer",
                            fontSize: "12px",
                            fontFamily: "'Inter', sans-serif",
                          }}
                          onClick={() =>
                            this.handlePageChange(this.state.currentPage + 1)
                          }
                        >
                          {this.state.currentPage + 1}
                        </button>
                      )}

                      {this.state.currentPage < this.state.totalPages - 2 && (
                        <span style={{ marginRight: "4px", color: "#64748B" }}>
                          ...
                        </span>
                      )}

                      {this.state.currentPage !== this.state.totalPages && (
                        <button
                          data-test-id="last-page"
                          style={{
                            marginRight: "4px",
                            width:
                              this.state.currentPage === this.state.totalPages
                                ? "24px"
                                : "16px",
                            height: "24px",
                            backgroundColor:
                              this.state.currentPage === this.state.totalPages
                                ? "#DBF5FF"
                                : "inherit",
                            color:
                              this.state.currentPage === this.state.totalPages
                                ? "#1A7BA4"
                                : "#64748B",
                            border: "none",
                            textDecoration:
                              this.state.currentPage !== this.state.totalPages
                                ? "underline"
                                : "none",
                            borderRadius: "8px",
                            cursor: "pointer",
                            fontSize: "12px",
                            fontFamily: "'Inter', sans-serif",
                            fontWeight:
                              this.state.currentPage !== this.state.totalPages
                                ? 400
                                : 700,
                          }}
                          onClick={() =>
                            this.handlePageChange(this.state.totalPages)
                          }
                        >
                          {this.state.totalPages}
                        </button>
                      )}
                    </>
                  )}

                  <button
                    data-test-id="next-page"
                    onClick={() => this.handleNextPage()}
                    style={{
                      backgroundColor: "inherit",
                      border: "none",
                      cursor: "pointer",
                      padding: 0,
                    }}
                    disabled={this.state.currentPage === this.state.totalPages}
                  >
                    <KeyboardArrowRightRoundedIcon
                      sx={
                        this.state.currentPage === this.state.totalPages
                          ? webStyle.chevronIconDisabled
                          : webStyle.chevronIconActive
                      }
                    />
                  </button>
                </Box>

                <Box sx={{ marginRight: "10px", width: "120px" }}>
                  <Typography
                    fontFamily={'"Nunito Sans", sans-serif'}
                    fontSize={"12px"}
                    color={"grey"}
                  >
                    {this.state.firstItemIndex + 1}-{this.state.lastItemIdex} of{" "}
                    {this.state.meterList.length} results
                  </Typography>
                </Box>
              </Box>
            )}
            <Dialog
              open={!!this.state.deleteButtonDialoge}
              onClose={()=> this.setDeleteButtonDialoge(null)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              PaperProps={{
                style: {
                  width: "633px", 
                  height: "autp", 
                  padding: "40px 32px 32px 32px", 
                  gap: "10px", 
                  borderRadius: "16px", 
                  fontFamily:"'Inter', sans-serif",
                },
              }}
            >
              <DialogTitle
                id="alert-dialog-title"
                sx={webStyle.dialogTitle}
              >
                {configJSON.dialogTitle}
              </DialogTitle>
              <DialogContent style={{ padding: "0px 20px"}}>
                <DialogContentText
                  id="alert-dialog-description"
                  sx={webStyle.dialogContent}
                >
                  {configJSON.dialogContent}
                </DialogContentText>
                <Divider /> 
              </DialogContent>
              <DialogActions sx={webStyle.dialogAction}>
                <Button
                  onClick={()=> this.setDeleteButtonDialoge(null)}
                  variant="outlined" 
                  sx={webStyle.cancelButton}
                >
                  {configJSON.cancelText}
                </Button>
                <Button
                  onClick={() => this.deleteMeter(this.state.deleteButtonDialoge)}
                  sx={webStyle.confirmDelete}
                  startIcon={<DeleteIcon />} 
                >
                  {configJSON.deleteText}
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </Box>
      //Merge Engine End DefaultContainer
      // Customizable Area Start
    );
  }
}
// Customizable Area Start
const webStyle = {
  metersContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "rgba(248, 250, 252, 1)",
    padding: "0px 30px",
    minHeight: "100%",
    "@media (max-width: 767px)": {
      padding: "0px 10px",
    }
  },
  tenantTitleContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    margin: "15px 0px",
    justifyContent: "space-between",
  },
  propertiesTableHeader: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    margin: "10px 0px 20px",
    "@media (max-width: 767px)": {
      flexDirection: "column",
    }
  },
  searchContainer: {
    display: "flex",
    flexDirection: "column",
    width: "340px",
    "@media (max-width: 767px)": {
      width: "100%"
    }
  },
  actionContainer: {
    display: "flex",
    flexDirection: "row",
    "@media (max-width: 767px)": {
      width: "100%"
    }
  },
  tenantActionContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start"
  },
  propertisTable: {
    border: "1px solid #CBD5E1",
    borderRadius: "12px",
    "@media (max-width: 1330px)": {
      minWidth: "770px",
    },
    // "@media (max-width: 767px)": {
    //   minWidth: "420px",
    // }
  },
  propertisTableHead: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
    backgroundColor: 'rgba(248, 250, 252, 1)',
    borderRadius: '12px',
  },
  propertisTableDataHead: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
    width: "calc(100% - 200px)",
    "@media (max-width: 767px)": {
      width: "calc(100% - 100px)",
    }
  },
  propertisTableBody: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
    borderTop: "1px solid #CBD5E1",
    backgroundColor: '#FFFFFF',
    '&:last-of-type': {
      borderRadius: '0 0 12px 12px',
    }
  },
  propertisTableDataBody: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
    width: "calc(100% - 200px)",
    "@media (max-width: 767px)": {
      width: "calc(100% - 100px)",
    }
  },
  fieldControl: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    marginTop: "10px",
    marginBottom: "10px",
    position: "relative",
  },
  inputElemnt: {
    height: "44px",
    padding: "10px 40px 10px 10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
  },
  searchIcon: {
    width: "24px",
    height: "24px",
    position: "absolute" as "absolute",
    top: 10,
    right: 10,
  },
  btnIcon: {
    width: "24px",
    height: "24px",
  },
  metersCount: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "22px",
    color: "#334155",
    margin: "0 0 14px"
  },
  backButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#1A7BA4",
    border: "none",
    backgroundColor: "#F8FAFC",
    display: "flex",
    flexDirection: "row" as const,
    height: "44px",
    alignItems: "center",
    margin: "10px 0px",
    padding: "6px 6px",
    textTransform: "none",
  },
  addPropertiesButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#FFFFFF",
    border: "none",
    borderRadius: "7px",
    backgroundColor: "#1A7BA4",
    display: "flex",
    flexDirection: "row" as const,
    height: "44px",
    alignItems: "center",
    margin: "10px 0px",
    padding: "6px 10px",
    textTransform: "none",
    "@media (max-width: 767px)": {
      width: "100%"
    },
    "&:hover": {
      backgroundColor: "#1A7BA4",
    },
  },
  headerTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "25%",
    overflow: "hidden",
    margin: "12px 4px",
  },
  headerTitleAction: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "200px",
    overflow: "hidden",
    margin: "12px 4px",
    "@media (max-width: 767px)": {
      width: "100px",
    }
  },
  editButton: {
    backgroundColor: "#FFFFFF",
    color: "#1A7BA4",
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    padding: "6px 10px 6px 10px",
    borderRadius: "7px",
    border: "1px solid #1A7BA4",
    display: "flex",
    flexDirection: "row" as const,
    height: "36px",
    alignItems: "center",
    textTransform: "none",
    minWidth: "32px",
  },
  deleteButton: {
    backgroundColor: "#FFFFFF",
    color: "#DC2626",
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    padding: "6px 10px 6px 10px",
    borderRadius: "7px",
    border: "1px solid #DC2626",
    display: "flex",
    flexDirection: "row" as const,
    height: "36px",
    alignItems: "center",
    textTransform: "none",
    minWidth: "32px",
  },
  propertyItem: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "25%",
    wordBreak: 'break-word',
    margin: "13px 4px",
    alignItems: "center",
    display: "flex",
  },
  propertyItemAction: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "200px",
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
    margin: "13px 4px",
    alignItems: "center",
    justifyContent: "space-between",
    "@media (max-width: 767px)": {
      width: "100px",
    }
  },
  tenantTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "29px",
    color: "#0F172A",
    wordBreak: 'break-word',
  },
  tenantSubTitle: {
    marginTop: "10px",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#64748B",
    wordBreak: 'break-word',
  },
  typographyTitleText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "22px",
    marginLeft: "6px",
  },
  typographyText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    "@media (max-width: 767px)": {
      display: "none",
    },
  },
  confirmDelete:{
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    padding: "6px 12px",
    borderRadius: "8px",
    textTransform: "none",
    backgroundColor:"white",
    color:"#DC2626",
    margin: "10px 10px 0px 0px",
    border:"1px solid #DC2626",
    "@media (max-width: 767px)": {
      width: "100%",
      margin: "10px 0px",
    }
 },
  cancelButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    marginTop: "10px",
    borderRadius: "8px",
    border: "1px solid #1A7BA4",
    background: "#FFFFFF",
    color: "#1A7BA4",
    padding: "0px 20px",
    height:"40px",
    textTransform: "none",
    "@media (max-width: 767px)": {
      width: "100%",
      margin: "10px 0px",
    }
  },
  dialogTitle: { 
    fontWeight: "600", 
    paddingBottom: "8px",
    fontFamily:"'Inter', sans-serif", 
    fontSize:'20px',
    // whiteSpace:"nowrap",
    "@media (max-width: 767px)": {
      fontSize:'17px',
    }
  },
  dialogContent: { 
    color: "#64748B", 
    paddingBottom: "16px",
    fontFamily:"'Inter', sans-serif",
    fontSize:"18px",
    "@media (max-width: 767px)": {
      fontSize:"16px",
    }
  },
  dialogAction: {
     padding: "16px",
    "@media (max-width: 767px)": {
      flexDirection: "column",
    },
  },
  chevronIconActive: {
    "> path": {
      fill: "#475569",
    },
  },
  chevronIconDisabled: {
    "> path": {
      fill: "#64748B",
    },
  },
  tableWrapper: (totalPages: number) => ({
    minHeight: totalPages > 1 ? "674px" : "unset",
    marginBottom: "30px",
    "@media (max-width: 1330px)": {
      // "@media (max-width: 767px)": {
      overflow: "auto",
    },
  }),
  noMeterBlock: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    maxWidth: "600px",
    width: "80%",
  },
  noMeterBlockTitle: {
    margin: "80px 10px",
    textAlign: "center",
  },
  noMeterTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    marginBottom: "11px",
    marginTop: "40px",
  },
  noMeterSubTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#475467",
    marginBottom: "20px",
  },
  addNewButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    margin: "15px auto",
    height: "56px",
    borderRadius: "8px",
    border: "none",
    background: "#1A7BA4",
    color: "#FFFFFF",
    padding: "0px 20px",
    flexDirection: "row" as const,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "430px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#1A7BA4",
    },
  },
  buttonText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
  },
  mainImage: {
    width: "251px",
    height: "192px",
  },
  buttonBlock: {
    display: "flex",
    flexDirection: "row",
  },
  loaderWrapper: {
   margin: '200px auto 0',
   width: 'fit-content',
  },
  loader: {
   color: '#1A7BA4',
   height: '60px',
   width: '60px',
  },
};
// Customizable Area End
