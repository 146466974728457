Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";

exports.dashboardText = "Dashboard";
exports.noReportTitleText = "No reports";

exports.meterTypeText = "Meter type";
exports.electricTypeText = "Electric";
exports.waterTitleText = "Water";

exports.lastYearText = "Last 12 months";      
exports.previusYearText = (year) => `Last year (${year})`;
exports.yearText = "Year";
exports.saveAsPdfText = "Save as pdf";
exports.consumptionText = "Consumption";
exports.propertyNameText = "Property name";
exports.tenantNameText = "Tenant name";
exports.meterIdText = "Meter #";
exports.lineChartText = "Line chart";
exports.barChartText = "Bar chart";
exports.tenantText = "Tenant";
exports.dateText = "Date";
// Customizable Area End
