import React, { useState } from "react";
import { IconButton, Box } from "@mui/material";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import UndoRoundedIcon from "@mui/icons-material/UndoRounded";
import BoldRoundedIcon from '@mui/icons-material/FormatBoldRounded';
import ItalicRoundedIcon from '@mui/icons-material/FormatItalicRounded';
import UnderlinedRoundedIcon from '@mui/icons-material/FormatUnderlinedRounded';
import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

type CustomerRichEditorFieldProps = {
  initialValue: string;
  onChange: (value: string) => void;

  noRevert?: boolean;
  allowEmpty?: boolean;
};

export const CustomerRichEditorField = ({
  initialValue,
  onChange,
  noRevert,
  allowEmpty = false,
}: CustomerRichEditorFieldProps) => {
  const [savedInitialValue] = useState(initialValue);
  const [previousValue, setPreviousValue] = useState(initialValue);
  const [hasChanged, setHasChanged] = useState(false);
  const [isEditable, setIsEditalbe] = useState(false);
  const [isError, setError] = useState(false);
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
    ],
    content: initialValue,
    editable: isEditable,
    editorProps: {
        handleKeyDown: (_, event) => {
            if (event.key === 'Escape') {
                handleCancel();

                return true;
            }

            return false;
        },
    },
    onUpdate: () => isError && setError(false),
  })

  const handleEdit = () => {
    editor?.setEditable(true);
    setIsEditalbe(true);
  };

  const handleUndo = () => {
    if (isError) {
      setError(false);
    }

    editor?.commands.setContent(savedInitialValue);
    setPreviousValue(savedInitialValue);
    setHasChanged(false);
    onChange(savedInitialValue);
  };

  const handleAccept = () => {
    if (!allowEmpty && editor?.getText()?.trim() === '') {
      setError(true);

      return;
    }

    if (isError) {
      setError(false);
    }

    setPreviousValue(editor?.getHTML() || '');
    editor?.setEditable(false);
    setIsEditalbe(false);
    setHasChanged(true);
    onChange(editor?.getHTML() || '');
  };

  const handleCancel = () => {
    if (isError) {
      setError(false);
    }

    editor?.commands.setContent(previousValue);
    editor?.setEditable(false);
    setIsEditalbe(false);
  };

  if (!editor) {
    return null;
  }

  return (
    <Box sx={webStyle.wrapper(isEditable)}>
      {isEditable && (
        <Box sx={webStyle.menuWrapper}>
            <Box sx={webStyle.editorOptions}>
                <IconButton onClick={() => editor.chain().focus().toggleBold().run()} sx={editor.isActive('bold') ? webStyle.activeMenuButton : webStyle.inactiveMenuButton}>
                    <BoldRoundedIcon sx={editor.isActive('bold') ? webStyle.activeMenuButtonIcon : webStyle.inactiveMenuButtonIcon} />
                </IconButton>
                <IconButton onClick={() => editor.chain().focus().toggleItalic().run()} sx={editor.isActive('italic') ? webStyle.activeMenuButton : webStyle.inactiveMenuButton}>
                    <ItalicRoundedIcon sx={editor.isActive('italic') ? webStyle.activeMenuButtonIcon : webStyle.inactiveMenuButtonIcon} />
                </IconButton>
                <IconButton onClick={() => editor.chain().focus().toggleUnderline().run()} sx={editor.isActive('underline') ? webStyle.activeMenuButton : webStyle.inactiveMenuButton}>
                    <UnderlinedRoundedIcon sx={editor.isActive('underline') ? webStyle.activeMenuButtonIcon : webStyle.inactiveMenuButtonIcon} />
                </IconButton>
            </Box>
            <Box sx={webStyle.editorControls}>
                <IconButton
                    onClick={handleAccept}
                    data-test-id="acceptButton"
                    sx={webStyle.inactiveMenuButton}
                >
                    <CheckRoundedIcon sx={webStyle.acceptIcon} />
                </IconButton>
                <IconButton
                    onClick={handleCancel}
                    data-test-id="cancelButton"
                    sx={webStyle.inactiveMenuButton}
                >
                    <CloseRoundedIcon sx={webStyle.cancelIcon} />
                </IconButton>
            </Box>
        </Box>
      )}
      <Box sx={webStyle.editor(isEditable, isError)}>
          <EditorContent editor={editor} />
      </Box>
      {!isEditable && (
        <Box sx={webStyle.textIconWrapper}>
            <IconButton onClick={handleEdit} sx={webStyle.textEditButtonIcon} data-test-id="editButton">
                <EditRoundedIcon sx={webStyle.textEditIcon} />
            </IconButton>
            {hasChanged && !noRevert && (
                <IconButton onClick={handleUndo} sx={webStyle.textUndoButtonIcon} data-test-id="undoButton">
                <UndoRoundedIcon sx={webStyle.textUndoIcon} />
                </IconButton>
            )}
        </Box>
      )}
    </Box>
  );
};

const webStyle = {
  wrapper: (isEditable: boolean): any => ({
    position: "relative",
    width: "100%",
    display: isEditable ? undefined : 'flex',
    gap: '8px',
  }),
  acceptIcon: {
    width: "24px",
    height: "24px",
    color: "#059669",
  },
  cancelIcon: {
    width: "24px",
    height: "24px",
    color: "#DC2626",
  },
  textIconWrapper: {
    display: "flex",
    gap: "8px",
    marginTop: '11px',
  },
  textEditButtonIcon: {
    height: "28px",
    width: "28px",
    backgroundColor: "#E8F9FC",
    borderRadius: "100px",
  },
  textUndoButtonIcon: {
    height: "28px",
    width: "28px",
    backgroundColor: "#F1F5F9",
    borderRadius: "100px",
  },
  textEditIcon: {
    width: "20px",
    height: "20px",
    color: "#1A7BA4",
  },
  textUndoIcon: {
    width: "20px",
    height: "20px",
    color: "#475569",
  },
  menuWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '11px 0 12px',
  },
  editorOptions: {
    display: 'flex',
    gap: '12px',
  },
  editorControls: {
    display: 'flex',
    gap: '12px',
  },
  inactiveMenuButton: {
    width: '26px',
    height: '26px',
    borderRadius: '0px',
  },
  activeMenuButton: {
    width: '26px',
    height: '26px',
    borderRadius: '0px',
    backgroundColor: '#1A7BA4',
    '&:hover': {
        backgroundColor: '#1A7BA4',
    }
  },
  inactiveMenuButtonIcon: {
    width: '24px',
    height: '24px',
    color: '#0F172A',
  },
  activeMenuButtonIcon: {
    width: '24px',
    height: '24px',
    color: '#FFFFFF',
  },
  editor: (isEditing: boolean, isError: boolean): any => ({
    '.ProseMirror': {
        outline: 'none',
        border: isError ? '1px solid #DC2626' : isEditing ? '1px solid #E2E8F0' : 'none',
        borderRadius: '8px',
        padding: isEditing ? '0 4px' : '0',
    }
  })
};
