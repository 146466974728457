import React from "react";
// Customizable Area Start
import { Typography, Box, Tooltip, Button } from "@mui/material";
import * as Yup from "yup";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Formik } from "formik";
import { errorIcon, addIcon, removeIcon } from "./assets";
// Customizable Area End

import AddPropertiesPageController, {
  Props,
  configJSON,
} from "./AddPropertiesPageController";
// Customizable Area Start
const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#1A7BA4",
        opacity: 1,
        border: 0,
        ...theme.applyStyles("dark", {
          backgroundColor: "#2ECA45",
        }),
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.grey[100],
      ...theme.applyStyles("dark", {
        color: theme.palette.grey[600],
      }),
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
      ...theme.applyStyles("dark", {
        opacity: 0.3,
      }),
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
    ...theme.applyStyles("dark", {
      backgroundColor: "#39393D",
    }),
  },
}));

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];
// Customizable Area End
export default class AddPropertiesPage extends AddPropertiesPageController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  questionSchema = () => {
    return Yup.object().shape({
      propertyName: Yup.string().trim().required("Property Name is required"),
      serviceAddress: Yup.string().trim().required("Service Address is required"),
      meterLocation: Yup.string().trim().required("Meter Location is required"),
      buildingManager: Yup.string().trim().required("Building Manager is required"),
      phone: Yup.string()
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(8, "Must be more then 8 digits")
        .max(12, "Must be less then 512digits")
        .trim()
        .required("Phone Number is required"),
      meterToRead: Yup.number()
        .min(1, "Must be more then 1")
        .required("Meter of Read is required"),
      email: Yup.string()
        .email("Invalid email format")
        .trim()
        .required("Mail is required"),
      getInvoice: Yup.boolean().required("Required"),
      addServiceCharge: Yup.boolean().required("Required"),
    });
  };
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Box sx={webStyle.addPropertiesContainer}>
        <Box sx={webStyle.addPropertiesBlock}>
          <Box sx={webStyle.addPropertyFormContainer}>
            <Box sx={webStyle.addPropertiesBlockTitle}>
              <Typography sx={webStyle.formTitle}>
                {this.state.propertyId > 0 
                  ? configJSON.editPropertyText
                  : configJSON.addPropertyText}
              </Typography>
              <Typography sx={webStyle.formSubTitle}>
                {this.state.propertyId > 0 
                  ? configJSON.updatePropertiydetailsText
                  : configJSON.enterPropertiydetailsText}
              </Typography>
            </Box>
            <Formik
              initialValues={{
                propertyName: this.state.propertyId > 0 ? this.state.formData.property_name : "",
                serviceAddress: this.state.propertyId > 0 ? this.state.formData.service_address : "",
                meterLocation: this.state.propertyId > 0 ? this.state.formData.meter_location : "",
                buildingManager: this.state.propertyId > 0 ? this.state.formData.building_manager : "",
                phone: this.state.propertyId > 0 ? this.state.formData.phone_number : "",
                email: this.state.propertyId > 0 ? this.state.formData.email : "",
                meterToRead: this.state.propertyId > 0 ? this.state.formData.of_meaters_to_read : 1,
                readingPeriod: this.state.propertyId > 0 ? this.state.formData.reading_period : "monthly",
                startReadingFrom: this.state.propertyId > 0 ? this.state.formData.start_reading_from : months[new Date().getMonth()],
                reportTo: "",
                getInvoice: true,
                addServiceCharge: true,
              }}
              enableReinitialize={true}
              validateOnMount={true}
              validateOnChange={true}
              validationSchema={this.questionSchema}
              onSubmit={() => {
                this.setValidate(true);
                if (this.state.propertyId > 0) {
                  this.updateProperty(this.state.propertyId);
                } else {
                  this.createProperty();
                }
              }}
              data-test-id="addPropertyForm"
            >
              {({ errors, values, setFieldValue, handleSubmit, touched, dirty }) => (
                <form>
                  <Box sx={webStyle.formContainer}>
                    <Box sx={webStyle.fieldControl}>
                      <Typography sx={webStyle.inputLabel}>
                        {configJSON.propertyNameText}
                      </Typography>
                      <input
                        style={{...webStyle.inputElemnt, ...(this.state.validate && errors.propertyName ? webStyle.inputElementError : {})}}
                        data-test-id="propertyName"
                        type="text"
                        value={values?.propertyName}
                        onChange={(event) => {
                          setFieldValue("propertyName", event.target.value);
                        }}
                      />
                      {this.state.validate && errors.propertyName && (
                        <Tooltip
                          open={Boolean(errors.propertyName)}
                          title={errors.propertyName}
                          componentsProps={tooltipStyles}
                          arrow
                        >
                          <img
                            src={errorIcon}
                            alt=""
                            style={webStyle.errorIcon}
                          />
                        </Tooltip>
                      )}
                    </Box>
                    <Box sx={webStyle.fieldControl}>
                      <Typography sx={webStyle.inputLabel}>
                        {configJSON.serviceAddressText}
                      </Typography>
                      <input
                        style={{...webStyle.inputElemnt, ...(this.state.validate && errors.serviceAddress ? webStyle.inputElementError : {})}}
                        data-test-id="serviceAddress"
                        type="text"
                        value={values?.serviceAddress}
                        onChange={(event) => {
                          setFieldValue("serviceAddress", event.target.value);
                        }}
                      />
                      {this.state.validate && errors.serviceAddress && (
                        <Tooltip
                          open={Boolean(errors.serviceAddress)}
                          title={errors.serviceAddress}
                          componentsProps={tooltipStyles}
                          arrow
                        >
                          <img
                            src={errorIcon}
                            alt=""
                            style={webStyle.errorIcon}
                          />
                        </Tooltip>
                      )}
                    </Box>
                    <Box sx={webStyle.fieldControl}>
                      <Typography sx={webStyle.inputLabel}>
                        {configJSON.meterLocationText}
                      </Typography>
                      <input
                        style={{...webStyle.inputElemnt, ...(this.state.validate && errors.meterLocation ? webStyle.inputElementError : {})}}
                        data-test-id="meterLocation"
                        type="text"
                        value={values?.meterLocation} 
                        onChange={(event) => {
                          setFieldValue("meterLocation", event.target.value);
                        }}
                      />
                      {this.state.validate && errors.meterLocation && (
                        <Tooltip
                          open={Boolean(errors.meterLocation)}
                          title={errors.meterLocation}
                          componentsProps={tooltipStyles}
                          arrow
                        >
                          <img
                            src={errorIcon}
                            alt=""
                            style={webStyle.errorIcon}
                          />
                        </Tooltip>
                      )}
                    </Box>
                    <Box sx={webStyle.fieldControl}>
                      <Typography sx={webStyle.inputLabel}>
                        {configJSON.buildingManagerText}
                      </Typography>
                      <input
                        style={{...webStyle.inputElemnt, ...(this.state.validate && errors.buildingManager ? webStyle.inputElementError : {})}}
                        data-test-id="buildingManager"
                        type={"text"}
                        value={values?.buildingManager}
                        onChange={(event) => {
                          setFieldValue("buildingManager", event.target.value);
                        }}
                      />
                      {this.state.validate && errors.buildingManager && (
                        <Tooltip
                          open={Boolean(errors.buildingManager)}
                          title={errors.buildingManager}
                          componentsProps={tooltipStyles}
                          arrow
                        >
                          <img
                            src={errorIcon}
                            alt=""
                            style={webStyle.errorIcon}
                          />
                        </Tooltip>
                      )}
                    </Box>
                    <Box sx={webStyle.fieldControlGroup}>
                      <Box sx={webStyle.fieldControl}>
                        <Typography sx={webStyle.inputLabel} >
                          {configJSON.phoneNumberText}
                        </Typography>
                        <input
                          style={{...webStyle.inputElemnt, ...(this.state.validate && errors.phone ? webStyle.inputElementError : {})}}
                          value={values?.phone}
                          data-test-id="phone"
                          type={"text"}
                          onChange={(event) => {
                            setFieldValue(
                              "phone",
                              event.target.value.replace(/\D/g, "")
                            );
                          }}
                        />
                        {this.state.validate && errors.phone && (
                          <Tooltip
                            open={Boolean(errors.phone)}
                            title={errors.phone}
                            componentsProps={tooltipStyles}
                            arrow
                          >
                            <img
                              src={errorIcon}
                              alt=""
                              style={webStyle.errorIcon}
                            />
                          </Tooltip>
                        )}
                      </Box>
                      <Box sx={webStyle.fieldControl}>
                        <Typography sx={webStyle.inputLabel} >
                          {configJSON.emailText}
                        </Typography>
                        <input
                          style={{...webStyle.inputElemnt, ...(this.state.validate && errors.email ? webStyle.inputElementError : {})}}
                          data-test-id="email"
                          type={"email"}
                          value={values?.email}
                          onChange={(event) => {
                            setFieldValue("email", event.target.value);
                          }}
                        />
                        {this.state.validate && errors.email && (
                          <Tooltip
                            open={Boolean(errors.email)}
                            title={errors.email}
                            componentsProps={tooltipStyles}
                            arrow
                          >
                            <img
                              src={errorIcon}
                              alt=""
                              style={webStyle.errorIcon}
                            />
                          </Tooltip>
                        )}
                      </Box>
                    </Box>
                    <Box sx={webStyle.fieldControlGroup}>
                      <Box sx={webStyle.fieldControl}>
                        <Typography sx={webStyle.inputLabel}>
                          {configJSON.meterOfReadText}
                        </Typography>
                        <Box sx={webStyle.meterToReadBlcok}>
                          <button
                            style={{...webStyle.numberFieldControlButton, ...webStyle.numberFieldControlButtonLeft}}
                            type="button"
                            data-test-id="meterToReadMinus"
                            onClick={() => {
                              setFieldValue(
                                "meterToRead",
                                ((Number(values?.meterToRead) - 1) || 1).toString()
                              );
                            }}
                          >
                            <img
                              src={removeIcon}
                              style={webStyle.numberFieldControlIcon}
                            />
                          </button>
                          <input
                            style={webStyle.numberInputElemnt}
                            data-test-id="meterToRead"
                            type="text"
                            min={0}
                            value={values?.meterToRead}
                          />
                          {this.state.validate && errors.meterToRead && (
                          <Tooltip
                            open={Boolean(errors.meterToRead)}
                            title={errors.meterToRead}
                            componentsProps={tooltipMeterToReadStyles}
                            arrow
                          >
                            <img
                              src={errorIcon}
                              alt=""
                              style={webStyle.errorMeterToReadIcon}
                            />
                          </Tooltip>
                        )}
                          <button
                            style={{...webStyle.numberFieldControlButton, ...webStyle.numberFieldControlButtonRight}}
                            type="button"
                            data-test-id="meterToReadPlus"
                            onClick={() => {
                              setFieldValue(
                                "meterToRead",
                                (Number(values?.meterToRead) + 1).toString()
                              );
                            }}
                          >
                            <img
                              src={addIcon}
                              style={webStyle.numberFieldControlIcon}
                            />
                          </button>
                        </Box>
                      </Box>
                      <Box sx={webStyle.fieldControl}>
                        <Typography sx={webStyle.inputLabel}>
                          {configJSON.readingPerionText}
                        </Typography>
                        <Select
                          value={values?.readingPeriod}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          data-test-id="readingPeriod"
                          sx={webStyle.selectInput}
                          onChange={(event) => {
                            setFieldValue("readingPeriod", event.target.value);
                          }}
                        >
                          <MenuItem sx={webStyle.selectItem} value={'monthly'}>Monthly</MenuItem>
                          <MenuItem sx={webStyle.selectItem} value={'two_months'}>2 months</MenuItem>
                          <MenuItem sx={webStyle.selectItem} value={'quarterly'}>Quarterly</MenuItem>
                          <MenuItem sx={webStyle.selectItem} value={'four_months'}>4 months</MenuItem>
                          <MenuItem sx={webStyle.selectItem} value={'six_months'}>6 months</MenuItem>
                          <MenuItem sx={webStyle.selectItem} value={'yearly'}>Yearly</MenuItem>
                        </Select>
                      </Box>
                      <Box sx={webStyle.fieldControl}>
                        <Typography sx={webStyle.inputLabel}>
                          {configJSON.startReadingFronText}
                        </Typography>
                        <Select
                          value={values?.startReadingFrom}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          data-test-id="startReadingFrom"
                          sx={webStyle.selectInput}
                          onChange={(event) => {
                            setFieldValue("startReadingFrom", event.target.value);
                          }}
                        >
                          {months.map(month => (
                            <MenuItem sx={webStyle.selectItem} value={month}>{month}</MenuItem>
                          ))}
                        </Select>
                      </Box>
                    </Box>
                    <Box sx={webStyle.fieldControl}>
                      <Typography sx={webStyle.inputLabel} >
                        {configJSON.receiveReportText}
                      </Typography>
                      <Select
                        value={0}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        data-test-id="reportTo"
                        sx={webStyle.selectInput}
                        onChange={(event) => {
                          setFieldValue("reportTo", event.target.value);
                        }}
                      >
                        <MenuItem sx={webStyle.selectItem} value={0}>{configJSON.emailText}</MenuItem>
                      </Select>
                    </Box>
                    <Box sx={webStyle.fieldCheckboxControl}>
                      <Typography sx={webStyle.switchTitle}>
                        {configJSON.getInvoiceText}
                      </Typography>
                      <IOSSwitch
                        sx={{ m: 1 }}
                        defaultChecked
                        data-test-id="getInvoice"
                      />
                    </Box>
                    <Box sx={webStyle.fieldCheckboxControl}>
                      <Typography sx={webStyle.switchTitle}>
                        {configJSON.addServiceText}
                      </Typography>
                      <IOSSwitch
                        sx={{ m: 1 }}
                        defaultChecked
                        data-test-id="addServiceCharge"
                      />
                    </Box>
                    <Box sx={webStyle.fieldActionGroup}>
                      <Button
                        sx={webStyle.cancelButton}
                        data-test-id="cancelBtn"
                        // type="button"
                        onClick={() => {
                          this.goPropertieesPage();
                        }}
                      >
                        {configJSON.cancelText}
                      </Button>
                      <Button
                        sx={webStyle.submitButton}
                        data-test-id="submitBtn"
                        // type="button"
                        onClick={() => {
                          this.setFormDate(values);
                          this.setValidate(true);
                          if ((Object.keys(errors).length == 0) || this.state.propertyId > 0) {
                            handleSubmit();
                          }
                        }}
                      >
                        {configJSON.saveText}
                      </Button>
                    </Box>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
      </Box>
      //Merge Engine End DefaultContainer
      // Customizable Area Start
    );
  }
}
// Customizable Area Start
const tooltipStyles = {
  tooltip: {
    sx: {
      color: '#FFFFFF',
      fontFamily: "'Inter', sans-serif",
      fontSize: '13px',
      lineHeight: '18px',
      fontWeight: '400',
      padding: '8px 12px',
      backgroundColor: '#1E293B',
      borderRadius: '8px',
      width: 'fit-content',
      maxWidth: '480px',
      '& .MuiTooltip-arrow': {
        color: '#1E293B',
      },
    }
  }
};

const tooltipMeterToReadStyles = {
  tooltip: {
    sx: {
      color: '#FFFFFF',
      fontFamily: "'Inter', sans-serif",
      fontSize: '13px',
      lineHeight: '18px',
      fontWeight: '400',
      padding: '8px 12px',
      backgroundColor: '#1E293B',
      borderRadius: '8px',
      width: 'fit-content',
      maxWidth: '480px',
      '& .MuiTooltip-arrow': {
        color: '#1E293B',
      },
    }
  }
};

const webStyle = {
  addPropertiesContainer: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#F8FAFC",
    fontFamily: "'Inter', sans-serif",
  },
  addPropertiesBlock: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  addPropertiesBlockTitle: {
    margin: "10px",
  },
  addPropertyFormContainer: {
    margin: "40px auto 0",
    maxWidth: "700px",
    width: "80%",
  },
  formContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  formTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    marginBottom: "6px",
    "@media (max-width: 500px)": {
      fontSize: "22px",
    },
  },
  formSubTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#475467",
    marginBottom: "20px",
    "@media (max-width: 500px)": {
      fontSize: "14px",
    },
  },
  fieldControl: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    margin: "10px",
    position: "relative",
  },
  select: {
    fontFamily: "'Inter', sans-serif",
    '& .MuiSelect-select': {
      fontFamily: "'Inter', sans-serif",
    },
  },
  selectItem: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    color: '#0F172A',
    padding: '8px 12px',
    margin: '0 8px 12px',
    borderRadius: '8px',
    '&:last-of-type': {
      margin: '0 8px',
    },
    '&.Mui-selected': {
      backgroundColor: '#E8FBFF',
      color: '#1A7BA4',
      fontWeight: '600',
    }
  },
  selectInput: {
    fontFamily: "'Inter', sans-serif",
    backgroundColor: "#FFFFFF",
    "& fieldset": {
      border: "1px solid #CBD5E1",
    },
    "& fieldset:hover": {
      border: "1px solid #CBD5E1 !important",
    },
  },
  fieldControlGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    "@media (max-width: 767px)": {
      flexDirection: "column",
    },
  },
  fieldActionGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  fieldCheckboxControl: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    margin: "10px",
    position: "relative",
  },
  switchTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#334155",
    alignItems: "center",
    display: "flex",
    "@media (max-width: 500px)": {
      fontSize: "12px",
    },
  },
  inputLabel: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#334155",
    marginBottom: "5px",
    alignItems: "center",
    display: "flex",
    "@media (max-width: 500px)": {
      fontSize: "12px",
    },
  },
  numberInputElemnt: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    height: "56px",
    padding: "10px",
    border: "none",
    "-moz-appearance": "textfield",
    "& ::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& ::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    width: "34%",
    textAlign: "center" as const,
  },
  inputElemnt: {
    height: "56px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    "@media (max-width: 500px)": {
      fontSize: "14px",
    },
  },
  inputElementError: {
    border: "1px solid #F87171",
  },
  errorIcon: {
    width: "24px",
    height: "24px",
    position: "absolute" as "absolute",
    top: 43,
    right: 16,
  },
  errorMeterToReadIcon: {
    width: "24px",
    height: "24px",
    position: "absolute" as "absolute",
    top: 43,
    right: 75,
  },
  numberFieldControlIcon: {
    width: "24px",
    height: "24px",
  },
  numberFieldControlButton: {
    backgroundColor: "rgba(26, 123, 164, 0.1)",
    border: "none",
    width: "33%",
    '&:hover': {
      backgroundColor: "rgba(26, 123, 164, 0.2)",
    }
  },
  numberFieldControlButtonLeft: {
    borderRadius: '8px 0 0 8px',
  },
  numberFieldControlButtonRight: {
    borderRadius: '0 8px 8px 0',
  },
  cancelButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    margin: "10px",
    height: "44px",
    borderRadius: "8px",
    border: "1px solid #1A7BA4",
    background: "#F8FAFC",
    color: "#1A7BA4",
    padding: "0px 20px",
    textTransform: "capitalize",
    "@media (max-width: 500px)": {
      fontSize: "14px",
      width: "100%"
    },
  },
  submitButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    margin: "10px",
    height: "44px",
    borderRadius: "8px",
    border: "none",
    background: "#1A7BA4",
    color: "#FFFFFF",
    padding: "0px 20px",
    textTransform: "capitalize",
    "@media (max-width: 500px)": {
      fontSize: "14px",
      width: "100%"
    },
    "&:hover": {
      backgroundColor: "#1A7BA4",
    },
  },
  meterToReadBlcok: {
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "row",
  },
};
// Customizable Area End
