import React, {useEffect, useState } from 'react';
import { Typography, 
  Box, 
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Divider,
  IconButton, 
  InputBase,
  Radio,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";
import * as Yup from "yup";
import { FieldArray, Formik } from "formik";
import {
  searchIcon,
  addIcon,
  editIcon,
  deleteIcon,
  hotWaterIcon,
  coldWaterIcon,
  caledarIcon,
  noPropertieisIcon,
  errorIcon,
  calendarErrorIcon,
} from "./assets";
import CloseRoundedIcon from '@mui/icons-material/Close';
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import { styled } from '@mui/material/styles';
import { Meter, TenantMeterDialogProps } from './CustomersPageController';
import moment from 'moment';

  export interface Props {
    getMeterList: () => void;
    meterList: Meter[];
    meterListLoading: boolean;
    addEditMeterDialogProps: TenantMeterDialogProps | null;
    setAddEditMeterDialogProps: (props: TenantMeterDialogProps | null) => void;
    deleteTenantMeterIdDialog: number | null;
    setDeleteTenantMeterIdDialog: (id: number | null) => void;
    addEditMeter: (meter: Meter) => void;
    deleteMeter: (meterId: number) => void;
    isTest?: boolean;
  }

  const itemsPerPage = 10;

  export const trimSpaces = (value: string) => value.trim().replace(/\s+/g, ' ');

  export const CustomerMeters = ({ meterList, getMeterList, meterListLoading, addEditMeter, addEditMeterDialogProps, setAddEditMeterDialogProps, setDeleteTenantMeterIdDialog, isTest, deleteMeter, deleteTenantMeterIdDialog }: Props) => {
    const [validate , setValidate ] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [getMeterListRequestFired, setMeterListRequestFired] = useState(false);
    const [searchText, setSearchText] = useState('');

    const filteredMeterList = searchText ? meterList.filter((meter) => meter.meter_number?.toLowerCase().includes(searchText.toLowerCase())) : meterList;
    const totalPages = Math.ceil(filteredMeterList.length / itemsPerPage) || 1;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const displayedMeters = filteredMeterList.slice(indexOfFirstItem, indexOfLastItem);
    
    useEffect(() => {
      getMeterList();
      setMeterListRequestFired(true);
    }, []);

    useEffect(() => {
      if (displayedMeters.length === 0 && currentPage !== 1) {
        setCurrentPage(1);
      }
    }, [displayedMeters, currentPage]);

    const handleNextPage = () => {
      if (currentPage < Math.ceil(meterList.length / itemsPerPage)) {
        setCurrentPage(currentPage + 1);
      }
    };

    const handlePrevPage = () => {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
      }
    };

    const handlePageChange = (page: number) => {
      setCurrentPage(page);
    }

    const questionSchema = () => {
      return Yup.object().shape({
        meter_type: Yup.string(),
        meter_number: Yup.string().transform((value: string) => trimSpaces(value)).matches(/^\d+$/, 'Only numbers are allowed').required("Meter number is required"),
        last_reading: Yup.string().transform((value: string) => trimSpaces(value || '')).test('conditional-required', 'Last reading is required', function(value) {
          const { parent } = this;
          return !parent.lastReadingAvailable || (parent.lastReadingAvailable && value);
        }).min(5, "Must be 5 digits minimum").max(9, "Must be 9 digits maximum"),
        date_of_last_reading: Yup.date().max(new Date(), `Last reading date should be equal or earlier today`).test('conditional-required', 'Last reading date is required', function(value) {
          const { parent } = this;
          return !parent.lastReadingAvailable || (parent.lastReadingAvailable && value);
        }),
        lastReadingAvailable: Yup.boolean(),
      })
    };

    const closeAddEditDialog = () => {
      setAddEditMeterDialogProps(null);
      setValidate(false);
    };

    if (meterListLoading || (!getMeterListRequestFired && !isTest)) {
      return (
        <Box sx={webStyle.loaderWrapper} data-test-id="loaderWrapper">
          <CircularProgress sx={webStyle.loader} />
        </Box>
      );
    }

    const renderTypeWithIcon = (meterType: 'Water' | 'Electric', waterMeterType?: 'Cold' | 'Hot') => {
      if (meterType === 'Electric') {
        return <Typography sx={{ fontSize: '14px', fontFamily: "'Inter', sans-serif" }}>Electrical</Typography>;
      }

      if (waterMeterType === "Cold") {
        return (
          <>
            Water &nbsp;
            <div style={{ display: "flex", alignItems: "center" }}>
              (
              <img
                src={coldWaterIcon}
                alt="Cold Icon"
                style={{ width: "16px", height: "16px" }}
              />{" "}
              &nbsp;Cold)
            </div>
          </>
        );
      } else if (waterMeterType === "Hot") {
        return (
          <>
            Water &nbsp;
            <div style={{ display: "flex", alignItems: "center" }}>
              (
              <img
                src={hotWaterIcon}
                alt="Hot Icon"
                style={{ width: "16px", height: "16px" }}
              />{" "}
              &nbsp;Hot)
            </div>
          </>
        );
      }
    };

    return (
      <Box sx={webStyle.metersContainer}>
        {!meterList.length ? (
          <Box sx={webStyle.metersContainer}>
          <Box sx={webStyle.noMetersBlock}>
            <Box sx={webStyle.noMetersBlockTitle}>
              <img src={noPropertieisIcon} style={webStyle.mainImage} />
              <Typography sx={webStyle.noMetersTitle}>No meters</Typography>
              <Typography sx={webStyle.noMetersSubTitle}>
                Click on the button below to start adding your meters
              </Typography>
              <Box sx={webStyle.noMetersAddButtonBlock}>
                <Button
                  sx={webStyle.addNewButton}
                  data-test-id="addButton"
                  // type="button"
                  onClick={() => setAddEditMeterDialogProps({ isAdd: true })}
                >
                  <Typography sx={webStyle.noTenantsButtonText}>Add meter</Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        ) : (
          <>
            <Box sx={webStyle.propertiesTableHeader}>
        <Box sx={webStyle.searchContainer}>
          <Box sx={webStyle.fieldControl}>
            <StyledInput
              data-test-id="search"
              type={"text"}
              placeholder= "Search"
              onChange={(event) => {
                setSearchText(event.target.value);
                currentPage !== 1 && setCurrentPage(1);
              }}
              sx={webStyle.searchElement}
            />
            <img src={searchIcon} alt="" style={webStyle.searchIcon} />
          </Box>
        </Box>
        <Box sx={webStyle.actionContainer}>
          <Button
            data-test-id="addMeter"
            variant='text'
            sx={webStyle.addPropertiesButton}
            onClick={() => {
              // this.addMeter();
              setAddEditMeterDialogProps({ isAdd: true });
            }}
          >
            <img src={addIcon} alt="" style={webStyle.btnIcon} />
            <Typography sx={webStyle.typographyTitleText}>
              Add meter
            </Typography>
          </Button>
        </Box>
      </Box>
      <Typography sx={webStyle.customerCount} data-test-id="resultsLength">
        {filteredMeterList.length + " results"}
      </Typography>
      {filteredMeterList.length > 0 && (
        <Box sx={webStyle.tableWrapper(totalPages)}>
          <Box sx={webStyle.propertisTable}>
            <Box sx={webStyle.propertisTableHead}>
              <Box sx={webStyle.propertisTableDataHead}>
                <Typography sx={webStyle.headerTitle}>
                  Meter Number
                </Typography>
                <Typography sx={webStyle.headerTitle}>
                  Type
                </Typography>
                <Typography sx={webStyle.headerTitle}>
                  Last reading
                </Typography>
                <Typography sx={webStyle.headerTitle}>
                Date of Last reading
                </Typography>
              </Box>
              <Typography sx={webStyle.headerTitleAction}>
                Actions
              </Typography>
            </Box>
            {displayedMeters.map((meter: Meter, index: any) => (
              <Box sx={webStyle.propertisTableBody} key={meter.id}>
                <Box sx={webStyle.propertisTableDataBody}>
                  <Box sx={webStyle.propertyItem}>{meter.meter_number}</Box>
                  <Box sx={webStyle.propertyItem}>
                    {renderTypeWithIcon(meter.meter_type, meter.water_sub_type)}
                  </Box>
                  <Box sx={webStyle.propertyItem}>{meter.last_reading || '-'}</Box>
                  <Box sx={webStyle.propertyItem}>{meter.date_of_last_reading ? moment(meter.date_of_last_reading).format('DD/MM/YYYY') : '-'}</Box>
                </Box>               
                <Box sx={webStyle.propertyItemAction}>
                  <Button
                    data-test-id={`editMeter.${index}`}
                    style={{flex: 1}}
                    sx={webStyle.editButton}
                    onClick={() => {
                      setAddEditMeterDialogProps({ initialMeter: meter });
                    }}
                  >
                    <img src={editIcon} alt="" style={webStyle.actionButtonIcon} />
                    <Typography sx={webStyle.typographyText}>
                      Edit
                    </Typography>
                  </Button>
                  <Button
                    data-test-id={`deleteMeter.${index}`}
                    style={{flex: 1}}
                    sx={webStyle.deleteButton}
                    onClick={() => {
                      setDeleteTenantMeterIdDialog(meter.id ?? null);
                    }}
                  >
                    <img src={deleteIcon} alt="" style={webStyle.actionButtonIcon} />
                    <Typography sx={webStyle.typographyText}>
                    Delete
                    </Typography>
                  </Button>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      )}
          </>
        )}
      {totalPages > 1 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "20px",
            marginBottom: "5%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* Previous Button */}
            <button
              onClick={() => handlePrevPage()}
              data-test-id="previous-button"
              style={{
                backgroundColor: "inherit",
                border: "none",
                cursor: "pointer",
                padding: 0,
                marginRight: "4px",
              }}
              disabled={currentPage === 1}
            >
              <KeyboardArrowLeftRoundedIcon
                sx={
                  currentPage === 1
                    ? webStyle.chevronIconDisabled
                    : webStyle.chevronIconActive
                }
              />
            </button>

            {totalPages <= 3 ? (
              [...Array(totalPages).keys()].map((page) => (
                <button
                  key={page + 1}
                  data-test-id={"page-button"}
                  style={{
                    marginRight: "4px",
                    width:
                      currentPage === page + 1
                        ? "24px"
                        : "16px",
                    height: "24px",
                    backgroundColor:
                      currentPage === page + 1
                        ? "#DBF5FF"
                        : "inherit",
                    color:
                      currentPage === page + 1
                        ? "#1A7BA4"
                        : "#64748B",
                    border: "none",
                    textDecoration:
                      currentPage !== page + 1
                        ? "underline"
                        : "none",
                    borderRadius: "8px",
                    fontWeight:
                      currentPage !== page + 1 ? 400 : 700,
                    cursor: "pointer",
                    fontSize: "12px",
                    fontFamily: "'Inter', sans-serif",
                  }}
                  onClick={() => handlePageChange(page + 1)}
                >
                  {page + 1}
                </button>
              ))
            ) : (
              <>
                {currentPage !== 1 && (
                  <button
                    data-test-id="ellipsis"
                    style={{
                      marginRight: "4px",
                      width: "16px",
                      height: "24px",
                      backgroundColor: "inherit",
                      color: "#64748B",
                      border: "none",
                      textDecoration: "underline",
                      borderRadius: "8px",
                      cursor: "pointer",
                      fontSize: "12px",
                      fontFamily: "'Inter', sans-serif",
                    }}
                    onClick={() => handlePageChange(1)}
                  >
                    1
                  </button>
                )}

                {currentPage > 3 && (
                  <span style={{ marginRight: "4px", color: "#64748B" }}>
                    ...
                  </span>
                )}

                {currentPage > 2 && (
                  <button
                    data-test-id="middle-page"
                    style={{
                      marginRight: "4px",
                      width: "24px",
                      height: "24px",
                      backgroundColor: "inherit",
                      color: "#64748B",
                      border: "none",
                      textDecoration: "underline",
                      borderRadius: "8px",
                      cursor: "pointer",
                      fontSize: "12px",
                      fontFamily: "'Inter', sans-serif",
                    }}
                    onClick={() =>
                      handlePageChange(currentPage - 1)
                    }
                  >
                    {currentPage - 1}
                  </button>
                )}
                <button
                  data-test-id="middle-page2"
                  style={{
                    marginRight: "4px",
                    width: "24px",
                    height: "24px",
                    backgroundColor: "#DBF5FF",
                    color: "#1A7BA4",
                    border: "none",
                    borderRadius: "8px",
                    cursor: "pointer",
                    fontWeight: 700,
                    fontSize: "12px",
                    fontFamily: "'Inter', sans-serif",
                  }}
                  onClick={() =>
                    handlePageChange(currentPage)
                  }
                >
                  {currentPage}
                </button>

                {currentPage < totalPages - 1 && (
                  <button
                    data-test-id="middle-page3"
                    style={{
                      marginRight: "4px",
                      width: "16px",
                      height: "24px",
                      color: "#64748B",
                      backgroundColor: "inherit",
                      border: "none",
                      textDecoration: "underline",
                      borderRadius: "8px",
                      cursor: "pointer",
                      fontSize: "12px",
                      fontFamily: "'Inter', sans-serif",
                    }}
                    onClick={() =>
                      handlePageChange(currentPage + 1)
                    }
                  >
                    {currentPage + 1}
                  </button>
                )}

                {currentPage < totalPages - 2 && (
                  <span style={{ marginRight: "4px", color: "#64748B" }}>
                    ...
                  </span>
                )}

                {currentPage !== totalPages && (
                  <button
                    data-test-id="last-page"
                    style={{
                      marginRight: "4px",
                      width:
                        currentPage === totalPages
                          ? "24px"
                          : "16px",
                      height: "24px",
                      backgroundColor:
                        currentPage === totalPages
                          ? "#DBF5FF"
                          : "inherit",
                      color:
                        currentPage === totalPages
                          ? "#1A7BA4"
                          : "#64748B",
                      border: "none",
                      textDecoration:
                        currentPage !== totalPages
                          ? "underline"
                          : "none",
                      borderRadius: "8px",
                      cursor: "pointer",
                      fontSize: "12px",
                      fontFamily: "'Inter', sans-serif",
                      fontWeight:
                        currentPage !== totalPages
                          ? 400
                          : 700,
                    }}
                    onClick={() =>
                      handlePageChange(totalPages)
                    }
                  >
                    {totalPages}
                  </button>
                )}
              </>
            )}

            <button
              data-test-id="next-page"
              onClick={() => handleNextPage()}
              style={{
                backgroundColor: "inherit",
                border: "none",
                cursor: "pointer",
                padding: 0,
              }}
              disabled={currentPage === totalPages}
            >
              <KeyboardArrowRightRoundedIcon
                sx={
                  currentPage === totalPages
                    ? webStyle.chevronIconDisabled
                    : webStyle.chevronIconActive
                }
              />
            </button>
          </Box>

          <Box sx={{ marginRight: "10px" }}>
            <Typography
              fontFamily={'"Nunito Sans", sans-serif'}
              fontSize={"12px"}
              color={"grey"}
            >
              {indexOfFirstItem + 1}-{indexOfLastItem} of{" "}
              {filteredMeterList.length} results
            </Typography>
          </Box>
        </Box>
      )}
      <Dialog 
        open={!!addEditMeterDialogProps} 
        onClose={closeAddEditDialog} 
        maxWidth="sm" 
        fullWidth 
        PaperProps={{
          style: {
            borderRadius: '8px',
            padding: '32px',
            maxHeight: 'calc(100% - 120px)',
          },
        }}
        data-test-id="meterDialog"
      >
        <DialogTitle style={{ padding: '0 0 32px', position: 'relative' }}>
          <Typography variant="h6" sx={webStyle.dialogTitleText}>
            {`${addEditMeterDialogProps?.isAdd ? 'Add' : 'Edit'} meter`}
          </Typography>
          <IconButton
            onClick={closeAddEditDialog}
            style={{ position: 'absolute', right: '0', top: '-8px', width: '56px', height: '56px', backgroundColor: '#F8FAFC', borderRadius: '52px' }}
          >
            <CloseRoundedIcon sx={{ width: '24px', height: '24px', color: '#64748B' }} />
          </IconButton>
        </DialogTitle>
        <Divider sx={{ marginBottom: '22px' }} />
        <DialogContent sx={{ padding: 0, overflowY: 'unset' }}>

        <Box sx={webStyle.addTenantsContainer}>
        <Box sx={webStyle.addTenantsBlock}>
          <Box sx={webStyle.addTenantFormContainer}>
            <Formik
              initialValues={{
                id: addEditMeterDialogProps?.initialMeter?.id || null,
                meter_number: addEditMeterDialogProps?.initialMeter?.meter_number || '',
                meter_note: addEditMeterDialogProps?.initialMeter?.meter_note || '',
                meter_type: addEditMeterDialogProps?.initialMeter?.meter_type || 'Water',
                water_sub_type: addEditMeterDialogProps?.initialMeter?.water_sub_type || 'Cold',
                volume: addEditMeterDialogProps?.initialMeter?.volume || 'Cu ft',
                metric: addEditMeterDialogProps?.initialMeter?.metric || 'Standard',
                lastReadingAvailable: addEditMeterDialogProps?.initialMeter ? !!addEditMeterDialogProps?.initialMeter?.date_of_last_reading : true,
                last_reading: addEditMeterDialogProps?.initialMeter?.last_reading || '',
                date_of_last_reading: addEditMeterDialogProps?.initialMeter?.date_of_last_reading || '',
              }}
              validateOnChange={true}
              validationSchema={questionSchema}
              onSubmit={(values: Meter & { lastReadingAvailable: boolean }) => {
                setValidate(false);
                addEditMeter({...values, meter_number: trimSpaces(values.meter_number), date_of_last_reading: values.date_of_last_reading ? trimSpaces(values.date_of_last_reading) : null, meter_note: trimSpaces(values.meter_note || '')});
              }}
              data-test-id="addTenantForm"
            >
              {({ errors, values, setFieldValue, handleSubmit, setValues }) => (
                <form style={{ margin: 0 }}>
                  <Box sx={webStyle.formContainer}>
                            <Box sx={webStyle.meterBlcok}>
                              <Box sx={{...webStyle.arrayFieldControl, margin: 0}}>
                                <Typography sx={webStyle.radioButtonTitleLabel}>
                                  Meter
                                </Typography>
                                <Box sx={webStyle.radioButtonGroup}>
                                  <Box sx={webStyle.radioButtnBlock}>
                                    <Radio
                                      sx={webStyle.radioButtonElemnt}
                                      data-test-id={`meterType.water`}
                                      name={`meter_type_water`}
                                      value={"Water"}
                                      checked={"water" === values?.meter_type?.toLowerCase()}
                                      onChange={(event) => {
                                        setFieldValue(`meter_type`, event.currentTarget.value);
                                        setFieldValue('water_sub_type', 'Cold');
                                        setFieldValue('volume', 'Cu ft');
                                        setFieldValue('metric', 'Standard');
                                      }}
                                    />
                                    <Typography sx={webStyle.radioButtonLabel}>
                                      Water
                                    </Typography>
                                    
                                  </Box>
                                  <Box sx={webStyle.radioButtnBlock}>
                                    <Radio
                                        sx={webStyle.radioButtonElemnt}
                                        data-test-id={`meterType.electrical`}
                                        name={`meter_type_electrical`}
                                        value={"Electric"}
                                        checked={"electric" === values?.meter_type?.toLowerCase()}
                                        onChange={(event) => {
                                          setFieldValue(`meter_type`, event.currentTarget.value);
                                          setFieldValue('water_sub_type', undefined);
                                          setFieldValue('volume', undefined);
                                          setFieldValue('metric', undefined);
                                        }}
                                      />
                                      <Typography sx={webStyle.radioButtonLabel}>
                                        Electrical
                                      </Typography>
                                  </Box>
                                </Box>
                              </Box>
                             
                              {values?.meter_type?.toLowerCase() === 'water' && (
                                <>
                                  <Box sx={webStyle.arrayFieldControl}>
                                    <Typography sx={webStyle.radioButtonTitleLabel}>
                                      Type
                                    </Typography>
                                    <Box sx={webStyle.radioButtonGroup}>
                                      <Box sx={webStyle.radioButtnBlock}>
                                        <Radio
                                          sx={webStyle.radioButtonElemnt}
                                          data-test-id={`meter_water_sub_type_cold`}
                                          name={`meter_water_sub_type_cold`}
                                          value="Cold"
                                          checked={values.water_sub_type?.toLowerCase() === "cold"}
                                          onChange={(event) => {
                                            setFieldValue(`water_sub_type`, event.currentTarget.value);
                                          }}
                                        />
                                        <Typography sx={webStyle.radioButtonLabel}>
                                          Cold
                                        </Typography>
                                      </Box>
                                      <Box sx={webStyle.radioButtnBlock}>
                                        <Radio
                                          sx={webStyle.radioButtonElemnt}
                                          data-test-id={`meter_water_sub_type_hot`}
                                          name={`meter_water_sub_type_cold`}
                                          value="Hot"
                                          checked={values?.water_sub_type?.toLowerCase() === "hot"}
                                          onChange={(event) => {
                                            setFieldValue(`water_sub_type`, event.currentTarget.value);
                                          }}
                                        />
                                        <Typography sx={webStyle.radioButtonLabel}>
                                          Hot
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Box>
                                  <Box sx={webStyle.arrayFieldControl}>
                                    <Typography sx={webStyle.radioButtonTitleLabel}>
                                      Volume
                                    </Typography>
                                    <Box sx={webStyle.radioButtonGroup}>
                                      <Box sx={webStyle.radioButtnBlock}>
                                        <Radio
                                          sx={webStyle.radioButtonElemnt}
                                          data-test-id={`volume_cubic_feet`}
                                          name={`volume_cu_ft`}
                                          value="Cu ft"
                                          checked={values?.volume?.toLowerCase() === "cu ft" || values?.volume?.toLowerCase() === "cu_ft"}
                                          onChange={(event) => {
                                            setFieldValue('volume', event.currentTarget.value);
                                          }}
                                        />
                                        <Typography sx={webStyle.radioButtonLabel}>
                                          Cu ft
                                        </Typography>
                                      </Box>
                                      <Box sx={webStyle.radioButtnBlock}>
                                        <Radio
                                          sx={webStyle.radioButtonElemnt}
                                          data-test-id={`volume_gallons`}
                                          name={`volume_gallons`}
                                          value="Gallons"
                                          checked={values?.volume?.toLowerCase() === "gallons"}
                                          onChange={(event) => {
                                            setFieldValue(`volume`, event.currentTarget.value);
                                          }}
                                        />
                                        <Typography sx={webStyle.radioButtonLabel}>
                                          Gallons
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Box>
                                  <Box sx={webStyle.arrayFieldControl}>
                                    <Typography sx={webStyle.radioButtonTitleLabel}>
                                      Metric
                                    </Typography>
                                    <Box sx={webStyle.radioButtonGroup}>
                                      <Box sx={webStyle.radioButtnBlock}>
                                        <Radio
                                          sx={webStyle.radioButtonElemnt}
                                          data-test-id={`metric_standard`}
                                          name={`metric_standard`}
                                          value="Standard"
                                          checked={values?.metric?.toLowerCase() === "standard"}
                                          onChange={(event) => {
                                            setFieldValue(`metric`, event.currentTarget.value);
                                          }}
                                        />
                                        <Typography sx={webStyle.radioButtonLabel}>
                                          Standard
                                        </Typography>
                                      </Box>
                                      <Box sx={webStyle.radioButtnBlock}>
                                        <Radio
                                          sx={webStyle.radioButtonElemnt}
                                          data-test-id={`metric_10cu_ft`}
                                          name={`metric_10cu_ft`}
                                          value="10 cu.ft/gallons"
                                          checked={values?.metric?.toLowerCase() === "10 cu.ft/gallons" || values?.metric?.toLowerCase() === 'ten_cuft_gallons'}
                                          onChange={(event) => {
                                            setFieldValue(`metric`, event.currentTarget.value);
                                          }}
                                        />
                                        <Typography sx={webStyle.radioButtonLabel}>
                                          10 cu.ft/gallons
                                        </Typography>
                                      </Box>
                                      <Box sx={webStyle.radioButtnBlock}>
                                        <Radio
                                          sx={webStyle.radioButtonElemnt}
                                          data-test-id={`metric_100cu_ft`}
                                          name={`metric_100cu_ft`}
                                          value="100 cu.ft/gallons"
                                          checked={values?.metric?.toLowerCase() === "100 cu.ft/gallons" || values?.metric?.toLowerCase() === 'hundred_cuft_gallons'}
                                          onChange={(event) => {
                                            setFieldValue(`metric`, event.currentTarget.value);
                                          }}
                                        />
                                        <Typography sx={webStyle.radioButtonLabel}>
                                          100 cu.ft/gallons
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Box>
                                </>
                              )}

                              <Box sx={{...webStyle.arrayFieldControl,  margin: '16px 0 10px'}}>
                                <Typography sx={webStyle.inputLabel}>
                                 Meter number
                                </Typography>
                                <input
                                  style={{...webStyle.inputElemnt1, ...(validate && errors.meter_number ? webStyle.inputElementError : {})}}
                                  data-test-id={`meter_number`}
                                  type="text"
                                  value={values?.meter_number || ''}
                                  onChange={(event) => {
                                    setFieldValue(`meter_number`, event.target.value);
                                  }}
                                />
                                {validate && errors.meter_number && (
                                  <Tooltip
                                    open={Boolean(errors.meter_number)}
                                    title={errors.meter_number}
                                    componentsProps={tooltipStyles}
                                    arrow
                                  >
                                    <img
                                      src={errorIcon}
                                      alt=""
                                      style={webStyle.errorIcon}
                                    />
                                  </Tooltip>
                                )}
                                </Box>
                                <Box sx={webStyle.arrayFieldControl}>
                                  <Typography sx={webStyle.radioButtonTitleLabel}>
                                    Last reading available
                                  </Typography>
                                  <Box sx={webStyle.lastReadingAvailableGroup}>
                                    <Box sx={webStyle.lastReadingAvailableBlock}>
                                      <Radio
                                        checked={values?.lastReadingAvailable}
                                        sx={webStyle.radioButtonElemnt}
                                        data-test-id={`meter.lastReadingAvailable.avaliable`}
                                        onChange={() => {
                                          setFieldValue(
                                            `lastReadingAvailable`,
                                            true
                                          );
                                        }}
                                      />
                                      <Typography sx={webStyle.radioButtonLabel}>
                                        Yes
                                      </Typography>
                                    </Box>
                                    <Box
                                      sx={webStyle.lastReadingAvailableBlock}
                                    >
                                      <Radio
                                        checked={!values?.lastReadingAvailable}
                                        sx={webStyle.radioButtonElemnt}
                                        data-test-id={`meter.lastReadingAvailable.notAvailable`}
                                        onChange={() => {
                                          setValues({...values, lastReadingAvailable: false, last_reading: '', date_of_last_reading: ''})
                                        }}
                                      />
                                      <Typography sx={webStyle.radioButtonLabel}>
                                        No
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>
                                <Box sx={webStyle.arrayFieldControl}>
                                  <Typography sx={webStyle.inputLabel}>
                                    Last reading
                                  </Typography>
                                  <input
                                    style={{...webStyle.inputElemnt1, ...(validate && errors.last_reading ? webStyle.inputElementError : {})}}
                                    data-test-id={`last_reading`}
                                    type="text"
                                    value={values?.last_reading || ''}
                                    onChange={(event) => {
                                      setFieldValue(`last_reading`, event.target.value);
                                    }}
                                    disabled={!values?.lastReadingAvailable}
                                  />
                                  {validate && errors.last_reading && (
                                    <Tooltip
                                      open={Boolean(errors.last_reading)}
                                      title={errors.last_reading}
                                      componentsProps={tooltipStyles}
                                      arrow
                                    >
                                      <img
                                        src={errorIcon}
                                        alt=""
                                        style={webStyle.errorIcon}
                                      />
                                    </Tooltip>
                                  )}
                                </Box>
                                <Box sx={webStyle.arrayFieldControl}>
                                  <Typography sx={webStyle.inputLabel}>
                                    Date of last reading
                                  </Typography>
                                  <StyledInput
                                    data-test-id={`date_of_last_reading`}
                                    type="date"
                                    placeholder="mm/dd/yyyy"
                                    inputProps={{ max: moment().format('YYYY-MM-DD') }}
                                    value={values?.date_of_last_reading ? moment(values.date_of_last_reading).format('YYYY-MM-DD') : ''}
                                    onChange={(event) => {
                                      setFieldValue(`date_of_last_reading`, event.target.value);
                                    }}
                                    style={!values?.date_of_last_reading ? { color: '#94A3B8' } : undefined}
                                    sx={{...(validate && errors.date_of_last_reading ? webStyle.inputElementError : undefined), ...(!values?.lastReadingAvailable ? webStyle.inputDisabled : undefined)}}
                                    disabled={!values?.lastReadingAvailable}
                                  />
                                  <Tooltip
                                    open={validate && !!errors.date_of_last_reading}
                                    title={errors.date_of_last_reading}
                                    componentsProps={tooltipStyles}
                                    arrow
                                  >
                                    <img
                                      src={validate && !!errors.date_of_last_reading ? calendarErrorIcon : caledarIcon}
                                      alt=""
                                      style={{...webStyle.calendarIcon as any, ...(!values?.lastReadingAvailable ? webStyle.inputDisabled : undefined)}}
                                    />
                                  </Tooltip>
                                </Box>
                                <Box sx={webStyle.arrayFieldControl}>
                                  <Typography sx={webStyle.inputLabel}>
                                    Meter note (eg.: Meter location, access code)
                                  </Typography>
                                  <input
                                    style={webStyle.inputElemnt1}
                                    data-test-id={`meter_note`}
                                    type="text"
                                    value={values?.meter_note || ''}
                                    onChange={(event) => {
                                      setFieldValue(`meter_note`, event.target.value);
                                    }}
                                  />
                                </Box>
                              </Box>
                      <Box sx={webStyle.fieldControlGroup}>
                        <Button
                          sx={webStyle.cancelButton}
                          data-test-id="cancelBtn"
                          // type="Button"
                          onClick={closeAddEditDialog}
                        >
                         Cancel
                        </Button>
                        <Button
                          sx={webStyle.submitButton}
                          data-test-id="submitBtn"
                          // type="Button"
                          onClick={() => {
                            // this.setTenantData({ tenantName: values?.tenantName });
                            // this.setMetersData(values?.meters);
                            setValidate(true);
                            if (Object.keys(errors).length == 0) {
                              handleSubmit();
                            }
                            // this.goPropertieesPage();
                          }}
                        >
                         Save
                        </Button>
                      </Box>
                    </Box>
                  </form>
                )}
              </Formik>
            </Box>
          </Box>
        </Box>


        </DialogContent>
      </Dialog>
      <Dialog
              open={!!deleteTenantMeterIdDialog}
              onClose={() => setDeleteTenantMeterIdDialog(null)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              data-test-id="deleteDialog"
              PaperProps={{
                style: {
                  width: "633px", 
                  padding: "40px 32px 32px 32px",
                  borderRadius: "16px", 
                  fontFamily:"'Inter', sans-serif",
                },
              }}
            >
        <DialogTitle
          id="alert-dialog-title"
          style={{ fontWeight: "600", padding: '0 0 24px',fontFamily:"'Inter', sans-serif", fontSize:'24px', letterSpacing: '-1px' }}
        >
          Are you sure you want to delete this meter?
        </DialogTitle>
        <DialogContent style={{ marginBottom: '32px', padding: 0 }}>
          <DialogContentText
            id="alert-dialog-description"
            style={{ color: "#64748B", fontFamily:"'Inter', sans-serif",fontSize:"18px"}}
          >
            This action can’t be undone, and all the data related to this meter will be permanently deleted and no longer accessible.
          </DialogContentText>
          <Divider style={{ marginTop: '32px' }} />
        </DialogContent>
        <DialogActions style={{ padding: 0 }}>
          <Button
            onClick={() => setDeleteTenantMeterIdDialog(null)}
            variant="outlined"
            sx={webStyle.dialogCancelButton}
          >
            Cancel
          </Button>
          <Button
            sx={webStyle.confirmDelete}
            startIcon={<DeleteIcon />}
            onClick={() => deleteTenantMeterIdDialog && deleteMeter(deleteTenantMeterIdDialog)}
            data-test-id="deleteDialogButton"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>


    </Box>
    );
  };

  const StyledInput = styled(InputBase)(() => ({
    height: "56px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    backgroundColor: '#FFFFFF',
    fontFamily: "'Inter', sans-serif",

    '& .MuiInputBase-input': {
      fontSize: '16px',

      '&::placeholder': {
        fontFamily: "'Inter', sans-serif",
        color: '#94A3B8',
        fontSize: '16px',
        opacity: 1, // Firefox requires this
      },
    },
  }));

  const tooltipStyles = {
    tooltip: {
      sx: {
        color: '#FFFFFF',
        fontFamily: "'Inter', sans-serif",
        fontSize: '13px',
        lineHeight: '18px',
        fontWeight: '400',
        padding: '8px 12px',
        backgroundColor: '#1E293B',
        borderRadius: '8px',
        width: 'fit-content',
        maxWidth: '480px',
        '& .MuiTooltip-arrow': {
          color: '#1E293B',
        },
      }
    }
  };

 
  const webStyle = {
    metersContainer: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: "rgba(248, 250, 252, 1)",
    },
    tenantTitleContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      margin: "20px 0px",
      justifyContent: "space-between",
    },
    propertiesTableHeader: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "space-between",
      margin: "20px 0px 16px",
      // margin: "20px 0px",
      "@media (max-width: 767px)": {
        flexDirection: "column",
      }
    },
    searchContainer: {
      display: "flex",
      flexDirection: "column",
      width: "340px",
      "@media (max-width: 767px)": {
        width: "100%",
      }
    },
    actionContainer: {
      display: "flex",
      flexDirection: "row",
      "@media (max-width: 767px)": {
        width: "100%",
      }
    },
    tenantActionContainer: {
      display: "flex",
      flexDirection: "column",
    },
    tableWrapper: (totalPages: number) => ({
      minHeight: totalPages > 1 ? '674px' : 'unset',
      marginBottom: "30px",
      "@media (max-width: 1330px)": {
        // "@media (max-width: 767px)": {
        overflow: "auto",
      },
    }),
    propertisTable: {
      border: "1px solid #CBD5E1",
      borderRadius: "12px",
      minWidth: "980px",
      "@media (max-width: 969px)": {
        minWidth: "670px",
      },
    },
    propertisTableHead: {
      display: "flex",
      flexDirection: "row",
      padding: "0px 16px",
      backgroundColor: 'rgba(248, 250, 252, 1)',
      borderRadius: '12px',
    },
    propertisTableDataHead: {
      display: "flex",
      flexDirection: "row",
      width: "calc(100% - 200px)",
      "@media (max-width: 969px)": {
        width: "calc(100% - 70px)",
        // width: "120px",
      }
    },
    propertisTableBody: {
      display: "flex",
      flexDirection: "row",
      padding: "0px 16px",
      borderTop: "1px solid #CBD5E1",
      backgroundColor: '#FFFFFF',
      '&:last-of-type': {
        borderRadius: '0 0 12px 12px',
      }
    },
    propertisTableDataBody: {
      display: "flex",
      flexDirection: "row",
      width: "calc(100% - 200px)",
      padding: "0px 10px",
      "@media (max-width: 969px)": {
        width: "calc(100% - 70px)",
      }
    },
    fieldControl: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      marginTop: "10px",
      marginBottom: "10px",
      position: "relative",
    },
    inputElemnt: {
      height: "44px",
      padding: "10px 40px 10px 10px",
      borderRadius: "8px",
      border: "1px solid #CBD5E1",
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
    },
    searchIcon: {
      width: "24px",
      height: "24px",
      position: "absolute" as "absolute",
      top: 10,
      right: 10,
    },
    btnIcon: {
      width: "24px",
      height: "24px",
    },
    backButton: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      color: "#1A7BA4",
      border: "none",
      backgroundColor: "#F8FAFC",
      display: "flex",
      flexDirection: "row" as const,
      height: "44px",
      alignItems: "center",
      margin: "10px 0px",
      padding: "6px 6px",
    },
    addPropertiesButton: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "22px",
      color: "#FFFFFF",
      border: "none",
      borderRadius: "7px",
      backgroundColor: "#1A7BA4",
      display: "flex",
      flexDirection: "row" as const,
      height: "44px",
      alignItems: "center",
      margin: "10px 0px",
      padding: "6px 10px",
      textTransform: "none",
      "@media (max-width: 767px)": {
      // "@media (max-width: 969px)": {
        width: "100%",
      },
      "&:hover": {
        backgroundColor: "#1A7BA4",
      },
    },
    headerTitle: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "12px",
      fontWeight: 700,
      lineHeight: "18px",
      textTransform: "uppercase" as const,
      color: "#64748B",
      width: "25%",
      overflow: "hidden",
      margin: "12px 4px",
    },
    headerTitleAction: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "12px",
      fontWeight: 700,
      lineHeight: "18px",
      textTransform: "uppercase" as const,
      color: "#64748B",
      minWidth: "70px",
      overflow: "hidden",
      margin: "12px 4px",
      width: "200px",
      "@media (max-width: 969px)": {
        width: "70px",
      }
    },
    editButton: {
      backgroundColor: "#FFFFFF",
      color: "#1A7BA4",
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "22px",
      padding: "6px",
      borderRadius: "7px",
      border: "1px solid #1A7BA4",
      display: "flex",
      flexDirection: "row" as const,
      height: "36px",
      alignItems: "center",
      textTransform: "none",
      "@media (max-width: 969px)": {
        minWidth: "32px",
        width: "32px",
      }
    },
    deleteButton: {
      backgroundColor: "#FFFFFF",
      color: "#DC2626",
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "22px",
      padding: "6px 10px 6px 10px",
      borderRadius: "7px",
      border: "1px solid #DC2626",
      display: "flex",
      flexDirection: "row" as const,
      height: "36px",
      alignItems: "center",
      textTransform: "none",
      "@media (max-width: 969px)": {
        minWidth: "32px",
        width: "32px",
      }
    },
    propertyItem: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "22px",
      color: "#0F172A",
      width: "25%",
      wordBreak: 'break-word',
      margin: "13px 4px",
      alignItems: "center",
      display: "flex",
    },
    propertyItemAction: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "22px",
      color: "#0F172A",
      minWidth: "70px",
      display: "flex",
      flexDirection: "row",
      overflow: "hidden",
      margin: "13px 4px",
      alignItems: "center",
      gap: "8px",
      width: "200px",
      "@media (max-width: 969px)": {
        width: "70px",
      }
    },
    tenantTitle: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "24px",
      fontWeight: 600,
      lineHeight: "29px",
      color: "#0F172A",
      wordBreak: 'break-word',
    },
    tenantSubTitle: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
      color: "#64748B",
      wordBreak: 'break-word',
    },
    typographyTitleText: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "22px",
      marginLeft: "6px",
    },
    typographyText: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "22px",
      marginLeft: "6px",
      "@media (max-width: 969px)": {
        display: "none"
      },
    },
    addTenantsContainer: {
      display: "flex",
      flexDirection: "row",
    },
    addTenantsBlock: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },
    addTenantFormContainer: {
      maxWidth: "600px",
    },
    formContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },
    formTitle: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: "32px",
      marginBottom: "10px",
      wordBreak: 'break-word',
    },
    formSubTitle: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
      color: "#475467",
      marginBottom: "20px",
      wordBreak: 'break-word',
    },
    fieldControl1: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      margin: "10px",
      position: "relative",
    },
    mainFieldControl: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      margin: "10px 0px",
      position: "relative",
    },
    arrayFieldControl: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      margin: "10px 0px",
      position: "relative",
    },
    fieldControlGroup: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
    },
    fieldCheckboxControl: {
      display: "flex",
      flex: 1,
      flexDirection: "row",
      margin: "10px",
      position: "relative",
    },
    switchTitle: {
      alignItems: "center",
      display: "flex",
    },
    radioButtonTitleLabel: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "22px",
      color: "#64748B",
      margin: "2px 0px 10px",
      alignItems: "center",
      display: "flex",
    },
    inputLabel: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "22px",
      color: "#334155",
      marginBottom: "5px",
      alignItems: "center",
      display: "flex",
      "@media (max-width: 767px)": {
        fontSize: "14px",
        // fontWeight: 700,
        lineHeight: "20px",
      }
    },
    radioButtonLabel: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
      color: "#0F172A",
      alignItems: "center",
      display: "flex",
      marginLeft: '8px',
      "@media (max-width: 767px)": {
        fontSize: "14px",
        marginTop: "4px",
        // fontWeight: 700,
        // lineHeight: "20px",
      }
    },
    radioButtnBlock: {
      display: "flex",
      flexDirection: "row",
      flex: '1',
      alignItems: 'center',
    },
    numberInputElemnt: {
      height: "56px",
      padding: "10px",
      border: "none",
      "-moz-appearance": "textfield",
      "& ::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "& ::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      width: "34%",
      textAlign: "center" as const,
    },
    inputElemnt1: {
      height: "56px",
      padding: "10px",
      borderRadius: "8px",
      border: "1px solid #CBD5E1",
      fontSize: '16px',
    },
    inputElementError: {
      border: "1px solid #F87171",
    },
    radioButtonElemnt: {
      padding: 0,
      width: '20px',
      height: '20px',
      marginTop: "4px",
      '& .MuiSvgIcon-root': {
        color: '#64748B',
        width: '20px',
        height: '20px',
      },
      '&.Mui-checked': {
        backgroundColor: '#1A7BA4',
        '& .MuiSvgIcon-root': {
          width: '20px',
          height: '20px',
        },
        '& .MuiSvgIcon-root:first-of-type': {
          color: '#1A7BA4',
        },
        '& .MuiSvgIcon-root:nth-of-type(2)': {
          color: '#FFFFFF',
        }
      },
    },
    errorIcon: {
      width: "24px",
      height: "24px",
      position: "absolute" as "absolute",
      top: 43,
      right: 16,
    },
    numberFieldControlIcon: {
      width: "24px",
      height: "24px",
    },
    numberFieldControlButton: {
      color: "#1A7BA41A",
      border: "none",
      width: "33%",
    },
    cancelButton: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      margin: "10px 16px 0 0",
      height: "44px",
      borderRadius: "8px",
      border: "1px solid #1A7BA4",
      background: "#FFFFFF",
      color: "#1A7BA4",
      padding: "0px 20px",
      textTransform: "none",
      "@media (max-width: 767px)": {
        width: '100%',
      }
    },
    submitButton: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      margin: "10px 0 0",
      height: "44px",
      borderRadius: "8px",
      border: "none",
      background: "#1A7BA4",
      color: "#FFFFFF",
      padding: "0px 20px",
      textTransform: "none",
      "@media (max-width: 767px)": {
        width: '100%',
      },
      "&:hover": {
        backgroundColor: "#1A7BA4",
      },
    },
    meterToReadBlcok: {
      border: "1px solid #CBD5E1",
      borderRadius: "8px",
      display: "flex",
      flexDirection: "row",
    },
    meterBlcok: {
      border: "1px solid #1A7BA44D",
      backgroundColor: "#1A7BA40F",
      padding: "16px",
      borderRadius: "12px",
      margin: "20px 0px",
    },
    meterTitle: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    meterTitleLabel: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "18px",
      fontWeight: 700,
      lineheight: "24px",
      color: "#0F172A",
    },
    addNewMeterButton: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      marginBottom: "15px",
      height: "44px",
      borderRadius: "8px",
      border: "none",
      background: "inherit",
      color: "#1A7BA4",
      padding: "0px 20px",
      flexDirection: "row" as const,
      display: "flex",
      alignItems: "center",
      flex: 1,
      justifyContent: "center",
    },
    removeMeeterButton: {
      height: "36px",
      width: "46px",
      border: "none",
      backgroundColor: "inherit",
    },
    typographyTitleText1: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "22px",
    },
    calendarIcon: {
      width: "24px",
      height: "24px",
      position: "absolute" as "absolute",
      top: 43,
      right: 13,
      backgroundColor: '#FFFFFF',
      pointerEvents: 'none',
    },
    radioButtonBlock: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '8px',
    },
    radioButtonElement: {
      marginRight: '8px',
    },
    confirmDelete:{
       padding: "6px 12px",
       borderRadius: "5px",
       textTransform: "none",
       backgroundColor:"white",
       color:"#DC2626",
       border:"1px solid #DC2626",
       fontFamily:"'Inter', sans-serif",
       fontWeight:"600",
       height: '44px',
       marginLeft: '16px !important',
       "@media (max-width: 767px)": {
          // marginLeft: '0px !important',
          width: "100%"
        }
    },
    dialogCancelButton:{
      color: "#1A7BA4",
      border: "1px solid #1A7BA4",
      padding: "6px 12px",
      borderRadius: "5px",
      textTransform: "none",
      fontWeight:"700",
      fontFamily:"'Inter', sans-serif",
      fontSize: '16px',
      height: '44px',
      "@media (max-width: 767px)": {
        width: "100%"
      }
    },
   customerCount: {
     fontFamily: "'Inter', sans-serif",
     fontSize: "16px",
     fontWeight: 500,
     lineHeight: "22px",
     color: "#334155",
     margin: "0 0 16px"
   },
   actionButtonIcon: {
    width: '20px',
    height: '20px',
    // marginRight: '6px',
   },
   chevronIconActive: {
     "> path": {
       fill: "#475569",
     },
   },
   chevronIconDisabled: {
     "> path": {
       fill: "#64748B",
     },
   },
   dialogTitleText: {
    fontWeight: '700',
    fontSize: '24px',
    fontFamily:"'Inter', sans-serif",
   },
   radioButtonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    "@media (max-width: 767px)": {
      flexDirection: "column",
    }
   },
   loaderWrapper: {
    margin: '200px auto 0',
    width: 'fit-content',
   },
   loader: {
    color: '#1A7BA4',
    height: '60px',
    width: '60px',
   },noMetersBlock: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    maxWidth: "600px",
    width: "80%",
  },
  noMetersBlockTitle: {
    margin: "80px 10px",
    textAlign: "center",
    "@media (max-width: 500px)": {
      margin: "10px",
    }
  },
  noMetersTitle: {
     fontFamily: "'Inter', sans-serif",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    marginBottom: "15px",
    marginTop: "50px",
    "@media (max-width: 500px)": {
      fontSize: "20px",
      lineHeight: "24px",
    }
  },
  noMetersSubTitle: {
     fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#475467",
    marginBottom: "20px",
    "@media (max-width: 500px)": {
      fontSize: "14px",
      lineHeight: "20px",
    }
  },
  mainImage: {
    width: "163px",
    height: "154px",    
  },
  addNewButton: {
     fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    margin: "15px auto",
    height: "56px",
    borderRadius: "8px",
    border: "none",
    background: "#1A7BA4",
    color: "#FFFFFF",
    padding: "0px 20px",
    flexDirection: "row" as const,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "430px",
    textTransform: "capitalize",
    "@media (max-width: 500px)": {
      fontSize: "14px",
      width: "100%"
    },
    "&:hover": {
      backgroundColor: "#1A7BA4",
    },
  },
  noTenantsButtonText: {
     fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
  },
  noMetersAddButtonBlock: {
    display: "flex",
    flexDirection: "row",
  },
  searchElement: {
    padding: "12px 44px 12px 12px",
    height: '44px',
  },
  lastReadingAvailableBlock: {
    display: "flex",
    flexDirection: "row",
    margin: "10px 0px",
    alignItems: "center",
    width: "131px",
    flex: "none",
  },
  lastReadingAvailableGroup: {
    display: "flex",
    justifyContent: "left",
  },
  inputDisabled: {
    backgroundColor: '#F5F5F4',
  }
  };
 
 