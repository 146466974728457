import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

interface Rate {
  rate: number;
  amount: number;
}

export interface Price {
  id?: string;
  name: string;
  consumptions: Rate[];
}

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface SnackbarProps {
  isOpen: boolean;
  type: 'Success' | 'Error';
  message: string;
}

interface S {
  step: number;
  validate: boolean;
  // Customizable Area Start
  waterPrices: Price[];
  waterPricesLoading: boolean;
  electricalPrices: Price[];
  electricalPricesLoading: boolean;
  serviceCharge: number;
  serviceChargeLoading: boolean;
  snackbarProps: SnackbarProps;
  token: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AdminSettingsPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  getWaterPricesCallId: string;
  editWaterPricesCallId: string;
  getElectricalPricesCallId: string;
  editElectricalPricesCallId: string;
  editElectricalPricesCallback: ((newPrices: Price[]) => void) | null;
  getServiceChargeCallId: string;
  editServiceChargeCallId: string;
  editServiceChargeCallback: (() => void) | null;
  editWaterPricesCallback: ((newPrices: Price[]) => void) | null;
  changeRateNameCallId: string;
  changeRateNameCallback: ((newPrices: Price[]) => void) | null;
  deletePriceCallId: string;
  deletePriceCallback: (() => void) | null;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.getWaterPricesCallId = "";
    this.editWaterPricesCallId = "";
    this.getElectricalPricesCallId = "";
    this.editElectricalPricesCallId = "";
    this.getServiceChargeCallId = "";
    this.editServiceChargeCallId = "";
    this.editServiceChargeCallback = null;
    this.editWaterPricesCallback = null;
    this.changeRateNameCallId = "";
    this.changeRateNameCallback = null;
    this.deletePriceCallId = "";
    this.deletePriceCallback = null;
    this.editElectricalPricesCallback = null;

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      step: 1,
      validate: false,
      waterPrices: [],
      waterPricesLoading: false,
      electricalPrices: [],
      electricalPricesLoading: false,
      serviceCharge: 0,
      serviceChargeLoading: false,
      snackbarProps: {
        isOpen: false,
        message: '',
        type: 'Success',
      },
      token: '',
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    
    if (!apiRequestCallId || !responseJson) {
      return;
    }

    if (apiRequestCallId === this.getWaterPricesCallId) {
      this.setWaterPrices(responseJson);
    } else if (apiRequestCallId === this.editWaterPricesCallId) {
      this.updateWaterPrices(responseJson);
    } else if (apiRequestCallId === this.getElectricalPricesCallId) {
      this.setElectricalPrices(responseJson);
    } else if (apiRequestCallId === this.editElectricalPricesCallId) {
      this.updateElectricalPrices(responseJson);
    } else if (apiRequestCallId === this.getServiceChargeCallId) {
      this.setServiceCharge(responseJson);
    } else if (apiRequestCallId ===  this.editServiceChargeCallId) {
      this.editServiceChargeResponseHandler(responseJson);
    } else if (apiRequestCallId === this.changeRateNameCallId) {
      this.changeRateNameResponseHandler(responseJson);
    } else if (apiRequestCallId === this.deletePriceCallId) {
      this.handleDeletePriceResponse(responseJson);
    }
    // Customizable Area End
  }

  setStep = (step: number) => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'AdminSettingsPage'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);

    this.setState({ step, validate: false });
    if (step === 1) {
      this.getWaterPrices();
    } else if (step === 2) {
      this.getElecricalPrices();
    } else if (step === 3) {
      this.getServiceCharge();
    }
  };

  setValidate = (validate: boolean) => {
    this.setState({ validate });
  };

  // Customizable Area Start
  getWaterPrices = () => {
    this.setState({ waterPricesLoading: true });
    const header = {
      "Content-Type": 'application/json',
      token: this.state.token
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getWaterPricesCallId = requestMessage.messageId;

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_settings/get_water_settings"
    );
    
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'Get'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  setWaterPrices = (responseJson: any) => {
    if (responseJson?.data) {
      this.setState({ waterPrices: responseJson.data.map((item: any) => item.attributes) || [] });
    } else {
      this.setState({
        snackbarProps: {
          isOpen: true,
          message: 'An unexpected error occurred while getting the water settings.',
          type: 'Error',
        }
      })
    }
    this.setState({ waterPricesLoading: false });
  }

  editWaterPrices = (value: Price, callback: (newPrices: Price[]) => void) => {
    const header = {
      "Content-Type": 'application/json',
      token: this.state.token
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editWaterPricesCallId = requestMessage.messageId;
    this.editWaterPricesCallback = callback;

    const httpData = {
      setting: {
        prices_attributes: [value],
      },
    }
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpData),
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_settings/water_setting"
    );
    
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'Put'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateWaterPrices = (responseJson: any) => {
    if (responseJson?.data?.attributes) {
      const responsePrice = responseJson.data.attributes;
      const isNewPrice = this.state.waterPrices.findIndex((price) => price.id === responsePrice.id) === -1;
      let newPrices = [...this.state.waterPrices];

      if (isNewPrice) {
        newPrices.unshift(responsePrice);
      } else {
        newPrices = newPrices.map((price) => {
          if (price.id === responsePrice.id) {
            return responsePrice;
          }
          return price;
        })
      }
      this.setState({ waterPrices: newPrices, snackbarProps: {
        isOpen: true,
        type: 'Success',
        message: 'Successfully updated the water price.'
      } });
      this.editWaterPricesCallback?.(newPrices);
    } else {
      this.setState({
        snackbarProps: {
          isOpen: true,
          message: 'An unexpected error occurred while updating the water settings.',
          type: 'Error',
        }
      })
    }
  }

  getElecricalPrices = () => {
    this.setState({ electricalPricesLoading: true });
    const header = {
      "Content-Type": 'application/json',
      token: this.state.token
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getElectricalPricesCallId = requestMessage.messageId;

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_settings/get_electricity_settings"
    );
    
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'Get'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  setElectricalPrices = (responseJson: any) => {
    if (responseJson?.data) {
      this.setState({ electricalPrices: responseJson.data.map((item: any) => item.attributes) || [] });
    } else {
      this.setState({
        snackbarProps: {
          isOpen: true,
          message: 'An unexpected error occurred while getting the electrical settings.',
          type: 'Error',
        }
      })
    }
    this.setState({ electricalPricesLoading: false });
  }

  editElectricalPrices = (value: Price, callback: (newPrices: Price[]) => void) => {
    const header = {
      "Content-Type": 'application/json',
      token: this.state.token
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editElectricalPricesCallId = requestMessage.messageId;
    this.editElectricalPricesCallback = callback;

    const httpData = {
      setting: {
        prices_attributes: [value],
      },
    }
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpData),
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_settings/electricity_setting"
    );
    
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'Put'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateElectricalPrices = (responseJson: any) => {
    if (responseJson?.data?.attributes) {
      const responsePrice = responseJson.data.attributes;
      const isNewPrice = this.state.electricalPrices.findIndex((price) => price.id === responsePrice.id) === -1;
      let newPrices = [...this.state.electricalPrices];

      if (isNewPrice) {
        newPrices.unshift(responsePrice);
      } else {
        newPrices = newPrices.map((price) => {
          if (price.id === responsePrice.id) {
            return responsePrice;
          }
          return price;
        })
      }

      this.setState({ electricalPrices: newPrices, snackbarProps: {
        isOpen: true,
        type: 'Success',
        message: 'Successfully updated the electrical price.'
      } });
      this.editElectricalPricesCallback?.(newPrices);
    } else {
      this.setState({
        snackbarProps: {
          isOpen: true,
          message: 'An unexpected error occurred while updating the electrical settings.',
          type: 'Error',
        }
      })
    }
  }

  deletePrice = (deleteIndex: number, isElectrical: boolean, callback: () => void) => {
    const header = {
      "Content-Type": 'application/json',
      token: this.state.token
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deletePriceCallId = requestMessage.messageId;
    this.deletePriceCallback = callback;

    const httpData = {
      price_id: (isElectrical ? this.state.electricalPrices : this.state.waterPrices).find((_,index) => index === deleteIndex)?.id,
    }
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpData),
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        isElectrical ? "bx_block_settings/delete_electricity_setting_price" : "bx_block_settings/delete_water_setting_price"
    );
    
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'Delete'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleDeletePriceResponse = (responseJson: any) => {
    console.log("Delete Price Response", responseJson, !!responseJson.message)
    if (!!responseJson?.message) {
    console.log(responseJson.message)
      this.setState({
        snackbarProps: {
          isOpen: true,
          type: 'Success',
          message: 'Successfully deleted the price.'
        } });
      this.deletePriceCallback?.();
    } else {
      this.setState({
        snackbarProps: {
          isOpen: true,
          message: 'An unexpected error occurred while deleting the price.',
          type: 'Error',
        }
      })
    }
  }

  changeRateName = (priceIndex: number, rateName: string, isElectrical: boolean, callback: () => void) => {
    const header = {
      "Content-Type": 'application/json',
      token: this.state.token
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.changeRateNameCallId = requestMessage.messageId;
    this.changeRateNameCallback = callback;

    const price = {...(isElectrical ? this.state.electricalPrices : this.state.waterPrices)?.[priceIndex], name: rateName}

    const httpData = {
      setting: {
        prices_attributes: [price],
      }
    }
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpData),
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        isElectrical ? "bx_block_settings/electricity_setting" : "bx_block_settings/water_setting"
    );
    
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'Put'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  changeRateNameResponseHandler = (responseJson: any) => {
    if (responseJson?.data?.attributes) {
      const responsePrice = responseJson.data.attributes;
      const isWaterSettingChanged = responseJson.data.type === 'water_setting';

      const newPrices = (isWaterSettingChanged ? this.state.waterPrices : this.state.electricalPrices).map((price) => {
        if (price.id === responsePrice.id) {
          return responsePrice;
        }
        return price;
      })
      if (isWaterSettingChanged) {
        this.setState({ waterPrices: newPrices });
      } else {
        this.setState({ electricalPrices: newPrices });
      }
      this.setState({
        snackbarProps: {
          isOpen: true,
          type: 'Success',
          message: 'Successfully updated the price name.'
        }
      });
      this.changeRateNameCallback?.(newPrices);
    } else {
      this.setState({
        snackbarProps: {
          isOpen: true,
          message: 'An unexpected error occurred while updating the name.',
          type: 'Error',
        }
      })
    }
  }

  getServiceCharge = () => {
    this.setState({ serviceChargeLoading: true });
    const header = {
      "Content-Type": 'application/json',
      token: this.state.token
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getServiceChargeCallId = requestMessage.messageId;

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_settings/get_service_charge"
    );
    
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'Get'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  setServiceCharge = (responseJson: any) => {
    if (responseJson?.amount === null || typeof responseJson?.amount === 'number') {
      this.setState({ serviceCharge: responseJson.amount || 0 });
    } else {
      this.setState({
        snackbarProps: {
          isOpen: true,
          message: 'An unexpected error occurred while getting the service charge.',
          type: 'Error',
        }
      })
    }
    this.setState({ serviceChargeLoading: false });
  }

  editServiceCharge = (amount: number, callback: () => void) => {
    const header = {
      "Content-Type": 'application/json',
      token: this.state.token
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editServiceChargeCallId = requestMessage.messageId;
    this.editServiceChargeCallback = callback;

    const httpData = {
      amount,
    }
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpData),
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_settings/service_charge"
    );
    
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'Put'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  editServiceChargeResponseHandler = (responseJson: any) => {
    if (responseJson?.amount === null || typeof responseJson?.amount === 'number') {
      this.setState({
        snackbarProps: {
          isOpen: true,
          type: 'Success',
          message: 'Successfully updated the service charge amount.'
        },
        serviceCharge: responseJson.amount || 0,
      })
      this.editServiceChargeCallback?.();
    } else {
      this.setState({
        snackbarProps: {
          isOpen: true,
          type: 'Error',
          message: 'An unexpected error occurred while updating the service charge.'
        }
      })
    }
  }

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  async componentDidUpdate(prevProps: Props, prevState: S): Promise<void> {
    const type = window.location.search?.replace('?type=', '') || "";

    if (type && prevState.step === this.state.step) { 
      if (type === 'water_settings' && prevState.step !== 1) { this.setStep(1); }
      if (type === 'electricity_settings' && prevState.step !== 2) { this.setStep(2); }
      if (type === 'service_charge' && prevState.step !== 3) { this.setStep(3); }
      if (type === 'notifications' && prevState.step !== 4) { this.setStep(4); }
    }
  }

  componentDidMount = async () => {
    const userRole = await getStorageData('role');

    if (userRole !== 'admin') {
      const message: Message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        'LogInPage'
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);

      return;
    }
    await this.getToken();
    this.getWaterPrices();
  }

  getToken = async () => {
    let token = await getStorageData("token");
    this.setState({ token: token });
  };
  // Customizable Area End
}
