import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData,setStorageData } from "../../../framework/src/Utilities";
import { Report, ReportPaymentStatus } from './CustomerReports.web';
import moment from "moment";
import { TenantDialogProps } from "./CustomerTenants.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface Property {
  id: string;
  property_name?: string | null;
  service_address?: string | null;
  meter_location?: string | null;
  building_manager?: string | null;
  phone_number?: string | null;
  email?: string | null;
  of_meaters_to_read?: number | null;
  of_tenants?: number | null;
  of_meters?: number | null;
  reading_period?: string | null;
  start_reading_from?: string | null;
  receive_report_by?: string | null;
  accountId?: string | null;
  book_number?: number | null;
}
interface Company {
  id: string;
  companyName: string;
  serviceAdress: string;
  createdAT: string | null;
  lastLoggedIn: string | null;
}
interface CompanyDetails {
  id: string;
  companyName: string;
  mailingAdress: string;
  city:string;
  state:string;
  zip:string;
  phone:string;
  fax:string;
  email:string;
  contactPerson:string;
  titleOfContactPerson:string
}
export interface Tenant {
  id: number | null;
  tenant_name: string;
  meters: Meter[];
}

export interface Meter {
  id?: number | null;
  meter_number: string;
  meter_type: 'Water' | 'Electric';
  meter_note: string;
  water_sub_type?: 'Hot' | 'Cold';
  volume?: string,
  metric?: string,
  last_reading: string,
  date_of_last_reading?: string | null,
}

export interface PropertyDialogProps {
  isAdd?: boolean;
  initialProperty?: Property;
}

export interface TenantMeterDialogProps {
  isAdd?: boolean;
  initialMeter?: Meter;
}

interface SnackbarProps {
  isOpen: boolean;
  type: 'Success' | 'Error';
  message: string;
}

export interface GenerateReportFormProps {
  propertyId: string;
  meterType: 'Water' | 'Electric';
  waterType: 'Cold' | 'Hot';
  dateFrom: string;
  dateTo: string;
}

interface Rate {
  rate: number;
  amount: number;
}

export interface Price {
  id: number;
  name: string;
  consumptions: Rate[];
}

export interface ReportListItem {
  report_id: string;
  property_name: string;
  property_id: string;
  generated_at: string;
  meter_type: "Water" | "Electric";
  water_sub_type: 'Cold' | 'Hot' | null;
  payment_status: ReportPaymentStatus;
  company_detail_id: number;
  report_sent?: boolean;
  invoice_sent?: boolean;
}

interface ReportProps {
  reportId: string;
  reportType: 'Water' | 'Electric';
  propertyId: string;
}

export interface Invoice {
  report_id?: string;
  invoice_id?: string;
  service_description?: string;
  service_rate?: number;
  service_quantity?: number;
  additional_charges_description?: string;
  additional_charges_rate?: number;
  additional_charges_quantity?: number;
}

interface S {
  propertiesList:Property[]
  currentPage:number;
  itemsPerPage:number;
  totalPages:number;
  currentcompany: Company[];
  firstItemIndex:number,
  lastItemIdex:number,
  tabIndex:number,
  selectedProperty: Property | null;
  // Customizable Area Start
  companyList: Company[]
  companyListLoading: boolean;
  companyDetail:CompanyDetails
  tenantList: Tenant[]
  selectedTenant: Tenant | null;
  meterList:Meter[]
  token:string
  isInvoiceOpen: boolean;
  propertiesLoading: boolean;
  addEditPropertyDialog: PropertyDialogProps | null;
  deleteCompanyPropertyIdDialog: string | null;
  snackbarProps: SnackbarProps;
  tenantsLoading: boolean;
  addEditTenantsDialogProps: TenantDialogProps | null;
  deleteTenantDialogId: number | string | null;
  tenantMetersLoading: boolean;
  addEditTenantMeterDialogProps: TenantMeterDialogProps | null;
  deleteTenantMeterIdDialog: number | null;
  companyDetailsApiErrors: { [key: string]: string } | null;
  isCompanyDetailsEditing: boolean;
  searchCompanyText: string;
  filteredCompanies: Company[];
  reportsList: ReportListItem[];
  reportsLoading: boolean;
  priceRates: Price[];
  priceRatesLoading: boolean;
  selectedReport: Report | null;
  selectedReportProps: ReportProps | null;
  selectedReportLoading: boolean;
  invoiceLoading: boolean;
  selectedInvoice: Invoice | null;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class companyPageController extends BlockComponent<Props, S, SS> {
  companyListCallId :any
  companyDetailsCallId :any
  editCompanyDetailsCallId :any
  companyPropertiesCallId: string;
  editCompanyPropertyCallId: string;
  deleteCompanyPropertyCallId: string;
  assignBookNumberToPropertyCall: {
    id: string;
    propertyId: string;
    bookNumber: number | null;
  };
  getPropertyTenantsCallId: string;
  addEditPropertyTenantCallId: string;
  deletePropertyTenantCallId: string;
  getTenantMetersCallId: string;
  addEditTenantMeterCallId: string;
  deleteTenantMeterCallId: string;
  generateReportCallId: string;
  getReportsListCallId: string;
  getPriceRatesCallId: string;
  getSelectedReportCallId: string;
  editReportCallId: string;
  getInvoiceCallId: string;
  editInvoiceCallId: string;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.companyListCallId = "";
    this.companyDetailsCallId="";
    this.editCompanyDetailsCallId="";
    this.companyPropertiesCallId = "";
    this.editCompanyPropertyCallId = "";
    this.deleteCompanyPropertyCallId = "";
    this.assignBookNumberToPropertyCall = {
      id: '',
      propertyId: '',
      bookNumber: null,
    };
    this.getPropertyTenantsCallId = "";
    this.addEditPropertyTenantCallId = "";
    this.deletePropertyTenantCallId = "";
    this.getTenantMetersCallId = "";
    this.addEditTenantMeterCallId = "";
    this.deleteTenantMeterCallId = "";
    this.generateReportCallId = "";
    this.getReportsListCallId = "";
    this.getPriceRatesCallId = "";
    this.getSelectedReportCallId = "";
    this.editReportCallId = "";
    this.getInvoiceCallId = "";
    this.editInvoiceCallId = "";
    this.state = {
      selectedReport: null,
      isInvoiceOpen: false,
      propertiesList: [],
      tenantList : [],
      meterList:[],
      companyList:[],
      companyListLoading: false,
      companyDetail: {
        id: '',
        companyName: '',
        mailingAdress: '',
        city: '',
        state: '',
        zip: '',
        phone: '',
        fax: '',
        email: '',
        contactPerson: '',
        titleOfContactPerson: '',
      },
      currentPage:1,
      itemsPerPage:10,
      totalPages: 0,
      currentcompany: [],
      firstItemIndex:1,
      lastItemIdex:10,
      tabIndex:0,
      selectedProperty: null,
      propertiesLoading: false,
      addEditPropertyDialog: null,
      deleteCompanyPropertyIdDialog: null,
      snackbarProps: { isOpen: false, type: 'Success', message: '' },
      selectedTenant: null,
      tenantsLoading: false,
      deleteTenantDialogId: null,
      addEditTenantsDialogProps: null,
      addEditTenantMeterDialogProps: null,
      tenantMetersLoading: false,
      deleteTenantMeterIdDialog: null,
      companyDetailsApiErrors: null,
      isCompanyDetailsEditing: false,
      searchCompanyText: '',
      filteredCompanies: [],
      reportsList: [],
      reportsLoading: false,
      priceRates: [],
      priceRatesLoading: false,
      selectedReportProps: null,
      selectedReportLoading: false,
      selectedInvoice: null,
      invoiceLoading: false,
      token:""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    
    if (!apiRequestCallId || !responseJson) {
      return;
    }
    if (apiRequestCallId === this.companyListCallId && responseJson) {
       this.setCompanyList(responseJson);
    }
    if (apiRequestCallId === this.companyDetailsCallId) {
        this.setCompanyDetails(responseJson);
    }
    if (apiRequestCallId === this.editCompanyDetailsCallId) {
      this.setCompanyDetails(responseJson);
     }
    if (apiRequestCallId === this.companyPropertiesCallId) {
      this.setCompanyProperties(responseJson);
    }
    if (apiRequestCallId === this.editCompanyPropertyCallId) {
      this.updateCompanyPropertiesList(responseJson);
    }
    if (apiRequestCallId === this.deleteCompanyPropertyCallId) {
      this.filterDeletedCompanyProperty(responseJson);
    }
    if (apiRequestCallId === this.assignBookNumberToPropertyCall.id) {
      this.validateAssignBookNumberToPropertyResponse(responseJson);
    }
    if (apiRequestCallId === this.getPropertyTenantsCallId) {
      this.setPropertyTenants(responseJson);
    }
    if (apiRequestCallId === this.addEditPropertyTenantCallId) {
      this.updatePropertyTenantsList(responseJson);
    }
    if (apiRequestCallId === this.deletePropertyTenantCallId) {
      this.filterDeletedPropertyTenant(responseJson);
    }
    if (apiRequestCallId === this.getTenantMetersCallId) {
      this.setTenantMeterList(responseJson);
    }
    if (apiRequestCallId === this.addEditTenantMeterCallId) {
      this.updateTenantMeterList(responseJson);
    }
    if (apiRequestCallId === this.deleteTenantMeterCallId) {
      this.filterDeletedTenantMeter(responseJson);
    }
    if (apiRequestCallId === this.getReportsListCallId) {
      this.handleGetReportsListResponse(responseJson);
    }
    if (apiRequestCallId === this.generateReportCallId) {
      this.handleGenerateReportResponse(responseJson);
    }
    if (apiRequestCallId === this.getPriceRatesCallId) {
      this.setPriceRates(responseJson);
    }
    if (apiRequestCallId === this.getSelectedReportCallId) {
      this.setSelectedReport(responseJson);
    }
    if (apiRequestCallId === this.editReportCallId) {
      this.editReportResponseHandler(responseJson);
    }
    if (apiRequestCallId === this.getInvoiceCallId) {
      this.setInvoice(responseJson);
    }
    if (apiRequestCallId === this.editInvoiceCallId) {
      this.editInvoiceResponseHandler(responseJson);
    }
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  searchcompany = (search: string) => {
    this.setState({ searchCompanyText: search, currentPage: 1 });
  }

  filtercompany = () => {

  }

  addcompany = () => {
    this.props.navigation.navigate("AddcompanyPage");
  }

  addTenant = (property: Property) => {
    this.setState({ selectedProperty: property });
  }

  viewDetails = (id: string) => {
    
  }

  deletePropeerties = (id: string) => {
    
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    const userRole = await getStorageData('role');

    if (userRole !== 'admin') {
      const message: Message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        'LogInPage'
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);

      return;
    }
    await this.getToken();
    await this.getCompanyList();
    await Promise.all([this.updatePagination()]);
    const storedCompanyDetail = await getStorageData("companyDetail");
    if (storedCompanyDetail) {
      this.setState({ companyDetail: JSON.parse(storedCompanyDetail) });
    }
  }

  async componentDidUpdate(prevProps: Props, prevState: S): Promise < void> {
    if (    
      prevState.currentPage !== this.state.currentPage ||
      prevState.itemsPerPage !== this.state.itemsPerPage ||
      prevState.companyList !== this.state.companyList ||
      prevState.searchCompanyText !== this.state.searchCompanyText
      ) {
      await Promise.all([ this.updatePagination()]);
    }
    if (    
      prevState.companyDetail !== this.state.companyDetail
      
      ) {
      await Promise.all([this.state.companyDetail]);
    }
  }

  getToken = async () => {
    let token = await getStorageData("token");
    this.setState({ token: token });
  };

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleNextPage = () => {
    if (this.state.currentPage < Math.ceil(this.state.companyList.length / this.state.itemsPerPage)) {
      this.setState({ currentPage: this.state.currentPage + 1 });
    }
  };

  handlePrevPage = () => {
    if (this.state.currentPage > 1) {
      this.setState({ currentPage: this.state.currentPage - 1 });
    }
  };

  handlePageChange = (pageNumber: number) => {
    this.setState({ currentPage: pageNumber });
  };

  updatePagination = () => {
    const { currentPage, itemsPerPage, companyList, searchCompanyText } = this.state;
    let indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const filteredCompanies = searchCompanyText ? companyList.filter((company) => company.companyName?.toLowerCase().includes(searchCompanyText.toLowerCase())) : companyList;
    const currentcompany = filteredCompanies.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredCompanies.length / itemsPerPage);

    if (indexOfLastItem > filteredCompanies.length ) {
        indexOfLastItem = filteredCompanies.length;
    }

    this.setState({
      filteredCompanies,
      currentcompany,
      totalPages,
      firstItemIndex:indexOfFirstItem,
      lastItemIdex:indexOfLastItem,
    });
  };

  handleTabChange = (event: any, newValue: any) => {
    this.setState({tabIndex:newValue});
  };

  handleCompanyChange = (details:any)=>{
      this.editCompanyDetails(details)
  }

  handleAddMeterClick = (tenant: Tenant) => {
    this.setState({ selectedTenant: tenant });
  };

  goToCustomerDetails = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));

    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      `CustomerDetailsPage`
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  goToCustomersPage = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));

    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      `CustomersPage`
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  goBack = () => {
    if (this.state.tabIndex === 2 && this.state.selectedReportProps && this.state.isInvoiceOpen) {
      this.setState({ isInvoiceOpen: false });

      return;
    }

    if (this.state.tabIndex === 2 && this.state.selectedReportProps) {
      this.setState({ selectedReportProps: null, selectedReport: null });

      return;
    }

    if (this.state.tabIndex === 2) {
      this.setState({ tabIndex: 0 });

      return;
    }

    if (this.state.tabIndex === 1 && this.state.selectedTenant) {
      this.setState({ selectedTenant: null });

      return;
    }

    if (this.state.tabIndex === 1 && this.state.selectedProperty) {
      this.setState({ selectedProperty: null });

      return;
    }

    if (this.state.tabIndex === 1) {
      this.setState({ tabIndex: 0 });

      return;
    }

    if (this.state.tabIndex === 0) {
      this.goToCustomersPage();
    }
  }

  getCompanyList = () => {
    this.setState({ companyListLoading: true });

    const header = {
        "Content-Type": configJSON.getPropertiesApiContentType,
        token: this.state.token
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.companyListCallId = requestMessage.messageId;

    const requestUrl = configJSON.companyDetailsEndPoint;

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        requestUrl
    );
    
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getPropertiesApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
};

setCompanyList = (responseJson: any) => {
  const companyData: Company[] = responseJson.data.map((item: any): Company => {
    return {
      id: item.attributes.id || "",
      companyName: item.attributes.company_name || "",
      serviceAdress: item.attributes.mailing_address || "",
      createdAT: moment(item.attributes.created_at).format('MM/DD/YYYY'),
      lastLoggedIn: item.attributes.last_login ? moment(item.attributes.last_login).format('MM/DD/YYYY') : ''
    };
  });

  this.setState({
    companyList: companyData,
    companyListLoading: false,
  });
};




companyDetails = (id:string) => {
  const header = {
    "Content-Type": configJSON.getPropertiesApiContentType,
    token: this.state.token
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.companyDetailsCallId = requestMessage.messageId;

  const requestUrl = `${configJSON.companyDetailsEndPoint}/${id}`;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    requestUrl
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.getPropertiesApiMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);

};

setCompanyDetails = (responseJson: any) => {
  if (!responseJson?.data?.attributes) {
    const errors: { [key: string]: string } = {};
    responseJson?.errors?.map((error: string) => {
      if (error.includes('email')) {
        errors.email = error;
      }
  
      if (error.includes('Phone')) {
        errors.phone = error;
      }
    })

    this.setState({ companyDetailsApiErrors: errors });

    return;
  }

  const companyDetail = {
    id: responseJson.data?.attributes.id.toString(),
    companyName: responseJson.data?.attributes.company_name,
    mailingAdress: responseJson.data?.attributes.mailing_address,
    city: responseJson.data?.attributes.city,
    state: responseJson.data?.attributes.state,
    zip: responseJson.data?.attributes.zip_code,
    phone: responseJson.data?.attributes.phone_number,
    fax: responseJson.data?.attributes.fax_number || '',
    email: responseJson.data?.attributes.primary_email,
    contactPerson: responseJson.data?.attributes.contact_person,
    titleOfContactPerson: responseJson.data?.attributes.title,
  };

  this.setState({ companyDetail: companyDetail, isCompanyDetailsEditing: false }, () => {
    setStorageData('companyDetail', JSON.stringify(companyDetail));
    this.goToCustomerDetails();
  });
};


editCompanyDetails = (details:any) => {
  this.setState({ companyDetailsApiErrors: null });
  const id = details.id;
  const body = {
    company_detail: {
      company_name: details.companyName,
      mailing_address: details.mailingAdress,
      zip_code: details.zip,
      city: details.city,
      state: details.state,
      title: details.titleOfContactPerson,
      contact_person: details.contactPerson,
      phone_number: details.phone,
      fax_number: details.fax,
      primary_email: details.email,
    },
  };
  const header = {
    "Content-Type": configJSON.getPropertiesApiContentType,
    token: this.state.token,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.editCompanyDetailsCallId = requestMessage.messageId;

  const requestUrl = `${configJSON.editCOpanyDetailsEndPoint}/${id}`;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    requestUrl
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "PUT"
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body)
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
};

getCompanyProperties = (id: string) => {
  this.setState({ propertiesLoading: true });

  const header = {
    "Content-Type": configJSON.getPropertiesApiContentType,
    "Cache-Control": 'no-cache, no-store, max-age=0, must-revalidate',
    token: this.state.token
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.companyPropertiesCallId = requestMessage.messageId;

  const requestUrl = `${configJSON.customerPropertiesEndPoint}?company_detail_id=${id}`;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    requestUrl
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.getPropertiesApiMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
}

addEditCompanyProperty = (property: Property) => {
  const header = {
    "Content-Type": configJSON.getPropertiesApiContentType,
    token: this.state.token
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.editCompanyPropertyCallId = requestMessage.messageId;

  const requestUrl = `${configJSON.customerPropertiesEndPoint}${property.id ? `/${property.id}` : ''}`;

  const httpData = {
    property,
    company_detail_id: this.state.companyDetail.id,
  }

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpData),
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    requestUrl
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    property.id ? 'Put' : 'Post',
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
}

deleteCompanyProperty = (propertyId: string) => {
  const header = {
    "Content-Type": configJSON.getPropertiesApiContentType,
    token: this.state.token
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.deleteCompanyPropertyCallId = requestMessage.messageId;

  const requestUrl = `${configJSON.customerPropertiesEndPoint}/${propertyId}`;

  const httpData = {
    company_detail_id: this.state.companyDetail.id,
  }

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpData),
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    requestUrl
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    'Delete',
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
}

assignBookNumberToProperty = (propertyId: string, bookNumber: number | null) => {
  const header = {
    "Content-Type": configJSON.getPropertiesApiContentType,
    token: this.state.token
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.assignBookNumberToPropertyCall = {
    id: requestMessage.messageId,
    propertyId,
    bookNumber,
  };

  const requestUrl = configJSON.assignBookNumberToProperty;

  const httpData = {
    property_id: propertyId,
    book_number: bookNumber,
  }

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpData),
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    requestUrl
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    'Post',
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
}

getPropertyTenants = (propertyId: string) => {
  this.setState({ tenantsLoading: true });

  const header = {
    "Content-Type": configJSON.getPropertiesApiContentType,
    token: this.state.token
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getPropertyTenantsCallId = requestMessage.messageId;

  const requestUrl = `${configJSON.propertyTenants(propertyId)}?company_detail_id=${this.state.companyDetail.id}`;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    requestUrl
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    'Get',
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
}

addEditPropertyTenant = (tenant: Tenant) => {
  const header = {
    "Content-Type": configJSON.getPropertiesApiContentType,
    token: this.state.token
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.addEditPropertyTenantCallId = requestMessage.messageId;

  const requestUrl = `${configJSON.propertyTenants(this.state.selectedProperty?.id)}${tenant.id !== null ? `/${tenant.id}` : ''}?company_detail_id=${this.state.companyDetail.id}`;

  const httpData = {
    tenant: {
      ...tenant,
      meters: undefined,
      meters_attributes: tenant.meters,
    },
    company_detail_id: this.state.companyDetail.id,
  }

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpData),
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    requestUrl
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    tenant.id !== null ? 'Put' : 'Post',
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
}

deletePropertyTenant = (tenantId: number | string) => {
  const header = {
    "Content-Type": configJSON.getPropertiesApiContentType,
    token: this.state.token
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.deletePropertyTenantCallId = requestMessage.messageId;

  const requestUrl = `${configJSON.propertyTenants(this.state.selectedProperty?.id)}/${tenantId}?company_detail_id=${this.state.companyDetail.id}`;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    requestUrl
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    'Delete',
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
}

getTenantMeters = (tenantId: number | string) => {
  this.setState({ tenantMetersLoading: true });

  const header = {
    "Content-Type": configJSON.getPropertiesApiContentType,
    token: this.state.token
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getTenantMetersCallId = requestMessage.messageId;

  const requestUrl = `${configJSON.getMeters(this.state.selectedProperty?.id, tenantId)}?company_detail_id=${this.state.companyDetail.id}`;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    requestUrl
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    'Get',
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
}

addEditTenantMeter = (meter: Meter) => {
  const header = {
    "Content-Type": configJSON.getPropertiesApiContentType,
    token: this.state.token
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.addEditTenantMeterCallId = requestMessage.messageId;

  const requestUrl = `${configJSON.createMeter(this.state.selectedProperty?.id, this.state.selectedTenant?.id)}${meter.id ? `/${meter.id}` : ''}?company_detail_id=${this.state.companyDetail.id}`;

  const httpData = {
    meter: {
      ...meter,
      water_sub_type: meter.meter_type === 'Electric' ? undefined : meter.water_sub_type,
      volume: meter.meter_type === 'Electric' ? undefined : meter.volume,
      metric: meter.meter_type === 'Electric' ? undefined : meter.metric,
      last_reading_available: !!meter.date_of_last_reading,
    },
    company_detail_id: this.state.companyDetail.id,
  }

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpData),
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    requestUrl
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    meter.id !== null ? 'Put' : 'Post',
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
}

deleteTenantMeter = (meterId: number) => {
  const header = {
    "Content-Type": configJSON.getPropertiesApiContentType,
    token: this.state.token
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.deleteTenantMeterCallId = requestMessage.messageId;

  const requestUrl = `${configJSON.deleteMeter(this.state.selectedProperty?.id, this.state.selectedTenant?.id, meterId)}?company_detail_id=${this.state.companyDetail.id}`;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    requestUrl
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    'Delete',
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
}

setTenantMeterList = (responseJson: any) => {
  if (responseJson?.data) {
    this.setState({ meterList: responseJson.data.map((item: { id: string, attributes: Tenant }) => ({ ...item.attributes, id: item.id })) });
  } else {
    this.setState({ snackbarProps: { isOpen: true, type: 'Error', message: responseJson?.message || 'An unexpected error occurred while loading the meters list.' } })
  }
  this.setState({ tenantMetersLoading: false });
}

updateTenantMeterList = (responseJson: any) => {
  const newMeterId: number = responseJson?.data?.id;
  const newMeter: Meter = responseJson?.data?.attributes;

  if (!newMeterId || !newMeter) {
    this.setState({ snackbarProps: { isOpen: true, type: 'Error', message: responseJson?.message || `An unexpected error occurred while ${this.state.addEditTenantMeterDialogProps?.isAdd ? 'adding a new meter' : 'updating the meter'}.` } })

    return;
  }

  const isUpdate = this.state.meterList.find((meter) => meter.id === newMeterId);

  if (isUpdate) {
    this.setState({ meterList: this.state.meterList.map((meter) => {
      if (meter.id === newMeterId) {
        return { ...newMeter, id: newMeterId };
      }

      return meter;
    }), snackbarProps: { isOpen: true, type: 'Success', message: 'Successfully updated the meter.' } })
  } else {
    this.setState({ meterList: [{...newMeter, id: newMeterId}, ...this.state.meterList], snackbarProps: { isOpen: true, type: 'Success', message: 'Successfully created a new meter.' } });
  }

  this.setAddEditTenantMeterDialogProps(null);
}

filterDeletedTenantMeter = (responseJson: any) => {
  if (responseJson?.message === 'Successfully Deleted!') {
    this.setState({
      meterList: this.state.meterList.filter((meter) => meter.id !== this.state.deleteTenantMeterIdDialog),
      deleteTenantMeterIdDialog: null,
      snackbarProps: { isOpen: true, type: 'Success', message: 'Successfully deleted the meter.' }
    });
  } else {
    this.setState({ snackbarProps: { isOpen: true, type: 'Error', message: responseJson?.message || 'An unexpected error occurred.' } });
  }
}

setAddEditTenantMeterDialogProps = (props: TenantMeterDialogProps | null) => {
  this.setState({ addEditTenantMeterDialogProps: props });
}

setDeleteTenantMeterIdDialog = (id: number | null) => {
  this.setState({ deleteTenantMeterIdDialog: id });
}

filterDeletedPropertyTenant = (responseJson: any) => {
  if (responseJson?.message === 'Successfully Deleted!') {
    this.setState({
      tenantList: this.state.tenantList.filter((tenant) => tenant.id !== this.state.deleteTenantDialogId),
      deleteTenantDialogId: null,
      snackbarProps: { isOpen: true, type: 'Success', message: 'Successfully deleted the tenant.' }
    });
  } else {
    this.setState({ snackbarProps: { isOpen: true, type: 'Error', message: responseJson?.message || 'An unexpected error occurred.' } });
  }
}

updatePropertyTenantsList = (responseJson: any) => {
  let newTenant: Tenant = responseJson?.tenant;

  if (!newTenant?.id) {
    this.setState({ snackbarProps: { isOpen: true, type: 'Error', message: responseJson?.message || `An unexpected error occurred while ${this.state.addEditTenantsDialogProps?.isAdd ? 'adding a new tenant' : 'updating the tenant'}.` } })

    return;
  }

  const isUpdate = this.state.tenantList.find((tenant) => tenant.id === newTenant.id);

  if (isUpdate) {
    this.setState({ tenantList: this.state.tenantList.map((tenant) => {
      if (tenant.id === newTenant.id) {
        return newTenant;
      }

      return tenant;
    }), snackbarProps: { isOpen: true, type: 'Success', message: 'Successfully updated the tenant.' } })
  } else {
    this.setState({ tenantList: [newTenant, ...this.state.tenantList], snackbarProps: { isOpen: true, type: 'Success', message: 'Successfully created a new tenant.' } });
  }

  this.setAddEditTenantsDialogProps(null);
}

setPropertyTenants = (responseJson: any) => {
  if (responseJson?.data) {
    this.setState({ tenantList: responseJson.data.map((item: { attributes: Tenant }) => ({ ...item.attributes })) });
  } else {
    this.setState({ snackbarProps: { isOpen: true, type: 'Error', message: responseJson?.message || 'An unexpected error occurred while loading the tenants list.' } })
  }
  this.setState({ tenantsLoading: false });
}

setAddEditTenantsDialogProps = (props: TenantDialogProps | null) => {
  this.setState({ addEditTenantsDialogProps: props });
}

setDeleteTenantDialogId = (id: number | string | null) => {
  this.setState({ deleteTenantDialogId: id });
}

validateAssignBookNumberToPropertyResponse = (responseJson: any) => {
  if (responseJson?.message === 'Book number assigned to property') {
    this.setSnackbarProps({ isOpen: true, type: 'Success', message: 'Successfully assigned a book number to the property.'});
    this.setState((prevState) => ({
      propertiesList: prevState.propertiesList.map((property) => ({
        ...property,
        book_number: property.id === this.assignBookNumberToPropertyCall.propertyId ? this.assignBookNumberToPropertyCall.bookNumber : property.book_number,
      }))
    }));
  } else {
    this.setSnackbarProps({ isOpen: true, type: 'Error', message: responseJson.message || 'An unexpected error occurred while assigning a book number to the property.' });
  }
}

setDeleteCompanyPropertyIdDialog = (propertyId: string | null) => {
  this.setState({ deleteCompanyPropertyIdDialog: propertyId });
}

setCompanyProperties = (responseJson: any) => {
  if (responseJson?.data) {
    this.setState({ propertiesList: responseJson.data.map((item: { attributes: Property }) => ({
      ...item.attributes,
    })) });
  } else {
    this.setState({ snackbarProps: { isOpen: true, type: 'Error', message: responseJson?.message || 'An unexpected error occurred while loading the properties list.' } })
  }
  this.setState({ propertiesLoading: false });
}

updateCompanyPropertiesList = (responseJson: any) => {
  const newProperty: Property = responseJson?.data?.attributes;

  if (!newProperty?.id) {
    this.setState({ snackbarProps: { isOpen: true, type: 'Error', message: responseJson?.message || `An unexpected error occurred while ${this.state.addEditPropertyDialog?.isAdd ? 'adding a new property' : 'updating the property'}.` } })

    return;
  }

  const isUpdate = this.state.propertiesList.find((property) => property.id === newProperty.id);

  if (isUpdate) {
    this.setState({ propertiesList: this.state.propertiesList.map((property) => {
      if (property.id === newProperty.id) {
        return newProperty;
      }

      return property;
    }), snackbarProps: { isOpen: true, type: 'Success', message: 'Successfully updated the property.' } })
  } else {
    this.setState({ propertiesList: [newProperty, ...this.state.propertiesList], snackbarProps: { isOpen: true, type: 'Success', message: 'Successfully created a new property.' } });
  }

  this.setAddEditPropertyDialog(null);
}

filterDeletedCompanyProperty = (responseJson: any) => {
  if (responseJson?.message === 'Successfully Deleted!') {
    this.setState({
      propertiesList: this.state.propertiesList.filter((property) => property.id !== this.state.deleteCompanyPropertyIdDialog),
      deleteCompanyPropertyIdDialog: null,
      snackbarProps: { isOpen: true, type: 'Success', message: 'Successfully deleted the property.' }
    });
  } else {
    this.setState({ snackbarProps: { isOpen: true, type: 'Error', message: responseJson?.message || 'An unexpected error occurred.' } });
  }
}

setAddEditPropertyDialog = (dialogProps: PropertyDialogProps | null) => {
  this.setState({ addEditPropertyDialog: dialogProps });
}

setSnackbarProps = (props: SnackbarProps) => {
  this.setState({ snackbarProps: props });
}

setSelectedReportProps = (reportId: string, reportType: 'Water' | 'Electric', propertyId: string) => {
  this.setState({ selectedReportProps: { reportId, reportType, propertyId } });
}

onOpenInvoice = (reportId: string, reportType: 'Water' | 'Electric', propertyId: string) => {
  this.setState({ selectedReportProps: { reportId, reportType, propertyId }, isInvoiceOpen: true });
}

getReportsListRequest = () => {
  this.setState({ reportsLoading: true });
  const header = {
      "Content-Type": configJSON.getPropertiesApiContentType,
      token: this.state.token
  };

  const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getReportsListCallId = requestMessage.messageId;

  requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_forms/reports?company_detail_id=${this.state.companyDetail.id}`
  );
  
  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );

  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getPropertiesApiMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
}

generateReportRequest = (data: GenerateReportFormProps) => {
  const header = {
      "Content-Type": configJSON.getPropertiesApiContentType,
      token: this.state.token
  };

  const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.generateReportCallId = requestMessage.messageId;

  const httpBody = {
    property_id: data.propertyId,
    meter_type: data.meterType,
    water_sub_type: data.meterType === 'Water' ? data.waterType : undefined,
    date_from: data.dateFrom,
    date_to: data.dateTo,
  };

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );

  requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_custom_forms/reports'
  );
  
  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );

  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'Post'
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
}

handleGetReportsListResponse = (responseJson: any) => {
  if (responseJson?.data) {
    this.setState({ reportsList: responseJson.data.map((item: any) => item.attributes) });
  } else {
    this.setState({
      snackbarProps: {
        isOpen: true,
        message: 'An unexpected error occurred while getting the reports list.',
        type: 'Error',
      }
    })
  }
  this.setState({ reportsLoading: false });
}

handleGenerateReportResponse = (responseJson: any) => {
  if (responseJson?.data?.attributes?.report_id) {
    this.setState({
      selectedReportProps: {
        reportId: responseJson.data.attributes.report_id,
        reportType: responseJson.data.attributes.meter_type,
        propertyId: responseJson.data.attributes.property_id || '180'
      },
      snackbarProps: { isOpen: true, type: 'Success', message: `Successfully created the report #${responseJson.data.attributes.report_id}.` }
    })
  } else {
    this.setState({
      snackbarProps: {
        isOpen: true,
        message: 'An unexpected error occurred while creating the report.',
        type: 'Error',
      }
    })
  }
}

getPriceRates = (isElectrical: boolean) => {
  this.setState({ priceRatesLoading: true });
  const header = {
    "Content-Type": 'application/json',
    token: this.state.token
  };

  const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getPriceRatesCallId = requestMessage.messageId;

  requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      isElectrical ? "bx_block_settings/get_electricity_settings" : "bx_block_settings/get_water_settings",
  );
  
  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );

  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'Get'
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
}

setPriceRates = (responseJson: any) => {
  if (responseJson?.data) {
    this.setState({ priceRates: responseJson.data.map((item: any) => item.attributes) || [] });
  } else {
    this.setState({
      snackbarProps: {
        isOpen: true,
        message: 'An unexpected error occurred while getting the rates.',
        type: 'Error',
      }
    })
  }
  this.setState({ priceRatesLoading: false });
}

getReport = (reportId: string) => {
  this.setState({ selectedReportLoading: true });
  const header = {
    "Content-Type": 'application/json',
    token: this.state.token
  };

  const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getSelectedReportCallId = requestMessage.messageId;

  requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_custom_forms/reports/${reportId}`
  );
  
  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );

  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'Get'
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
}

setSelectedReport = (responseJson: any) => {
  if (responseJson?.id) {
    this.setState({ selectedReport: responseJson });
  } else {
    this.setState({
      snackbarProps: {
        isOpen: true,
        message: 'An unexpected error occurred while getting the rates.',
        type: 'Error',
      }
    })
  }
  this.setState({ selectedReportLoading: false });
}

editReport = (report: Report) => {
  console.log(report);
  const header = {
    "Content-Type": 'application/json',
    token: this.state.token
  };

  const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.editReportCallId = requestMessage.messageId;

  const reportForRequest = {
    comment: report.comment,
    tenants_list: report.tenantsList.map((tenant) => ({
      id: tenant.id,
      meters: tenant.meters.map((meter) => ({
        id: meter.id,
        readings: [
          {
            present: meter.present,
            previous: meter.previous,
            consumption: (meter.present || 0) - (meter.previous || 0),
            price_id: meter.price_id,
            amount: meter.amount,
            id: meter.reading_id,
          }
        ]
      })),
    })),
    pro_rates: report.proRates.map((proRate) => ({
      id: proRate.tenant_id,
      amount: proRate.amount,
    }))
  }

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(reportForRequest),
  );

  requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_custom_forms/reports/${report.id}`,
  );
  
  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );

  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'Put'
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
}

editReportResponseHandler = (responseJson: any) => {
  if (responseJson.id) {
    this.setState({
      snackbarProps: { isOpen: true, type: 'Success', message: `Successfully updated the report #${responseJson.id}.` },
    });
    this.onOpenInvoice(responseJson.id, responseJson.type, responseJson.propertyId);
  } else {
    this.setState({
      snackbarProps: { isOpen: true, type: 'Error', message: `An unexpected error occurred while updating the report.` },
    });
  }
}

getInvoice = (invoiceId: string) => {
  this.setState({ invoiceLoading: true });
  const header = {
    "Content-Type": 'application/json',
    token: this.state.token
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getInvoiceCallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `/bx_block_custom_forms/invoices/${invoiceId}`
  );
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    'Get'
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
}

setInvoice = (responseJson: any) => {
  if (responseJson?.data?.attributes) {
    this.setState({
      selectedInvoice: responseJson.data.attributes,
    });
  } else {
    this.setState({
      snackbarProps: { isOpen: true, type: 'Error', message: `An unexpected error occurred while loading the invoice.` },
    });
  }
  this.setState({ invoiceLoading: false });
}

editInvoice = (invoice: Invoice) => {
  const header = {
    "Content-Type": 'application/json',
    token: this.state.token
  };

  const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.editInvoiceCallId = requestMessage.messageId;

  const httpBody = {
    invoice,
  };

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `/bx_block_custom_forms/invoices/${invoice.invoice_id}`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    'Put'
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
}

editInvoiceResponseHandler = (responseJson: any) => {
  if (responseJson?.data?.attributes?.invoice_id) {
    this.setState({
      snackbarProps: { isOpen: true, type: 'Success', message: `Successfully updated the invoice #${responseJson.data.attributes.invoice_id}.` },
      selectedInvoice: responseJson.data.attributes,
    });
  } else {
    this.setState({
      snackbarProps: { isOpen: true, type: 'Error', message: `An unexpected error occurred while updating the invoice.` },
    })
  }
}
  // Customizable Area End
}
